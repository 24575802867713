import React from "react";
import { descriptionForEnum } from "../enums";
import useQuery from "../hooks/use-query";
import filtroTipoDecisao from "../libs/filtro-tipo-decisao";
import classNames from "../libs/classnames";
import mapFiltros from "../libs/map-filtros";
import mapFiltrosData from "../libs/map-filtros-data";
import { sortById, sortDecisao, sortTipoDecisao } from "../libs/sorts";
import SideFilter from "./side-filter";
import filtroDecisao from "../libs/filtro-decisao";
import jurisdicao from "../libs/jurisdicao";
import SideFilterFavoritos from "./v2/side-filter-favoritos";

export default function SideFilters({ filtros, hideStatsFilter, className }) {
  const { query } = useQuery();

  const selectedSimilares = query.getAll("similar");
  const selectedTiposDecisao = query.getAll("tipoDecisao");
  const selectedGrauJurisdicao = query.getAll("grauJurisdicao");
  const selectedDecisoes = query.getAll("decisao");
  const selectedMagistrados = query.getAll("magistrado");
  const selectedVaras = query.getAll("vara");
  const selectedOrgaosJulgadores = query.getAll("orgaoJulgador");
  const selectedDatas = query.getAll("data");

  const grausJurisdicao = Object.values(jurisdicao).filter((j) => !j.invisible);

  const datas = mapFiltrosData(filtros?.datas).sort(sortById).reverse();
  const tiposDecisao = mapFiltros(filtros?.tiposDecisao, descriptionForEnum(false))
    .filter(filtroTipoDecisao(selectedGrauJurisdicao))
    .sort(sortTipoDecisao);
  const decisoes = mapFiltros(filtros?.decisoes, descriptionForEnum(true))
    .filter(filtroDecisao(selectedGrauJurisdicao, selectedTiposDecisao))
    .sort(sortDecisao);
  const varas = mapFiltros(filtros?.varas, (vara) => vara).sort(sortById);
  const magistrados = mapFiltros(filtros?.magistrados, (magistrado) => magistrado).sort(sortById);
  const orgaosJulgadores = mapFiltros(filtros?.orgaosJulgadores, (orgaoJulgador) => orgaoJulgador).sort(sortById);
  const similares = selectedSimilares.map((s) => ({ id: s, description: s }));

  return filtros ? (
    <aside
      className={classNames(
        className,
        "mt-4 border-r-0 sm:border-r border-gray-200 xl:w-84 lg:w-84 md:w-72 sm:w-60 print:hidden"
      )}
    >
      <SideFilterFavoritos />
      {selectedSimilares?.length > 0 && (
        <SideFilter label="Similares" id="similar" selected={selectedSimilares} options={similares} />
      )}
      <SideFilter label="Jurisdição" id="grauJurisdicao" selected={selectedGrauJurisdicao} options={grausJurisdicao} />
      {datas?.length > 0 && <SideFilter label="Data" id="data" selected={selectedDatas} options={datas} />}
      {!hideStatsFilter && tiposDecisao?.length > 0 && (
        <SideFilter label="Tipo de decisão" id="tipoDecisao" selected={selectedTiposDecisao} options={tiposDecisao} />
      )}
      {!hideStatsFilter && decisoes?.length > 0 && (
        <SideFilter label="Decisão" id="decisao" selected={selectedDecisoes} options={decisoes} />
      )}
      {magistrados?.length > 0 && (
        <SideFilter
          label="Magistrado"
          id="magistrado"
          selected={selectedMagistrados}
          options={magistrados}
          showSearch
        />
      )}
      {varas?.length > 0 && <SideFilter label="Vara" id="vara" selected={selectedVaras} options={varas} showSearch />}
      {orgaosJulgadores?.length > 0 && (
        <SideFilter
          label="Órgão Julgador"
          id="orgaoJulgador"
          selected={selectedOrgaosJulgadores}
          options={orgaosJulgadores}
          showSearch
        />
      )}
    </aside>
  ) : (
    <aside
      className={classNames(
        className,
        "mt-4 border-r-0 sm:border-r bg-gray-100 border-gray-200 xl:w-84 lg:w-84 md:w-72 sm:w-60 print:hidden animate-pulse"
      )}
    />
  );
}
