import React, { useEffect } from "react";
import useSWR from "swr";
import { CalendarIcon, ClipboardListIcon, ClockIcon, LocationMarkerIcon, UserIcon } from "@heroicons/react/outline";
import useQuery from "../hooks/use-query";
import useDynamicRefs from "../hooks/use-dynamic-refs";
import fetcher from "../libs/fetcher";
import formatarData from "../libs/formatar-data";
import DecisaoBadge from "./decisao-badge";
import DecisaoDot from "./decisao-dot";
import ModalHeader from "./modal-header";
import ModalContainer from "./modal-cointainer";
import NumeroProcesso from "./numero-processo";
import pluralizar from "../libs/pluralizar";
import formatarNumero from "../libs/formatar-numero";

export default function MovimentacoesProcesso({ indice, id, tema, onClose }) {
  const { query } = useQuery();
  const [getRef, setRef] = useDynamicRefs();

  useEffect(() => {
    const id = query.get("id");
    const el = id && getRef(id)?.current;
    if (el) el.scrollIntoView({ behavior: "smooth" });
  });

  const params = new URLSearchParams({ id, sort: "data:desc" });
  const { data: estatisticas, error: estatisticasError } = useSWR(
    `/movimentacoes/estatisticas?${params.toString()}`,
    fetcher
  );
  if (estatisticasError) throw estatisticasError;

  if (tema) params.set("tema", tema);
  const { data: movimentacoes, error } = useSWR(`/movimentacoes/processo?${params.toString()}`, fetcher);
  if (error) throw error;
  if (!movimentacoes) return null;
  return (
    <ModalContainer onClose={onClose}>
      <ModalHeader title={<NumeroProcesso numero={movimentacoes[0].processo} />} onClose={onClose}>
        <div className="flex flex-col lg:flex-row">
          {estatisticas?.merito.dias > 0 && (
            <div className="flex lg:mr-4 text-gray-400 font-semibold text-sm items-begin">
              <ClockIcon className="w-5 h-5 mr-1 text-primary-500" />{" "}
              <span className="text-secondary-700">
                {formatarNumero(estatisticas.merito.dias)} {pluralizar(estatisticas.merito.dias, "dia", "dias")} até
                sentença
              </span>
            </div>
          )}
          {estatisticas?.merito.movimentacoes > 0 && (
            <div className="flex lg:mr-4 text-gray-400 font-semibold text-sm items-begin">
              <ClipboardListIcon className="w-5 h-5 mr-1 text-primary-500" />
              <span className="text-secondary-700">
                {formatarNumero(estatisticas.merito.movimentacoes)}{" "}
                {pluralizar(estatisticas.merito.movimentacoes, "movimentação", "movimentações")} até sentença
              </span>
            </div>
          )}
          {estatisticas?.tutela.dias > 0 && (
            <div className="flex lg:mr-4 text-gray-400 font-semibold text-sm items-begin">
              <ClockIcon className="w-5 h-5 mr-1 text-primary-500" />
              <span className="text-secondary-700">
                {formatarNumero(estatisticas.tutela.dias)} {pluralizar(estatisticas.tutela.dias, "dia", "dias")} até
                tutela
              </span>
            </div>
          )}
          {estatisticas?.tutela.movimentacoes > 0 && (
            <div className="flex lg:mr-4 text-gray-400 font-semibold text-sm items-begin">
              <ClipboardListIcon className="w-5 h-5 mr-1 text-primary-500" />
              <span className="text-secondary-700">
                {formatarNumero(estatisticas.tutela.movimentacoes)}{" "}
                {pluralizar(estatisticas.tutela.movimentacoes, "movimentação", "movimentações")} até tutela
              </span>
            </div>
          )}
        </div>
      </ModalHeader>

      <main className="px-8 py-4">
        {movimentacoes.map((movimentacao, count) => (
          <div key={movimentacao.id} id={`${movimentacao.grau}:${movimentacao.id}`} ref={setRef(`${movimentacao.grau}:${movimentacao.id}`)}>
            <ul>
              <li className="flex">
                <div className="hidden lg:flex mt-3">
                  <div className="text-secondary-500 text-xs font-bold border-gray-200">
                    {formatarData(movimentacao.data)}
                  </div>
                  <div className="flex flex-col items-center">
                    <DecisaoDot decisao={movimentacao.decisao} className="ml-2 mr-3" />
                    <div
                      className={`-ml-1 w-0.5 bg-gray-200 flex-grow ${
                        count === movimentacoes.length - 1 ? "mb-5" : "-mb-3"
                      }`}
                    />
                  </div>
                </div>
                <div className="mb-4 border rounded-lg">
                  <div className="px-4 py-3 flex flex-col w-full lg:flex-row lg:items-center border-b border-gray-200">
                    <div className="flex mb-1 lg:hidden">
                      <DecisaoBadge decisao={movimentacao.decisao} tipoDecisao={movimentacao.tipo_decisao} />
                    </div>
                    <div className="lg:hidden flex">
                      <div className="flex mb-1 text-gray-500 font-semibold text-xs sm:text-sm items-begin">
                        <CalendarIcon className="w-4 h-4 sm:w-5 sm:h-5 mr-1 text-primary-500" />{" "}
                        <span className="text-secondary-700">{formatarData(movimentacao.data)}</span>
                      </div>
                    </div>
                    {movimentacao.juiz && (
                      <div className="flex mb-1 lg:mr-4 text-gray-500 font-semibold text-xs sm:text-sm items-begin">
                        <UserIcon className="w-4 h-4 sm:w-5 sm:h-5 mr-1 text-primary-500" />{" "}
                        <span className="text-secondary-700">{movimentacao.juiz}</span>
                      </div>
                    )}
                    <div className="flex mb-1 lg:mr-4 text-gray-500 font-semibold text-xs sm:text-sm items-begin">
                      <LocationMarkerIcon className="w-4 h-4 sm:w-5 sm:h-5 mr-1 text-primary-500" />
                      <span className="text-secondary-700">
                        {movimentacao.vara && movimentacao.vara}
                        {movimentacao.orgao_julgador && movimentacao.orgao_julgador}
                      </span>
                    </div>
                    <div className="hidden lg:flex flex-grow justify-end">
                      <DecisaoBadge decisao={movimentacao.decisao} tipoDecisao={movimentacao.tipo_decisao} />
                    </div>
                  </div>
                  <div
                    className="px-4 py-3 highlight-content text-gray-900 text-xs sm:text-sm lg:text-justify"
                    dangerouslySetInnerHTML={{ __html: movimentacao.integra }}
                  />
                </div>
              </li>
            </ul>
          </div>
        ))}
      </main>
    </ModalContainer>
  );
}
