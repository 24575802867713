import React from "react";
import descricaoDecisao from "../libs/descricao-decisao";
import * as enums from "../enums";
import classNames from "../libs/classnames";

export default function DecisaoBadge({ decisao, tipoDecisao, className }) {
  const d = enums.getDecisao(decisao, tipoDecisao);

  if (d) {
    return (
      <div
        className={classNames(
          "flex items-center gap-2 px-3 text-xs rounded-full font-bold leading-loose",
          d.bg,
          d.text,
          className
        )}
      >
        <svg height="5" width="5" viewBox="0 0 100 100" className="fill-current">
          <circle cx="50" cy="50" r="40" />
        </svg>
        {descricaoDecisao(decisao, tipoDecisao)}
      </div>
    );
  } else {
    return null;
  }
}
