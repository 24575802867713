import React, { useState } from "react";
import useSWR from "swr";
import BaseLayout from "../components/base-layout";
import fetcher from "../libs/fetcher";
import AdminMenu from "../components/admin-menu";

function AdminEventoItem({ evento }) {
  const { data: usuario } = useSWR(`/usuario/${evento.user}`, fetcher);
  const [mostrarMetadata, setMostrarMetadata] = useState(false);

  return (
    <div className="p-4 border rounded">
      <div>{usuario ? `${usuario?.nome} <${usuario?.email}>` : ""}</div>
      <div>{evento.type}</div>
      <div>{evento.date}</div>
      <div>{evento.level}</div>
      <div>
        <button className="p-2 bg-gray-50 border" onClick={() => setMostrarMetadata((m) => !m)}>
          METADATA
        </button>
      </div>
      {mostrarMetadata && (
        <div>
          <pre>{JSON.stringify(evento.metadata)}</pre>
        </div>
      )}
    </div>
  );
}

function AdminEventoEstatisticaItem({ estatistica }) {
  const { data: usuario } = useSWR(`/usuario/${estatistica.key}`, fetcher);

  return (
    <div className="p-4 border-b">
      <div>{usuario ? `${usuario?.nome} <${usuario?.email}>` : ""}</div>
      <div className="flex justify-between">
        <div>TOTAL: {estatistica.doc_count}</div>
        {estatistica.tipo_evento.buckets.map((te) => (
          <div>
            {te.key}: {te.doc_count}
          </div>
        ))}
      </div>
    </div>
  );
}

export default function AdminEventos() {
  const { data: eventos } = useSWR(`/eventos`, fetcher);
  const { data: estatisticas } = useSWR(`/eventos/estatisticas`, fetcher);

  return (
    <BaseLayout>
      <AdminMenu />

      <div>
        {estatisticas &&
          estatisticas.map((estatistica) => (
            <AdminEventoEstatisticaItem key={estatistica.key} estatistica={estatistica} />
          ))}
      </div>
      <div>
        {eventos &&
          eventos.hits
            .map((evento) => ({ ...evento._source, id: evento._id }))
            .map((evento) => <AdminEventoItem evento={evento} key={evento.id} />)}
        <div className="mb-10" />
      </div>
    </BaseLayout>
  );
}
