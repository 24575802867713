import React, { useState } from "react";
import useSWR from "swr";
import tratarErrosApi from "../libs/tratar-erros-api";
import BaseLayout from "../components/base-layout";
import fetcher from "../libs/fetcher";
import formatarNumero from "../libs/formatar-numero";
import * as Table from "../components/table";
import AdminMenu from "../components/admin-menu";
import { Roles, temRole } from "../libs/roles";

export default function AdminIndices() {
  const { data: indices, error: indiceRequestError, mutate } = useSWR(`/indice`, fetcher);
  const [error, setError] = useState(null);

  if (indiceRequestError) throw indiceRequestError;
  if (error?.__desconhecido) throw error.__desconhecido;

  function deleteIndex(indice) {
    return async (event) => {
      event.preventDefault();
      if (window.confirm(`Realmente apagar ${indice}?`)) {
        try {
          await fetcher(`/indice/${indice}`, { method: "delete" });
          await mutate();
        } catch (error) {
          setError(tratarErrosApi(error));
        }
      }
    };
  }

  return (
    <BaseLayout>
      <AdminMenu />
      {indices && (
        <Table.Container>
          <thead className="bg-gray-50">
            <tr>
              <Table.HeadRow>#</Table.HeadRow>
              <Table.HeadRow>Name</Table.HeadRow>
              <Table.HeadRow className="text-center">Health</Table.HeadRow>
              <Table.HeadRow>Status</Table.HeadRow>
              <Table.HeadRow>Count</Table.HeadRow>
              <Table.HeadRow>Deleted</Table.HeadRow>
              <Table.HeadRow>Size</Table.HeadRow>
              <Table.HeadRow className="text-center">Ações</Table.HeadRow>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {indices
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((indice) => (
                <tr className="text-sm text-gray-900" key={indice._id}>
                  <Table.Row className="text-xs text-gray-500">{indice._id}</Table.Row>
                  <Table.Row>{indice.name}</Table.Row>
                  <Table.Row>
                    <div className="flex justify-center w-full">
                      <HealthDot health={indice.health} />
                    </div>
                  </Table.Row>
                  <Table.Row>{indice.status}</Table.Row>
                  <Table.Row>{formatarNumero(indice.count)}</Table.Row>
                  <Table.Row>{formatarNumero(indice.deleted)}</Table.Row>
                  <Table.Row>{indice.size}</Table.Row>
                  <Table.Row className="px-6 py-4">
                    <div className="flex justify-center w-full">
                      <Table.Button
                        className="bg-gray-100 text-gray-800 focus:ring-gray-500"
                        onClick={deleteIndex(indice.name)}
                        disabled={!temRole(Roles.ROOT)}
                      >
                        Apagar
                      </Table.Button>
                    </div>
                  </Table.Row>
                </tr>
              ))}
          </tbody>
        </Table.Container>
      )}
      <div className="mb-10" />
    </BaseLayout>
  );
}

function HealthDot({ health }) {
  let css;
  switch (health) {
    case "green":
      css = "bg-green-200";
      break;
    case "yellow":
      css = "bg-yellow-200";
      break;
    case "red":
      css = "bg-red-200";
      break;
    default:
      css = "bg-gray-200";
  }

  return <div className={`rounded-full h-4 w-4 ${css}`} />;
}
