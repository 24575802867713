import React from "react";
import useSWR from "swr";
import BaseLayout from "../components/base-layout";
import fetcher from "../libs/fetcher";
import * as Table from "../components/table";
import AdminMenu from "../components/admin-menu";

export default function AdminLogPdfs() {
  const { data: pdfs, error } = useSWR(`/log/pdf`, fetcher);
  if (error) throw error;

  return (
    <BaseLayout>
      <AdminMenu />
      {pdfs && (
        <Table.Container>
          <thead className="bg-gray-50">
            <tr>
              <Table.HeadRow>id</Table.HeadRow>
              <Table.HeadRow>Ano</Table.HeadRow>
              <Table.HeadRow>Data disponibilização</Table.HeadRow>
              <Table.HeadRow>DJe PDF</Table.HeadRow>
              <Table.HeadRow>Número</Table.HeadRow>
              <Table.HeadRow>Procesamento sucesso</Table.HeadRow>
              <Table.HeadRow>Procesamento timestamp</Table.HeadRow>
              <Table.HeadRow>Sucesso</Table.HeadRow>
              <Table.HeadRow>Timestamp</Table.HeadRow>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {pdfs.map((pdf) => (
              <tr className="text-sm text-gray-900" key={pdf.id}>
                <Table.Row>{pdf.id}</Table.Row>
                <Table.Row>{pdf.ano}</Table.Row>
                <Table.Row>{pdf.dataDisponibilizacao}</Table.Row>
                <Table.Row>{pdf.djePdf}</Table.Row>
                <Table.Row>{pdf.numero}</Table.Row>
                <Table.Row>{pdf.processamento_sucesso}</Table.Row>
                <Table.Row>{pdf.processamento_timestamp}</Table.Row>
                <Table.Row>{pdf.sucesso}</Table.Row>
                <Table.Row>{pdf.timestamp}</Table.Row>
              </tr>
            ))}
          </tbody>
        </Table.Container>
      )}
      <div className="mb-10" />
    </BaseLayout>
  );
}
