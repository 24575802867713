import React, { Fragment, useContext } from "react";
import { Link } from "react-router-dom";
import { CalendarIcon, UserIcon, LocationMarkerIcon } from "@heroicons/react/outline";
import formatarData from "../libs/formatar-data";
import DecisaoBadge from "./decisao-badge";
import NumeroProcesso from "./numero-processo";
import jurisdicao from "../libs/jurisdicao";
import classNames from "../libs/classnames";
import AlertsContext from "../contexts/alerts-context";
import * as enums from "../enums";
import FavoritoButton from "./v2/favorito-button";

function formatarCitacao(citacao) {
  if (!citacao.startsWith("(") && !citacao.endsWith(")")) {
    return `(${citacao})`;
  } else {
    return citacao;
  }
}

export default function MovimentacaoListItem({
  esconderAcoes = false,
  esconderDecisao = false,
  _id,
  _score,
  tema,
  decisao,
  tipo_decisao,
  juiz,
  processo,
  integra,
  integra_tipo,
  vara,
  data,
  data_publicacao,
  orgao_julgador,
  grau,
  descricao_decisao_colegiada,
  inteiro_teor,
  inteiro_teor_url,
  texto,
  citacao,
  url_acordao,
  data_sessao,
}) {
  const { addAlert } = useContext(AlertsContext);
  const citacao1grau =
    grau === "1grau" &&
    `(Processo n.º ${processo}, ${juiz ? `Juiz(a) ${juiz}, ` : ""} ${vara}, Publicado no DJE: ${formatarData(
      data
    )}, TJDFT)`;

  const citacaoTrt1grau =
    grau === jurisdicao.trt10_1grau.id &&
    `(Processo n.º ${processo}, ${juiz ? `Juiz(a) ${juiz}, ` : ""} ${vara}, Publicado no DJE: ${formatarData(data)}, ${
      jurisdicao.trt10_1grau.description
    })`;

  citacao =
    grau === jurisdicao.trf2_2grau_acordaos.id ?
    `(Processo n.º ${processo}, ${juiz ? `${juiz}, ` : ""} ${orgao_julgador}, Publicado: ${formatarData(
      data
    )}, TRF 2ª Região 2º grau)` : citacao;

  integra = integra ? integra : [texto];
  const d = enums.getDecisao(decisao, tipo_decisao);

  const params = new URLSearchParams();
  if (tema) params.set("tema", tema);

  function ListItemActions() {
    const componentes = [];

    async function handleCopiarEmenta() {
      const ementaToCopy = integra.join(" ").replace(/<[^>]*>?/gm, "");
      let textToCopy = `${ementaToCopy}`;
      if (citacao) {
        textToCopy = `${ementaToCopy}\n\n${citacao}`;
      }
      await navigator.clipboard.writeText(textToCopy);
      addAlert("Ementa copiada para a área de transferência", "success", _id);
    }

    async function handleCopiarEmentaStfETrf1Acordaos() {
      const ementaToCopy = integra.join(" ").replace(/<[^>]*>?/gm, "");
      const citacaoToCopy = formatarCitacao(citacao.replace(/<[^>]*>?/gm, ""));
      const textToCopy = `${ementaToCopy}\n\n${citacaoToCopy}`;
      await navigator.clipboard.writeText(textToCopy);
      addAlert("Ementa copiada para a área de transferência", "success", _id);
    }

    function handleCopiarCitacao(grau) {
      return async function () {
        const textToCopy = grau === jurisdicao["trt10_1grau"].id ? citacaoTrt1grau : citacao1grau;
        await navigator.clipboard.writeText(textToCopy);
        addAlert("Citação copiada para a área de transferência", "success", _id);
      };
    }

    if (
      grau === "2grau" ||
      grau === "stj" ||
      grau === "stf" ||
      grau === "trf1_acordaos" ||
      grau === "tst_acordaos" ||
      grau === jurisdicao.trt10_2grau_acordaos.id ||
      grau === jurisdicao.trf2_2grau_acordaos.id ||
      grau === jurisdicao.trf3_2grau_acordaos.id
    ) {
      componentes.push((isFirst) => (
        <button
          className={classNames(
            isFirst ? "rounded-tl-lg" : "border-l",
            "block px-4 py-2 bg-gray-100 text-sm text-center font-semibold hover:bg-gray-200 focus:outline-none focus:bg-gray-200"
          )}
          onClick={grau === "stf" || grau === "trf1_acordaos" ? handleCopiarEmentaStfETrf1Acordaos : handleCopiarEmenta}
        >
          Copiar ementa
        </button>
      ));
    }

    if (grau === "tcu_acordaos") {
      componentes.push((isFirst) => (
        <button
          className={classNames(
            isFirst ? "rounded-tl-lg" : "border-l",
            "block px-4 py-2 bg-gray-100 text-sm text-center font-semibold hover:bg-gray-200 focus:outline-none focus:bg-gray-200"
          )}
          onClick={handleCopiarEmentaStfETrf1Acordaos}
        >
          Copiar sumário
        </button>
      ));
    }

    if (grau === jurisdicao["1grau"].id || grau === jurisdicao.trt10_1grau.id) {
      componentes.push((isFirst) => (
        <button
          className={classNames(
            isFirst ? "rounded-tl-lg" : "border-l",
            "block px-4 py-2 bg-gray-100 text-sm text-center font-semibold hover:bg-gray-200 focus:outline-none focus:bg-gray-200"
          )}
          onClick={handleCopiarCitacao(grau)}
        >
          Copiar citação
        </button>
      ));
    }

    if (grau === "trf1_acordaos") {
      componentes.push((isFirst) => (
        <a
          className={classNames(
            isFirst ? "rounded-tl-lg" : "border-l",
            "block px-4 py-2 bg-gray-100 text-sm text-center font-semibold hover:bg-gray-200 focus:outline-none focus:bg-gray-200"
          )}
          href={`${inteiro_teor}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Inteiro teor
        </a>
      ));
    }

    if (grau === "trf2_2grau_acordaos") {
      componentes.push((isFirst) => (
        <a
          className={classNames(
            isFirst ? "rounded-tl-lg" : "border-l",
            "block px-4 py-2 bg-gray-100 text-sm text-center font-semibold hover:bg-gray-200 focus:outline-none focus:bg-gray-200"
          )}
          href={`${inteiro_teor}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Inteiro teor
        </a>
      ));
    }

    // if (grau === "trf3_2grau_acordaos") {
    //   componentes.push((isFirst) => (
    //     <a
    //       className={classNames(
    //         isFirst ? "rounded-tl-lg" : "border-l",
    //         "block px-4 py-2 bg-gray-100 text-sm text-center font-semibold hover:bg-gray-200 focus:outline-none focus:bg-gray-200"
    //       )}
    //       href={`${inteiro_teor}`}
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Inteiro teor
    //     </a>
    //   ));
    // }

    if (grau === "tst_acordaos") {
      componentes.push((isFirst) => (
        <a
          className={classNames(
            isFirst ? "rounded-tl-lg" : "border-l",
            "block px-4 py-2 bg-gray-100 text-sm text-center font-semibold hover:bg-gray-200 focus:outline-none focus:bg-gray-200"
          )}
          href={`https://consultadocumento.tst.jus.br/consultaDocumento/acordao.do${inteiro_teor}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Inteiro teor
        </a>
      ));
    }

    if (grau === "stf") {
      componentes.push((isFirst) => (
        <a
          className={classNames(
            isFirst ? "rounded-tl-lg" : "border-l",
            "block px-4 py-2 bg-gray-100 text-sm text-center font-semibold hover:bg-gray-200 focus:outline-none focus:bg-gray-200"
          )}
          href={`${inteiro_teor_url}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Inteiro teor
        </a>
      ));
    }

    if (grau === "stj") {
      componentes.push((isFirst) => (
        <a
          className={classNames(
            isFirst ? "rounded-tl-lg" : "border-l",
            "block px-4 py-2 bg-gray-100 text-sm text-center font-semibold hover:bg-gray-200 focus:outline-none focus:bg-gray-200"
          )}
          href={`https://scon.stj.jus.br/${inteiro_teor}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Inteiro teor
        </a>
      ));
    }

    if (grau === "2grau") {
      componentes.push((isFirst) => (
        <form target="_blank" action="https://pesquisajuris.tjdft.jus.br/IndexadorAcordaos-web/sistj" method="post">
          <input type="hidden" name="numeroDoDocumento" value={parseInt(_id)} />
          <input type="hidden" name="objetoAjax" value="tjdf.infra.apresentacao.ajax.VisibilidadeAjax" />
          <input
            type="hidden"
            name="visaoId"
            value="tjdf.sistj.acordaoeletronico.buscaindexada.apresentacao.VisaoBuscaAcordao"
          />
          <input type="hidden" name="nomeDaPagina" value="dadosDoAcordao" />
          <input type="hidden" name="enderecoDoServlet" value="sistj" />
          <input type="hidden" name="tokenDePaginacao" value="2" />
          <input type="hidden" name="camposSelecionados" value="ESPELHO" />
          <input type="hidden" name="quantidadeDeRegistros" value="1000" />
          <input type="hidden" name="baseSelecionada" value="BASE_ACORDAO_TODAS" />
          <input type="hidden" name="comando" value="downloadInteiroTeor" />
          <button
            className={classNames(
              isFirst ? "rounded-tl-lg" : "border-l",
              "block px-4 py-2 bg-gray-100 text-sm text-center font-semibold hover:bg-gray-200 focus:outline-none focus:bg-gray-200"
            )}
            type="submit"
          >
            Inteiro teor
          </button>
        </form>
      ));
    }

    if ((grau === jurisdicao["1grau"].id || grau === jurisdicao["trt10_1grau"].id) && processo) {
      componentes.push((isFirst) => (
        <Link
          className={classNames(
            isFirst ? "rounded-tl-lg" : "border-l",
            "block px-4 py-2 bg-gray-100 text-sm text-center font-semibold hover:bg-gray-200 focus:outline-none focus:bg-gray-200"
          )}
          to={(location) => {
            const search = new URLSearchParams(location.search);
            search.set("id", `${grau}:${_id}`);
            return { ...location, search: search.toString() };
          }}
        >
          Detalhar processo
        </Link>
      ));
    }

    if (grau === "tcu_acordaos") {
      componentes.push((isFirst) => (
        <a
          className={classNames(
            isFirst ? "rounded-tl-lg" : "border-l",
            "block px-4 py-2 bg-gray-100 text-sm text-center font-semibold hover:bg-gray-200 focus:outline-none focus:bg-gray-200"
          )}
          href={inteiro_teor}
          target="_blank"
          rel="noopener noreferrer"
        >
          Inteiro teor
        </a>
      ));

      componentes.push((isFirst) => (
        <a
          className={classNames(
            isFirst ? "rounded-tl-lg" : "border-l",
            "block px-4 py-2 bg-gray-100 text-sm text-center font-semibold hover:bg-gray-200 focus:outline-none focus:bg-gray-200"
          )}
          href={url_acordao}
          target="_blank"
          rel="noopener noreferrer"
        >
          Detalhar acórdão
        </a>
      ));
    }

    if (componentes.length === 0) {
      return null;
    } else {
      return (
        <>
          {componentes.map((componente, i) => (
            <Fragment key={i}>{componente(i === 0)}</Fragment>
          ))}
        </>
      );
    }
  }
  return (
    <div className="mt-2 w-full border rounded-lg text-gray-700 overflow-hidden">
      <div className={classNames("border-l-4", d ? d.border : "border-cool-gray-300")}>
        <header className="flex flex-col border-b border-gray-200">
          <div className="py-3 px-5">
            <div className="flex flex-col gap-1 md:flex-row justify-between items-bottom">
              <div className="flex flex-col sm:flex-row sm:gap-2 text-base text-gray-500 font-bold ">
                <div className="flex gap-1">
                  <FavoritoButton idMovimentacao={_id} />
                  <NumeroProcesso numero={processo} />
                </div>
                {!esconderDecisao && (
                  <DecisaoBadge className="hidden sm:flex" decisao={decisao} tipoDecisao={tipo_decisao} />
                )}
              </div>
              <div className="flex items-center gap-1">
                {!esconderDecisao && (
                  <DecisaoBadge className="sm:hidden flex" decisao={decisao} tipoDecisao={tipo_decisao} />
                )}
                <div className="md:pl-2 md:pr-1 py-1 text-xs rounded-full font-bold leading-loose bg-white">
                  <div className="hidden text-xs text-gray-300 text-right">{_score?.toFixed(2).replace(".", ",")}</div>
                </div>
                <div className="px-3 text-xs rounded-full font-bold leading-loose bg-cool-gray-200">
                  {jurisdicao[grau].description}
                </div>
                {descricao_decisao_colegiada && (
                  <div className="px-3 text-xs rounded-full font-bold leading-loose bg-cool-gray-200">
                    {descricao_decisao_colegiada}
                  </div>
                )}
                {integra_tipo && (
                  <div className="px-3 text-xs rounded-full font-bold leading-loose bg-cool-gray-200">
                    {integra_tipo}
                  </div>
                )}
              </div>
            </div>

            <div className="mt-1 flex flex-col lg:flex-row justify-between">
              <div className="flex flex-col lg:flex-row">
                {juiz && (
                  <div className="flex lg:mr-4 text-gray-500 font-semibold text-sm items-begin">
                    <UserIcon className="w-5 h-5 mr-1 text-primary-500" />{" "}
                    <span className="text-secondary-700">{juiz}</span>
                  </div>
                )}
                {(vara || orgao_julgador) && (
                  <div className="flex text-gray-500 font-semibold text-sm items-begin">
                    <LocationMarkerIcon className="w-5 h-5 mr-1 text-primary-500" />{" "}
                    <span className="text-secondary-700">
                      {vara && vara}
                      {orgao_julgador && orgao_julgador}
                    </span>
                  </div>
                )}
              </div>
              <div className="flex text-gray-500 font-semibold text-sm items-begin">
                <CalendarIcon className="w-5 h-5 mr-1 text-primary-500" />{" "}
                <span className="text-secondary-700">
                  {data && `Publicado em ${formatarData(data)}`}
                  {data_publicacao && `Publicado em ${formatarData(data_publicacao)}`}
                  {data_sessao && `Sessão em ${formatarData(data_sessao)}`}
                </span>
              </div>
            </div>
          </div>
        </header>
        <main>
          <div className="highlight-content py-4 px-6 text-gray-700 text-sm lg:text-justify">
            {integra
              .map((trecho, key) => (
                <span
                  key={key}
                  dangerouslySetInnerHTML={{ __html: trecho.replace(/\n\s*\n/g, "\n\n").replace(/\n/g, "<br />") }}
                />
              ))
              .reduce((prev, curr) => [prev, " ... ", curr])}

            {citacao && <p className="mt-4">{formatarCitacao(citacao)}</p>}
            {citacao1grau && <p className="mt-4">{citacao1grau}</p>}
            {citacaoTrt1grau && <p className="mt-4">{citacaoTrt1grau}</p>}

          </div>

          {!esconderAcoes && (
            <div className="flex justify-end">
              <ListItemActions />
            </div>
          )}
        </main>
      </div>
    </div>
  );
}
