import React from "react";
import { Link, useLocation } from "react-router-dom";

export default function TabLink({ className, to, ...props }) {
  const location = useLocation();
  const statusClass =
    location.pathname === to
      ? "border-b-2 border-blue-400 font-semibold hover:bg-gray-100 focus:bg-gray-100"
      : "hover:bg-gray-100 focus:bg-gray-100";

  return (
    <>
      <Link
        className={`px-3 py-2 flex items-baseline uppercase tracking-normal text-sm text-gray-700 whitespace-nowrap relative focus:outline-none ${statusClass} ${className}`}
        to={to}
        {...props}
      />
    </>
  );
}
