export default function createAutorString(autor) {
  const termos = [
    "Autor",
    "A:",
    "A",
    "Requerente",
    "Reclamante",
    "Demandante",
    "Impetrante",
    "Recorrente",
    "Apelante",
    "Suscitante",
    "Paciente",
    "Embargante",
    "Exequente",
    "Impugnante",
    "Excipiente",
  ];
  return termos.map((termo) => `"${termo} ${autor}"`).join(" | ");
}
