import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { SearchIcon } from "@heroicons/react/outline";
import useQuery from "../hooks/use-query";
import useInterval from "../hooks/use-interval";

const exemplos = [
  `"home care" "dano moral"`,
  `cancelamento plano de academia`,
  `"Fulano de Tal"`,
  `"extravio de bagagem" "dano moral"`,
  `dano automóvel "via pública"`,
  `supermercado + furto + veículo`,
  `carro | automóvel | veículo`,
  `seguro -automóvel`,
];

function OperadorButton(props) {
  return (
    <button
      type="button"
      className="px-2 py-1 border rounded font-semibold text-sm items-baseline hover:bg-gray-100"
      {...props}
    />
  );
}

export default function SearchInput({ searchButton, showHint, ...props }) {
  const { setQuery, pushQuery } = useQuery();
  const searchInputRef = useRef();
  const [currentExemplo, setCurrentExemplo] = useState(0);

  function insertText(prefix, suffix) {
    if (Number.isInteger(searchInputRef.current.selectionStart)) {
      const startPosition = searchInputRef.current.selectionStart;
      const endPosition = searchInputRef.current.selectionEnd;
      const currentValue = searchInputRef.current.value;

      if (!suffix) {
        searchInputRef.current.value = `${currentValue.substring(0, startPosition)}${prefix}${currentValue.substring(
          endPosition,
          currentValue.length
        )}`;
        searchInputRef.current.selectionStart = startPosition + prefix.length;
        searchInputRef.current.selectionEnd = searchInputRef.current.selectionStart;
      } else {
        searchInputRef.current.value = `${currentValue.substring(0, startPosition)}${prefix}${currentValue.substring(
          startPosition,
          endPosition
        )}${suffix}${currentValue.substring(endPosition, currentValue.length)}`;

        if (startPosition === endPosition) {
          searchInputRef.current.selectionStart = startPosition + prefix.length;
        } else {
          searchInputRef.current.selectionStart = endPosition + prefix.length + suffix.length;
        }
        searchInputRef.current.selectionEnd = searchInputRef.current.selectionStart;
      }
    } else {
      searchInputRef.current.value += prefix;
    }

    searchInputRef.current.focus();
  }

  useInterval(() => {
    setCurrentExemplo((e) => (e + 1) % exemplos.length);
  }, 3000);

  function handleSubmit(event) {
    event.preventDefault();
    const form = event.target;
    const tema = form.elements.namedItem("tema").value;
    setQuery("tema", tema);
    setQuery("pagina", 1);
    pushQuery();
  }

  function setTutorialOperadores(tutorialOperadores) {
    setQuery("tutorialOperadores", tutorialOperadores);
    if (!tutorialOperadores) setQuery("tutorialOperadores", null);
    pushQuery();
  }

  return (
    <form className="flex flex-col items-center" onSubmit={handleSubmit}>
      {showHint && (
        <p className="text-center mx-4 text-cool-gray-600 text-sm">
          Faça a busca de um tema para conhecer como ele está sendo julgado.
        </p>
      )}
      <div className="flex mt-3 p-1 w-full border shadow rounded-full overflow-hidden focus-within:shadow-outline-blue">
        <div className="pl-4 p-2 flex items-center">
          <SearchIcon className="text-blue-400 h-4" />
        </div>
        <input
          className="p-2 flex-grow text-gray-700 mx-2 mr-4 focus:outline-none text-lg"
          name="tema"
          placeholder={`exemplo: ${exemplos[currentExemplo]}`}
          type="search"
          autoFocus
          ref={searchInputRef}
          {...props}
        />
      </div>

      <div className="py-2 pb-4 flex gap-2 items-center">
        <OperadorButton
          onClick={() => insertText("+")}
          title="Representado pelo character '+' e equivalente a operação E. Por exemplo: supermercado + furto + veículo. O sistema buscará documentos que contenham obrigatóriamente as três palavras."
        >
          E
        </OperadorButton>
        <OperadorButton
          onClick={() => insertText("|")}
          title="Representado pelo character '|' e equivalente a operação OU.  Por exemplo: carro | automóvel | veículo. O sistema buscará documentos que contenham qualquer uma das três palavras."
        >
          OU
        </OperadorButton>
        <OperadorButton
          onClick={() => insertText("-")}
          title="Representado pelo character '-' e equivalente a operação NÃO. Por exempolo: seguro -automóvel. O sistema buscará documentos que contenham a palavra seguro excluíndo os resultados que contenham a palavra automóvel."
        >
          NÃO
        </OperadorButton>
        <OperadorButton
          onClick={() => insertText('"', '"')}
          title='Melhora busca de palavras consecutivas ou nomes. Por exemplo: \"Fulano de Tal\", O sistema buscará pelo nome e sobrenome excluindo resultados em que Fulano e Tal apareçam separadamente.'
        >
          " "
        </OperadorButton>
        <OperadorButton
          onClick={() => insertText("(", ")")}
          title="Muito parecido com o uso de parentese em operações matemáticas. Ou seja, muda a precedência do operador. Por exemplo, (seguro + veículo) | (seguro + automóvel). O sistema buscará documentos que contenham o termo seguro e o termo veículo, ou documentos que contenham o termo seguro e o termo automóvel."
        >
          ( )
        </OperadorButton>
        <button
          className="ml-2 rounded-full h-7 w-7 bg-secondary-500 text-white font-bold hover:bg-secondary-700"
          type="button"
          onClick={(event) => {
            event.preventDefault();
            setTutorialOperadores(1);
          }}
        >
          ?
        </button>
      </div>

      <div className="flex flex-col gap-2 md:flex-row-reverse">
        <button
          type="submit"
          className="px-4 py-2 border font-bold border-primary-500 bg-primary-500 text-white rounded-xl hover:bg-primary-600 focus:bg-primary-600 focus:outline-none text-center"
        >
          Pesquisar no Dominia
        </button>
        {searchButton && (
          <Link
            to="/pesquisa?tutorial=1"
            className="px-4 py-2 border font-bold border-secondary-500 bg-secondary-500 text-white rounded-xl hover:bg-secondary-700 focus:bg-secondary-700 focus:outline-none text-center"
          >
            Como usar o Dominia?
          </Link>
        )}
      </div>
    </form>
  );
}
