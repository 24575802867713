import React from "react";
import { Link } from "react-router-dom";
import classNames from "../../libs/classnames";

export default function Barra({ label, dados, className = "bg-primary-500", ...props }) {
  return (
    <li className="mt-3">
      <Link {...props}>
        <h2 className="font-semibold text-gray-700 text-xs">{label}</h2>
        <div className="flex items-center">
          <div
            className={classNames(className, "h-6 rounded mr-2 print:border-black print:border")}
            style={{ width: `${dados.percent + 1}%` }}
          />
          <span className="font-semibold text-gray-700 text-xs">{dados.percent}%</span>
        </div>
      </Link>
    </li>
  );
}
