import React from "react";

export default function PerguntaResposta({ pergunta, children }) {
  return (
    <div>
      <dt className="text-xl leading-6 font-bold text-gray-900">{pergunta}</dt>
      <dd className="mt-2 text-base text-gray-500">{children}</dd>
    </div>
  );
}
