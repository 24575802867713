import TokenManager from "../libs/token-manager";

export const Roles = {
  ROOT: "ROOT",
  COFUNDADOR: "COFUNDADOR",
  ASSINANTE: "ASSINANTE",
  USUARIO: "USUARIO",
};

export function temRole(...roles) {
  const token = TokenManager.get();
  const userRole = token ? TokenManager.info()?.role || Roles.ASSINANTE : undefined;
  return token && (userRole === Roles.ROOT || roles.includes(userRole));
}
