import React from "react";
import Card from "./card";
import Container from "./container";
import Barra from "./barra";
import TempoMedioRanking from "./tempo-medio-ranking";
import ContagemRanking from "./contagem-ranking";
import formatarNumero from "../../libs/formatar-numero";
import classNames from "../../libs/classnames";
import useSWR from "swr";
import fetcher from "../../libs/fetcher";
import pluralizar from "../../libs/pluralizar";
import jurisdicao from "../../libs/jurisdicao";
import * as enums from "../../enums";

function EsqueletoGrafico() {
  return (
    <Container label={<div className="h-4 w-64 bg-gray-300 mb-1 rounded-lg" />}>
      <ol className="p-4 pt-0">
        {[...Array(3).keys()].map((i) => (
          <li className="mt-3" key={i}>
            <h2 className="font-semibold text-gray-700 text-xs">
              <div className="h-3 w-32 bg-gray-300 mb-1 rounded-lg" />
            </h2>
            <div className="flex items-center">
              <div className="h-6 flex-grow bg-gray-300 mb-1 rounded-lg" />
              <div className="h-6 rounded mr-2" />
              <span className="font-semibold text-gray-700 text-xs">
                <div className="h-3 w-6 bg-gray-300 mb-1 rounded-lg" />
              </span>
            </div>
          </li>
        ))}
      </ol>
    </Container>
  );
}

function createGenerateTo(paramsString) {
  return function generateTo(params, page = "pesquisa") {
    const searchParams = new URLSearchParams(paramsString);
    for (const [key, value] of Object.entries(params)) {
      searchParams.set(key, value);
    }
    return `/${page}?${searchParams.toString()}`;
  };
}

function Relatorio1Grau({ grau, estatisticas, descricao, generateTo }) {
  return (
    <>
      <div className="grid grid-cols-1 gap-2 md:grid-cols-3 mt-3">
        {estatisticas ? (
          <>
            <Card
              label={`Sentenças ${descricao}`}
              to={generateTo({
                grauJurisdicao: jurisdicao[grau].id,
                tipoDecisao: enums.TIPO_DECISAO_MERITO,
              })}
            >
              {formatarNumero(estatisticas.merito.count)}{" "}
              <small className="text-cool-gray-500">
                {pluralizar(estatisticas.merito.count, "sentença", "sentenças")}
              </small>
            </Card>
            <Card
              label={`Tutelas ${descricao}`}
              to={generateTo({
                grauJurisdicao: jurisdicao[grau].id,
                tipoDecisao: enums.TIPO_DECISAO_TUTELA,
              })}
            >
              {formatarNumero(estatisticas.tutela.count)}{" "}
              <small className="text-cool-gray-500">{pluralizar(estatisticas.tutela.count, "tutela", "tutelas")}</small>
            </Card>
            <Card
              label={`Despachos ${descricao}`}
              to={generateTo({
                grauJurisdicao: jurisdicao[grau].id,
                tipoDecisao: enums.TIPO_DECISAO_OUTROS,
              })}
            >
              {formatarNumero(estatisticas.despacho.count)}{" "}
              <small className="text-cool-gray-500">
                {pluralizar(estatisticas.despacho.count, "despacho", "despachos")}
              </small>
            </Card>
          </>
        ) : (
          <>
            {[...Array(3).keys()].map((i) => (
              <Card key={i} label={<div className="h-3 w-32 bg-gray-300 mb-1 rounded-lg mt-2" />}>
                <div className="h-6 w-24 bg-gray-300 mb-1 rounded-lg" />
              </Card>
            ))}
          </>
        )}
      </div>
      <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 mt-2">
        {estatisticas ? (
          <Container label={`Como as sentenças são decididas no ${descricao}?`}>
            <ol className="p-4 pt-0">
              <Barra
                className="bg-graph-1"
                label="Procedente"
                dados={estatisticas.merito.decisoes.procedente}
                to={generateTo({
                  grauJurisdicao: jurisdicao[grau].id,
                  decisao: enums.DECISAO_PROCEDENTE,
                })}
              />
              <Barra
                className="bg-graph-2"
                label="Parcialmente procedente"
                dados={estatisticas.merito.decisoes.parcialmente_procedente}
                to={generateTo({
                  grauJurisdicao: jurisdicao[grau].id,
                  decisao: enums.DECISAO_PARCIALMENTE_PROCEDENTE,
                })}
              />
              <Barra
                className="bg-graph-3"
                label="Improcedente"
                dados={estatisticas.merito.decisoes.improcedente}
                to={generateTo({
                  grauJurisdicao: jurisdicao[grau].id,
                  decisao: enums.DECISAO_IMPROCEDENTE,
                })}
              />
              <Barra
                className="bg-graph-4"
                label="Acordo"
                dados={estatisticas.merito.decisoes.acordo}
                to={generateTo({
                  grauJurisdicao: jurisdicao[grau].id,
                  decisao: enums.DECISAO_ACORDO,
                })}
              />
            </ol>
          </Container>
        ) : (
          <EsqueletoGrafico />
        )}
        {estatisticas ? (
          <Container label={`Como as tutelas são decididas no ${descricao}?`}>
            <ol className="p-4 pt-0">
              <Barra
                className="bg-graph-1"
                label="Deferido"
                dados={estatisticas.tutela.decisoes.deferido}
                to={generateTo({
                  grauJurisdicao: jurisdicao[grau].id,
                  decisao: enums.DECISAO_DEFERIDO,
                })}
              />
              <Barra
                className="bg-graph-2"
                label="Parcialmente deferido"
                dados={estatisticas.tutela.decisoes.parcialmente_deferido}
                to={generateTo({
                  grauJurisdicao: jurisdicao[grau].id,
                  decisao: enums.DECISAO_PARCIALMENTE_DEFERIDO,
                })}
              />
              <Barra
                className="bg-graph-3"
                label="Indeferido"
                dados={estatisticas.tutela.decisoes.indeferido}
                to={generateTo({
                  grauJurisdicao: jurisdicao[grau].id,
                  decisao: enums.DECISAO_INDEFERIDO,
                })}
              />
            </ol>
          </Container>
        ) : (
          <EsqueletoGrafico />
        )}
      </div>
      <div className="grid grid-cols-1 gap-2 md:grid-cols-2 mt-2">
        {estatisticas ? (
          <>
            <Card label={`Média de movimentações até Sentença ${descricao}`}>
              {formatarNumero(estatisticas.merito.movimentacoes)}{" "}
              <small className="text-cool-gray-500">
                {pluralizar(estatisticas.merito.movimentacoes, "movimentação", "movimentações")}
              </small>
            </Card>
            <Card label={`Média de movimentações até Tutela ${descricao}`}>
              {formatarNumero(estatisticas.tutela.movimentacoes)}{" "}
              <small className="text-cool-gray-500">
                {pluralizar(estatisticas.tutela.movimentacoes, "movimentação", "movimentações")}
              </small>
            </Card>
          </>
        ) : (
          <>
            {[...Array(2).keys()].map((i) => (
              <Card key={i} label={<div className="h-3 w-32 bg-gray-300 mb-1 rounded-lg mt-2" />}>
                <div className="h-6 w-24 bg-gray-300 mb-1 rounded-lg" />
              </Card>
            ))}
          </>
        )}
      </div>
      <div className="grid grid-cols-1 gap-2 md:grid-cols-2 xl:grid-cols-4 mt-2">
        {estatisticas ? (
          <>
            <TempoMedioRanking
              estatisticas={estatisticas.merito}
              titulo={
                <>
                  Tempo médio até <strong>sentença</strong>
                </>
              }
              generateTo={generateTo}
              grauJurisdicao={jurisdicao[grau].id}
            />
            <ContagemRanking
              estatisticas={estatisticas.merito}
              titulo={
                <>
                  Varas com mais <strong>sentenças</strong>
                </>
              }
              generateTo={generateTo}
              grauJurisdicao={jurisdicao[grau].id}
            />
            <TempoMedioRanking
              estatisticas={estatisticas.tutela}
              titulo={
                <>
                  Tempo médio até <strong>tutela</strong>
                </>
              }
              generateTo={generateTo}
              grauJurisdicao={jurisdicao[grau].id}
            />
            <ContagemRanking
              estatisticas={estatisticas.tutela}
              titulo={
                <>
                  Varas com mais <strong>tutelas</strong>
                </>
              }
              generateTo={generateTo}
              grauJurisdicao={jurisdicao[grau].id}
            />
          </>
        ) : (
          <>
            {[...Array(4).keys()].map((i) => (
              <Card key={i} label={<div className="h-4 w-32 bg-gray-300 mb-1 rounded-lg mt-2" />}>
                <div className="h-8 w-24 bg-gray-300 mb-1 rounded-lg" />
              </Card>
            ))}
          </>
        )}
      </div>
    </>
  );
}

export default function Relatorio({ paramsString }) {
  const url = paramsString ? `/movimentacoes/estatisticas?${paramsString}` : `/estatisticas`;

  const { data: estatisticas, error: estatisticasError } = useSWR(url, fetcher);
  if (estatisticasError) throw estatisticasError;

  const generateTo = createGenerateTo(paramsString);

  return (
    <div className="m-0 p-0 w-full pt-2 sm:pt-0">
      <section className={classNames(!estatisticas && "animate-pulse", "pt-0", "w-full px-2 py-4 mb-2")}>
        <Relatorio1Grau estatisticas={estatisticas} generateTo={generateTo} grau="1grau" descricao="TJDFT 1º grau" />
        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 mt-2">
          {estatisticas ? (
            <>
              <Card
                label="Acórdãos TRF1 2º grau"
                to={generateTo({
                  grauJurisdicao: jurisdicao["2grau"].id,
                  tipoDecisao: enums.TIPO_DECISAO_ACORDAO,
                })}
              >
                {formatarNumero(estatisticas.acordaoTjdft.count)}{" "}
                <small className="text-cool-gray-500">
                  {pluralizar(estatisticas.acordaoTjdft.count, "acórdão", "acórdãos")}
                </small>
              </Card>
              <Card
                label="Acórdãos TRF1 2º grau"
                to={generateTo({
                  grauJurisdicao: jurisdicao["trf1_acordaos"].id,
                  tipoDecisao: enums.TIPO_DECISAO_ACORDAO,
                })}
              >
                {formatarNumero(estatisticas.acordaosTrf.count)}{" "}
                <small className="text-cool-gray-500">
                  {pluralizar(estatisticas.acordaosTrf.count, "acórdão", "acórdãos")}
                </small>
              </Card>
            </>
          ) : (
            <>
              {[...Array(2).keys()].map((i) => (
                <Card key={i} label={<div className="h-3 w-32 bg-gray-300 mb-1 rounded-lg mt-2" />}>
                  <div className="h-6 w-24 bg-gray-300 mb-1 rounded-lg" />
                </Card>
              ))}
            </>
          )}
        </div>
        <div className="grid grid-cols-1 gap-3 lg:grid-cols-2 mt-2">
          {estatisticas ? (
            <Container label="Como os acórdãos são decididos no TJDFT 2º grau?">
              <ol className="p-4 pt-0">
                <Barra
                  className="bg-graph-1"
                  label="Provido"
                  dados={estatisticas.acordaoTjdft.decisoes.provido}
                  to={generateTo({
                    grauJurisdicao: jurisdicao["2grau"].id,
                    decisao: enums.DECISAO_PROVIDO,
                  })}
                />
                <Barra
                  className="bg-graph-2"
                  label="Parcialmente provido"
                  dados={estatisticas.acordaoTjdft.decisoes.parcialmente_provido}
                  to={generateTo({
                    grauJurisdicao: jurisdicao["2grau"].id,
                    decisao: enums.DECISAO_PARCIALMENTE_PROVIDO,
                  })}
                />
                <Barra
                  className="bg-graph-3"
                  label="Improvido"
                  dados={estatisticas.acordaoTjdft.decisoes.improvido}
                  to={generateTo({
                    grauJurisdicao: jurisdicao["2grau"].id,
                    decisao: enums.DECISAO_IMPROVIDO,
                  })}
                />
              </ol>
            </Container>
          ) : (
            <EsqueletoGrafico />
          )}
          {estatisticas ? (
            <Container label="Como os acórdãos são decididos no TRF1 2º grau?">
              <ol className="p-4 pt-0">
                <Barra
                  className="bg-graph-1"
                  label="Provido"
                  dados={estatisticas.acordaosTrf.decisoes.provido}
                  to={generateTo({
                    grauJurisdicao: jurisdicao["trf1_acordaos"].id,
                    decisao: enums.DECISAO_PROVIDO,
                  })}
                />
                <Barra
                  className="bg-graph-2"
                  label="Parcialmente provido"
                  dados={estatisticas.acordaosTrf.decisoes.parcialmente_provido}
                  to={generateTo({
                    grauJurisdicao: jurisdicao["trf1_acordaos"].id,
                    decisao: enums.DECISAO_PARCIALMENTE_PROVIDO,
                  })}
                />
                <Barra
                  className="bg-graph-3"
                  label="Improvido"
                  dados={estatisticas.acordaosTrf.decisoes.improvido}
                  to={generateTo({
                    grauJurisdicao: jurisdicao["trf1_acordaos"].id,
                    decisao: enums.DECISAO_IMPROVIDO,
                  })}
                />
              </ol>
            </Container>
          ) : (
            <EsqueletoGrafico />
          )}
        </div>
        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 mt-2">
          {estatisticas ? (
            <>
              <Card
                label="Acórdãos TRF2 2º grau"
                to={generateTo({
                  grauJurisdicao: jurisdicao.trf2_2grau_acordaos,
                  tipoDecisao: enums.TIPO_DECISAO_ACORDAO,
                })}
              >
                {formatarNumero(estatisticas.acordaosTrf2.count)}{" "}
                <small className="text-cool-gray-500">
                  {pluralizar(estatisticas.acordaosTrf2.count, "acórdão", "acórdãos")}
                </small>
              </Card>
              <Card
                label="Acórdãos TRF3 2º grau"
                to={generateTo({
                  grauJurisdicao: jurisdicao.trf3_2grau_acordaos.id,
                  tipoDecisao: enums.TIPO_DECISAO_ACORDAO,
                })}
              >
                {formatarNumero(estatisticas.acordaosTrf3.count)}{" "}
                <small className="text-cool-gray-500">
                  {pluralizar(estatisticas.acordaosTrf3.count, "acórdão", "acórdãos")}
                </small>
              </Card>
            </>
          ) : (
            <>
              {[...Array(2).keys()].map((i) => (
                <Card key={i} label={<div className="h-3 w-32 bg-gray-300 mb-1 rounded-lg mt-2" />}>
                  <div className="h-6 w-24 bg-gray-300 mb-1 rounded-lg" />
                </Card>
              ))}
            </>
          )}
        </div>
        <div className="grid grid-cols-1 gap-3 lg:grid-cols-2 mt-2">
          {estatisticas ? (
            <Container label="Como os acórdãos são decididos no TRF 2ª Região 2º grau?">
              <ol className="p-4 pt-0">
                <Barra
                  className="bg-graph-1"
                  label="Provido"
                  dados={estatisticas.acordaosTrf2.decisoes.provido}
                  to={generateTo({
                    grauJurisdicao: jurisdicao.trf2_2grau_acordaos.id,
                    decisao: enums.DECISAO_PROVIDO,
                  })}
                />
                <Barra
                  className="bg-graph-2"
                  label="Parcialmente provido"
                  dados={estatisticas.acordaosTrf2.decisoes.parcialmente_provido}
                  to={generateTo({
                    grauJurisdicao: jurisdicao.trf2_2grau_acordaos.id,
                    decisao: enums.DECISAO_PARCIALMENTE_PROVIDO,
                  })}
                />
                <Barra
                  className="bg-graph-3"
                  label="Improvido"
                  dados={estatisticas.acordaosTrf3.decisoes.improvido}
                  to={generateTo({
                    grauJurisdicao: jurisdicao.trf2_2grau_acordaos.id,
                    decisao: enums.DECISAO_IMPROVIDO,
                  })}
                />
              </ol>
            </Container>
          ) : (
            <EsqueletoGrafico />
          )}
          {estatisticas ? (
            <Container label="Como os acórdãos são decididos no TRF 3ª Região 2º grau?">
              <ol className="p-4 pt-0">
                <Barra
                  className="bg-graph-1"
                  label="Provido"
                  dados={estatisticas.acordaosTrf3.decisoes.provido}
                  to={generateTo({
                    grauJurisdicao: jurisdicao.trf3_2grau_acordaos.id,
                    decisao: enums.DECISAO_PROVIDO,
                  })}
                />
                <Barra
                  className="bg-graph-2"
                  label="Parcialmente provido"
                  dados={estatisticas.acordaosTrf3.decisoes.parcialmente_provido}
                  to={generateTo({
                    grauJurisdicao: jurisdicao.trf3_2grau_acordaos.id,
                    decisao: enums.DECISAO_PARCIALMENTE_PROVIDO,
                  })}
                />
                <Barra
                  className="bg-graph-3"
                  label="Improvido"
                  dados={estatisticas.acordaosTrf3.decisoes.improvido}
                  to={generateTo({
                    grauJurisdicao: jurisdicao.trf3_2grau_acordaos.id,
                    decisao: enums.DECISAO_IMPROVIDO,
                  })}
                />
              </ol>
            </Container>
          ) : (
            <EsqueletoGrafico />
          )}
        </div>
        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 mt-2">
          {estatisticas ? (
            <>
              <Card
                label="Acórdãos STJ"
                to={generateTo({
                  grauJurisdicao: jurisdicao["stj"].id,
                  tipoDecisao: enums.TIPO_DECISAO_ACORDAO,
                })}
              >
                {formatarNumero(estatisticas.acordaoStj.count)}{" "}
                <small className="text-cool-gray-500">
                  {pluralizar(estatisticas.acordaoStj.count, "acórdão", "acórdãos")}
                </small>
              </Card>
              <Card
                label="Acórdãos STF"
                to={generateTo({
                  grauJurisdicao: jurisdicao["stf"].id,
                  tipoDecisao: enums.TIPO_DECISAO_ACORDAO,
                })}
              >
                {formatarNumero(estatisticas.acordaoStf.count)}{" "}
                <small className="text-cool-gray-500">
                  {pluralizar(estatisticas.acordaoStf.count, "acórdão", "acórdãos")}
                </small>
              </Card>
            </>
          ) : (
            <>
              {[...Array(2).keys()].map((i) => (
                <Card key={i} label={<div className="h-3 w-32 bg-gray-300 mb-1 rounded-lg mt-2" />}>
                  <div className="h-6 w-24 bg-gray-300 mb-1 rounded-lg" />
                </Card>
              ))}
            </>
          )}
        </div>
        <div className="grid grid-cols-1 gap-2 lg:grid-cols-2 mt-2">
          {estatisticas ? (
            <Container label="Como os acórdãos são decididos no STJ?">
              <ol className="p-4 pt-0">
                <Barra
                  className="bg-graph-1"
                  label="Provido"
                  dados={estatisticas.acordaoStj.decisoes.provido}
                  to={generateTo({
                    grauJurisdicao: jurisdicao["stj"].id,
                    decisao: enums.DECISAO_PROVIDO,
                  })}
                />
                <Barra
                  className="bg-graph-2"
                  label="Parcialmente provido"
                  dados={estatisticas.acordaoStj.decisoes.parcialmente_provido}
                  to={generateTo({
                    grauJurisdicao: jurisdicao["stj"].id,
                    decisao: enums.DECISAO_PARCIALMENTE_PROVIDO,
                  })}
                />
                <Barra
                  className="bg-graph-3"
                  label="Improvido"
                  dados={estatisticas.acordaoStj.decisoes.improvido}
                  to={generateTo({
                    grauJurisdicao: jurisdicao["stj"].id,
                    decisao: enums.DECISAO_IMPROVIDO,
                  })}
                />
              </ol>
            </Container>
          ) : (
            <EsqueletoGrafico />
          )}
          {estatisticas ? (
            <Container label="Como os acórdãos são decididos no STF?">
              <ol className="p-4 pt-0">
                <Barra
                  className="bg-graph-1"
                  label="Provido"
                  dados={estatisticas.acordaoStf.decisoes.provido}
                  to={generateTo({
                    grauJurisdicao: jurisdicao["stf"].id,
                    decisao: enums.DECISAO_PROVIDO,
                  })}
                />
                <Barra
                  className="bg-graph-2"
                  label="Parcialmente provido"
                  dados={estatisticas.acordaoStf.decisoes.parcialmente_provido}
                  to={generateTo({
                    grauJurisdicao: jurisdicao["stf"].id,
                    decisao: enums.DECISAO_PARCIALMENTE_PROVIDO,
                  })}
                />
                <Barra
                  className="bg-graph-3"
                  label="Improvido"
                  dados={estatisticas.acordaoStf.decisoes.improvido}
                  to={generateTo({
                    grauJurisdicao: jurisdicao["stf"].id,
                    decisao: enums.DECISAO_IMPROVIDO,
                  })}
                />
              </ol>
            </Container>
          ) : (
            <EsqueletoGrafico />
          )}
        </div>
        <Relatorio1Grau
          estatisticas={estatisticas?.trabalhista}
          generateTo={generateTo}
          grau="trt10_1grau"
          descricao="TRT 10ª Região 1º grau"
        />
        <div className="grid grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-2 mt-3">
          {estatisticas ? (
            <>
              <Card
                label="Acórdãos TRT 10ª Região 2º grau"
                to={generateTo({
                  grauJurisdicao: jurisdicao.trt10_2grau_acordaos.id,
                  tipoDecisao: enums.TIPO_DECISAO_ACORDAO,
                })}
              >
                {formatarNumero(estatisticas.trabalhista.acordaoTrt10.count)}{" "}
                <small className="text-cool-gray-500">
                  {pluralizar(estatisticas.trabalhista.acordaoTrt10.count, "acórdão", "acórdãos")}
                </small>
              </Card>
              <Card
                label="Acórdãos TST"
                to={generateTo({
                  grauJurisdicao: jurisdicao["tst_acordaos"].id,
                  tipoDecisao: enums.TIPO_DECISAO_ACORDAO,
                })}
              >
                {formatarNumero(estatisticas.trabalhista.acordaoTst.count)}{" "}
                <small className="text-cool-gray-500">
                  {pluralizar(estatisticas.trabalhista.acordaoTst.count, "acórdão", "acórdãos")}
                </small>
              </Card>
            </>
          ) : (
            <>
              {[...Array(2).keys()].map((i) => (
                <Card key={i} label={<div className="h-3 w-32 bg-gray-300 mb-1 rounded-lg mt-2" />}>
                  <div className="h-6 w-24 bg-gray-300 mb-1 rounded-lg" />
                </Card>
              ))}
            </>
          )}
        </div>
        <div className="grid grid-cols-1 gap-3 lg:grid-cols-2 mt-2">
          {estatisticas ? (
            <Container label="Como os acórdãos são decididos no TRT 10ª Região 2º grau?">
              <ol className="p-4 pt-0">
                <Barra
                  className="bg-graph-1"
                  label="Provido"
                  dados={estatisticas.trabalhista.acordaoTrt10.decisoes.provido}
                  to={generateTo({
                    grauJurisdicao: jurisdicao.trt10_2grau_acordaos.id,
                    decisao: enums.DECISAO_PROVIDO,
                  })}
                />
                <Barra
                  className="bg-graph-2"
                  label="Parcialmente provido"
                  dados={estatisticas.trabalhista.acordaoTrt10.decisoes.parcialmente_provido}
                  to={generateTo({
                    grauJurisdicao: jurisdicao.trt10_2grau_acordaos.id,
                    decisao: enums.DECISAO_PARCIALMENTE_PROVIDO,
                  })}
                />
                <Barra
                  className="bg-graph-3"
                  label="Improvido"
                  dados={estatisticas.trabalhista.acordaoTrt10.decisoes.improvido}
                  to={generateTo({
                    grauJurisdicao: jurisdicao.trt10_2grau_acordaos.id,
                    decisao: enums.DECISAO_IMPROVIDO,
                  })}
                />
              </ol>
            </Container>
          ) : (
            <EsqueletoGrafico />
          )}
          {estatisticas ? (
            <Container label="Como os acórdãos são decididos no TST?">
              <ol className="p-4 pt-0">
                <Barra
                  className="bg-graph-1"
                  label="Provido"
                  dados={estatisticas.trabalhista.acordaoTst.decisoes.provido}
                  to={generateTo({
                    grauJurisdicao: jurisdicao["tst_acordaos"].id,
                    decisao: enums.DECISAO_PROVIDO,
                  })}
                />
                <Barra
                  className="bg-graph-2"
                  label="Parcialmente provido"
                  dados={estatisticas.trabalhista.acordaoTst.decisoes.parcialmente_provido}
                  to={generateTo({
                    grauJurisdicao: jurisdicao["tst_acordaos"].id,
                    decisao: enums.DECISAO_PARCIALMENTE_PROVIDO,
                  })}
                />
                <Barra
                  className="bg-graph-3"
                  label="Improvido"
                  dados={estatisticas.trabalhista.acordaoTst.decisoes.improvido}
                  to={generateTo({
                    grauJurisdicao: jurisdicao["tst_acordaos"].id,
                    decisao: enums.DECISAO_IMPROVIDO,
                  })}
                />
              </ol>
            </Container>
          ) : (
            <EsqueletoGrafico />
          )}
        </div>
      </section>
    </div>
  );
}
