import React from "react";
import IndexLayout from "../components/index-layout";

export default function TermosDeUso() {
  return (
    <IndexLayout>
      <div className="bg-white pb-20">
        <div className="pt-8 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center uppercase">
            <h1 className="text-3xl font-extrabold text-gray-900">Termos de Uso</h1>
            <h2 className="text-2xl font-extrabold text-gray-900">(Dominia)</h2>
          </div>
          <div className="mt-12 text-sm lg:text-justify">
            <p>Bem-vindos ao DOMINIA. Apresentamos os Termos de Uso.</p>
            <p className="mt-5">
              Ao fazer uso da plataforma DOMINIA você expressamente aceita e concorda com as disposições deste termo. Em
              razão disso, o usuário deve ler atentamente suas cláusulas e condições.
            </p>

            <h3 className="mt-5 font-bold">1. Aceitação</h3>
            <p className="mt-1">
              Para a coleta e o processamento de dados pessoais, o website DOMINIA disponibilizará um termo de aceite
              específico, conforme a legislação de proteção de dados, o qual deverá contar com a manifestação livre,
              inequívoca e informada do usuário quanto aos dados pessoais em geral.
            </p>
            <p className="mt-1">
              Apenas os dados indispensáveis para cumprimento do contrato serão amenizados em nosso sistema.
            </p>
            <p className="mt-1">
              Ao utilizar e/ou contratar os serviços do DOMINIA, você também declara que: i) se pessoa física, é maior
              de 18 (dezoito) anos e possui capacidade jurídica para celebrar os negócios jurídicos mediante a
              plataforma; ii) se pessoa jurídica, é validamente existente, está devidamente constituída e registrada no
              registro comercial competente e CNPJ; iii) possui capacidade financeira para arcar com os custos e
              despesas inerentes aos serviços; iv) as informações fornecidas para preenchimento do cadastro para criação
              da conta são verídicas, corretas, completas e atualizadas. Ao se cadastrar você deverá criar uma senha
              para uso próprio, sendo de sua integral e exclusiva responsabilidade manter sigilo de sua senha e evitar
              que terceiros tenham acesso a esta.
            </p>

            <h3 className="mt-5 font-bold">2. Funcionamento da Plataforma DOMINIA</h3>
            <p className="mt-1">
              O DOMINIA utiliza o processamento de dados públicos, oriundos de decisões do Tribunal de Justiça do
              Distrito Federal e dos Territórios - TJDFT, a partir do ano de 2015, exclusivamente na área cível, com o
              objetivo de extrair dados relevantes na atuação de Juízes e Varas de 1ª instância no âmbito do E. TJDFT.
            </p>
            <p className="mt-1">
              Importante salientar, que referidos dados tratam-se de meras estimativas -- tanto em relação ao tempo de
              duração dos processos, despachos e decisões, quanto em relação ao conteúdo destas --, não tratando-se,
              portanto, de garantia que as informações trazidas pelo sistema serão, de fato, aplicadas em casos em
              curso, semelhantes ou a serem ajuizados.
            </p>
            <p className="mt-1">
              Nesse contexto, o usuário está plenamente ciente que decisões, sentenças e outras medidas tomadas em
              processos judiciais dependem de inúmeros fatores que o DOMINIA, obviamente, não tem condição de prever o
              resultado, mas apenas servir de norte a atuação de operadores do direito e empresas litigantes na seara
              cível no âmbito do Distrito Federal.
            </p>
            <p className="mt-1">
              O sistema DOMINIA funcionará via website e depende de acesso à internet, podendo, inclusive por este
              motivo, sofrer problemas ou intercorrências em razão de fatores externos (sair do ar temporiamente,
              problemas de conexão, servidor, DNS, queda de energia, entre outros).
            </p>

            <h3 className="mt-5 font-bold">3. Da adesão a um dos planos DOMINIA</h3>
            <p className="mt-1">
              O Usuário deverá efetuar o seu cadastro na plataforma/aplicativo DOMINIA, fornecendo seus dados pessoais e
              aderir à modalidade de plano disponibilizada. Para a realização do cadastro, é obrigatória a leitura,
              compreensão e Aceite dos presentes Termos de Uso e da Política de Privacidade pelo Usuário.
            </p>
            <p className="mt-1">
              ‍O usuário é responsável pela veracidade, validade e precisão dos dados pessoais por ele fornecidos no seu
              cadastro, competindo-lhe manter referido cadastro sempre atualizado.
            </p>
            <p className="mt-1">
              ‍A DOMINIA não tem obrigação de policiar ou fiscalizar os dados pessoais fornecidos pelo usuário, mas
              poderá, a seu exclusivo critério, impedir, vetar ou excluir usuários por informações que lhe pareçam
              suspeitas e/ou inverídicas.
            </p>
            <p className="mt-1">
              O efetivo acesso ao DOMINIA será concedido ao usuário após a compensação do pagamento pelo sistema.
            </p>

            <h3 className="mt-5 font-bold">4. A utilização do DOMINIA pelo usuário</h3>
            <p className="mt-1">
              A DOMINIA concederá ao usuário licenças, em número aderido e adimplido via site, de uso pessoal e
              intransferível para o uso do sistema DOMINIA.
            </p>
            <p className="mt-1">
              O usuário, por sua vez, compromete-se a não utilizar os dados a que terá acesso para qualquer ilícita e/ou
              imoral.
            </p>
            <p className="mt-1">
              O usuário compromete-se, ainda, a não copiar, modificar, distribuir, vender, alugar, compartilhar ou,
              ainda, ceder, a qualquer título, os serviços disponibilizados pela DOMINIA.
            </p>
            <p className="mt-1">
              A DOMONIA poderá, a seu exclusivo critério, suspender, modificar ou encerrar as atividades da do usuário
              plataforma, mediante comunicação a este, em razão de inadimplemento ou mau uso do sistema.
            </p>

            <h3 className="mt-5 font-bold">5. Cancelamento</h3>
            <p className="mt-1">
              O usuário terá o prazo de 7 (sete) dias corridos, após a adesão ao plano, para cancelar a sua assinatura,
              sem qualquer ônus, na forma do Art. 49 do Código de Defesa do Consumidor. O estorno dos valores obedecerá
              a regra de cada bandeira de cartão de crédito/débito.
            </p>
            <p className="mt-1">
              Após acima, o cancelamento antecipado do plano ocasionará multa de 20% (vinte por cento) sobre as parcelas
              vincendas.
            </p>
            <p className="mt-1">O website trará campo próprio para tal finalidade.</p>
            <p className="mt-1">
              O usuário concorda, ainda, que o DOMINIA, à sua livre discrição, poderá cancelar sua utilização do
              Serviço, incluindo, mas não se limitando: (i) por descumprimentos e/ou violação destes Termos; (ii) em
              função de ordem judicial ou requisição legal e competente de autoridade governamental; (iii) por
              requisição do próprio Usuário; (iv) por desativação ou modificação do Serviço (ou de qualquer de suas
              partes); (v) por caso fortuito, força maior e/ou questões de segurança; (vi) por inatividade da conta por
              um período razoável de tempo; (vii) pela suposta prática de qualquer atividade fraudulenta ou ilegal por
              parte do usuário, a critério do DOMINIA; (viii) ao uso inadequado do website, a critério do DOMINIA; e/ou
              (ix) por inadimplemento por parte do usuário de quaisquer obrigações, valores, pagamentos devidos em razão
              do serviço, quando aplicável.
            </p>
            <p className="mt-1">
              O usuário concorda que o término de seu acesso ao serviço, por qualquer razão constante dos termos acima,
              pode ocorrer sem uma notificação prévia e todas as informações e dados constantes poderão ser
              permanentemente apagados.
            </p>

            <h3 className="mt-5 font-bold">‍6. Do canal de comunicação</h3>
            <p className="mt-1">
              ‍Para qualquer assunto relacionado às condições destes Termos de Uso ou da Política de Privacidade, e
              outros assuntos relacionados ao uso do sistema, o usuário deverá entrar em mediante mensagem endereçada ao
              e-mail 
              <a href="mailto:contato@dominia.app" className="underline text-blue-500">
                contato@dominia.app
              </a>
              .
            </p>
            <p className="mt-1">
              Por sua vez, o e-mail cadastrado pelo usuário no momento da adesão do plano será o meio utilizado pelo
              DOMINIA a entrar em contato com o usuário.
            </p>
            <p className="mt-1">
              O usuário autoriza, neste ato, o DOMINIA a enviar para o seu endereço de e-mail mensagens ou outras
              correspondências de caráter informativo, comercial e/ou promocional.
            </p>

            <h3 className="mt-5 font-bold">‍7. Propriedade Intelectual</h3>
            <p className="mt-1">
              As marcas, nomes, logotipos, nomes de domínio e demais sinais distintivos, bem como todo e qualquer
              conteúdo, desenho, arte ou layout publicado website ou aplicativo, são de propriedade exclusiva do
              DOMINIA.
            </p>
            <p className="mt-1">
              São vedados quaisquer atos ou contribuições tendentes à descompilação, engenharia reversa, modificação das
              características, ampliação, alteração, mesclagem ou incorporação em quaisquer outros programas ou sistemas
              do website ou serviço. Enfim, toda e qualquer forma de reprodução do website, serviço ou aplicativo, total
              ou parcial, permanente, temporária ou provisória, de forma gratuita ou onerosa, sob quaisquer modalidades,
              formas ou títulos é expressamente vedada.
            </p>

            <h3 className="mt-5 font-bold">‍8. Responsabilidade</h3>
            <p className="mt-1">
              O usuário tem plena ciência que a utilização das informações obtidas via plataforma DOMINIA é de sua
              inteira e exclusiva responsabilidade. Logo, eventual má utilização ou uso para fins indevidos ou ilícitos
              serão imputados somente a si.
            </p>
            <p className="mt-1">
              O usuário tem plena ciência que a utilização das informações obtidas via plataforma DOMINIA é de sua
              inteira e exclusiva responsabilidade. Logo, eventual má utilização ou uso para fins indevidos ou ilícitos
              serão imputados somente a si.
            </p>
            <p classNMame="mt-1">
              O usuário será automaticamente descadastrado, caso identificada conduta de sua autoria em desacordo com
              os termos de uso ou com a legislação brasileira.
            </p>

            <h3 className="mt-5 font-bold">‍9. Atualizações</h3>
            <p classNMame="mt-1">
              Por se tratar de sistema digital, o DOMINIA sofrerá atualizações, modificações e melhorias constantes,
              tanto em relação a suas funcionalidades quanto layout, as quais independem da aquiescência dos seus
              usuários.
            </p>
            <p className="mt-1">
              O DOMINIA poderá, igualmente, a qualquer tempo, alterar os Termos de Uso ou a Política de Privacidade, a
              seu exclusivo critério, os quais serão informadas por meio do website ou aplicativo. Após a comunicação, o
              usuário passará a ser submetido aos Termos de Uso e Política de Privacidade atualizados.
            </p>

            <h3 className="mt-5 font-bold">‍10. Links para sites e aplicativos de terceiros</h3>
            <p className="mt-1">
              Nosso website e/ou aplicativo podem conter links para sites e aplicativos de terceiros. Note que dentro
              destes sites e aplicativos de terceiros você estará sujeito a outros termos de uso e políticas de
              privacidade. Nossos Termos de Uso e Política de Privacidade não são válidos nos sites e aplicativos de
              terceiros. A existência desses links não significa nenhuma relação de endosso entre o DOMINIA e esses
              terceiros
            </p>

            <h3 className="mt-5 font-bold">‍11. Vigência</h3>
            <p className="mt-1">
              Este termo de uso tem vigência durante toda a duração do plano contratado pelo usuário, a partir do
              aceite.
            </p>
            <p className="mt-1">
              Não obstante, o DOMINIA se reserva o direito de, a qualquer tempo, modificar ou descontinuar,
              temporariamente ou permanentemente, o serviço ou parte dele, com ou sem notificação. O Usuário concorda
              que o DOMINIA não será responsabilizado, nem terá qualquer obrigação adicional, implícita ou explícita,
              para com o Usuário ou terceiros em razão de qualquer modificação, suspensão ou desativação do Serviço,
              desde que reembolsado/estornado os valores pelos meses não utilizados em razão da descontinuidade dos
              serviços.
            </p>
          </div>
        </div>
      </div>
    </IndexLayout>
  );
}
