import React from "react";
import classNames from "../../libs/classnames";

export default function Container({ label, className, children }) {
  return (
    <div className={classNames(className, "w-full flex flex-col lg:flex-row")}>
      <div className="flex-grow border-2 border-cool-gray-300 rounded-lg overflow-hidden">
        <h1 className="p-4 text-base font-bold text-black bg-cool-gray-50">{label}</h1>
        {children}
      </div>
    </div>
  );
}
