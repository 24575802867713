import React from "react";
import Logo from "./logo";

export default function Loading({ text }) {
  return (
    <div className="animate-pulse flex">
      <div className="w-full h-128 text-primary font-bold flex justify-center items-center text-4xl flex-col">
        <Logo className="h-16 w-16 mb-6" />
        {text && <p className="text-lg" >{text}</p>}
      </div>
    </div>
  );
}
