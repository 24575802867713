import React, { useState } from "react";
import useSWR from "swr";
import fetcher from "../libs/fetcher";
import useQuery from "../hooks/use-query";
import BaseLayout from "../components/base-layout";
import SearchInput from "../components/search-input";
import useStickyResult from "../hooks/use-sticky-result";
import Menu from "../components/menu";
import classNames from "../libs/classnames";
import SideFilters from "../components/side-filters";
import TextoRelatorio from "../components/texto-relatorio";
import SideMobileMenu from "../components/side-mobile-menu";
import Relatorio from "../components/estatisticas/relatorio";
import TutorialOperadores from "../components/tutorial-operadores";
import swrNoRevalidateOptions from "../libs/swr-no-revalidate-options";
import generateTema from "../libs/generate-tema";

export default function Jurimetria() {
  const { query, setQuery, pushQuery } = useQuery();
  const [filterOpened, setFilterOpened] = useState(false);
  const tema = query.get("tema");
  const reus = query.getAll("reu");
  const autores = query.getAll("autor");
  const selectedMagistrados = query.getAll("magistrado");
  const selectedVaras = query.getAll("vara");
  const selectedDatas = query.getAll("data");
  const selectedOrgaosJulgadores = query.getAll("orgaoJulgador");
  const favoritos = query.get("favoritos");

  const params = new URLSearchParams();
  if (tema || reus.length > 0 || autores.length > 0) {
    params.set("tema", generateTema(tema, reus, autores));
  }

  if (favoritos) {
    params.set("favoritos", true);
  }
  for (let magistrado of selectedMagistrados) {
    params.append("magistrado", magistrado);
  }
  for (let vara of selectedVaras) {
    params.append("vara", vara);
  }
  for (let data of selectedDatas) {
    params.append("data", data);
  }
  for (let orgaoJulgador of selectedOrgaosJulgadores) {
    params.append("orgaoJulgador", orgaoJulgador);
  }

  const { data: filtros, error: errorFiltros } = useSWR(
    `/movimentacoes/filtros?${params.toString()}`,
    fetcher,
    swrNoRevalidateOptions
  );
  if (errorFiltros) throw errorFiltros;
  const stickyFiltros = useStickyResult(filtros);

  let { data: count, error: errorCount } = useSWR(
    tema ? `/movimentacoes/count?${params.toString()}` : null,
    fetcher,
    swrNoRevalidateOptions
  );
  if (errorCount) throw errorCount;

  const tutorialOperadores = query.get("tutorialOperadores");
  function setTutorialOperadores(tutorialOperadores) {
    setQuery("tutorialOperadores", tutorialOperadores);
    if (!tutorialOperadores) setQuery("tutorialOperadores", null);
    pushQuery();
  }

  return (
    <>
      {tutorialOperadores && <TutorialOperadores onClose={() => setTutorialOperadores(null)} />}
      <BaseLayout>
        <div className="flex flex-grow">
          {tema && <SideFilters hideStatsFilter filtros={stickyFiltros} className={classNames("hidden sm:block")} />}
          {tema && (
            <SideMobileMenu open={filterOpened} onClose={() => setFilterOpened(false)}>
              <SideFilters hideStatsFilter filtros={stickyFiltros} className={classNames("block sm:hidden")} />
            </SideMobileMenu>
          )}
          <main className="mt-3 flex flex-col flex-grow w-full items-center">
            <div className="px-2 mt-3 flex flex-col flex-grow w-full items-center">
              <div className="flex flex-col mt-2 pb-2 bg-white max-w-screen-md w-full print:hidden">
                <SearchInput defaultValue={tema} searchButton={!tema} showHint={!tema} />
              </div>
              {!tema && <Relatorio />}
              {tema && (
                <>
                  <Menu count={count} tema={tema} onFiltrosClick={() => setFilterOpened(true)} />
                  <Relatorio
                    paramsString={params.toString()}
                    title={
                      <TextoRelatorio
                        className="text-cool-gray-700"
                        {...{
                          tema,
                          selectedMagistrados,
                          selectedVaras,
                          selectedDatas,
                          selectedOrgaosJulgadores,
                        }}
                      />
                    }
                  />
                </>
              )}
            </div>
          </main>
        </div>
      </BaseLayout>
    </>
  );
}
