import React from "react";
import useSWR from "swr";
import fetcher from "../libs/fetcher";
import swrNoRevalidateOptions from "../libs/swr-no-revalidate-options";

const UserContext = React.createContext({ user: null, mutate: () => {} });
export function UserProvider({ children }) {
  const { data, mutate } = useSWR(`/usuario`, fetcher, swrNoRevalidateOptions);
  return <UserContext.Provider value={{ user: data, mutate }}>{children}</UserContext.Provider>;
}

export default UserContext;
