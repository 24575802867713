import React, { useState, useEffect } from "react";
import BaseLayout from "../components/base-layout";
import fetcher from "../libs/fetcher";
import AdminMenu from "../components/admin-menu";
import MovimentacaoListItem from "../components/movimentacao-list-item";
import MovimentacaoListItemSkeleton from "../components/movimentacao-list-item-skeleton";
import descricaoDecisao from "../libs/descricao-decisao";
import * as enums from "../enums";
import tratarErrosApi from "../libs/tratar-erros-api";
import useSWR from "swr";
import classNames from "../libs/classnames";
import { sortDecisaoClassificacao } from "../libs/sorts";

const valorInicialTipoDecisao = enums.TIPO_DECISAO_MERITO;

class HistoricoClassificacaoManager {
  static all() {
    const classificacoes = localStorage.getItem("historicoClassificacao");
    return classificacoes && JSON.parse(classificacoes);
  }

  static get(i) {
    if (i === undefined || i === null) throw Error("Deve informar i");
    const classificacoes = HistoricoClassificacaoManager.all();
    if (!classificacoes) {
      return null;
    } else {
      return classificacoes[i];
    }
  }

  static push(classificacao) {
    let classificacoes = HistoricoClassificacaoManager.all();
    localStorage.setItem(
      "historicoClassificacao",
      JSON.stringify(classificacoes ? [...classificacoes, classificacao] : [classificacao])
    );
  }

  static lastI() {
    const classificacoes = HistoricoClassificacaoManager.all();
    return classificacoes && classificacoes.length && classificacoes.length - 1;
  }
}

/** Função horrorosa para o Tailwind conseguir fazer o parsing do CSS */
function getBgGraph(i) {
  switch (i) {
    case 0:
      return "bg-graph-1";
    case 1:
      return "bg-graph-2";
    case 2:
      return "bg-graph-3";
    case 3:
      return "bg-graph-4";
    case 4:
      return "bg-graph-5";
    case 5:
      return "bg-graph-6";
    case 6:
      return "bg-graph-7";
    case 7:
      return "bg-graph-8";
    case 8:
      return "bg-graph-9";
    case 9:
      return "bg-graph-10";
    default:
      return "bg-graph-1";
  }
}

function EstatisticasClassificacoes({ estatisticas }) {
  return (
    <div>
      <div className="p-2">{estatisticas && `${estatisticas.total} classificações`}</div>
      <ol className="p-4 pt-0">
        {estatisticas &&
          estatisticas.dados.sort(sortDecisaoClassificacao).map((e, i) => (
            <li className="mt-3" key={e.classificacao}>
              <h2 className="font-semibold text-gray-700 text-xs">{e.classificacao}</h2>
              <div className="flex items-center">
                <div
                  className={classNames(`${getBgGraph(i)}`, "h-6 rounded mr-2 print:border-black print:border")}
                  style={{ width: `${(e.quantidade / estatisticas.total) * 100 + 1}%` }}
                />
                <span className="font-semibold text-gray-700 text-xs">{e.quantidade}</span>
              </div>
            </li>
          ))}
      </ol>
    </div>
  );
}

export default function AdminClassificacoes() {
  const [tipoDecisao, setTipoDecisao] = useState(valorInicialTipoDecisao);
  const decisoes = enums.decisoes.filter((d) => tipoDecisao === d.tipoDecisao);
  const [iClassificacao, setIClassificacao] = useState(HistoricoClassificacaoManager.lastI() + 1);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [movimentacao, setMovimentacao] = useState(null);
  const { data: estatisticas, mutate, error: estatisticasError } = useSWR("/classificacoes/estatisticas", fetcher);
  if (estatisticasError) throw estatisticasError;

  useEffect(() => {
    async function obterMovimentacao(movimentacao) {
      setError(null);
      setLoading(true);
      try {
        const response = await fetcher(`/movimentacoes/${movimentacao.grau}/${movimentacao.id}`);
        setMovimentacao(response);
      } catch (error) {
        setError(tratarErrosApi(error));
      } finally {
        setLoading(false);
      }
    }

    async function obterMovimentacaoAleatoria() {
      setError(null);
      setLoading(true);
      try {
        const response = await fetcher("/movimentacao_aleatoria");
        setMovimentacao(response);
        HistoricoClassificacaoManager.push({ grau: response.grau, id: response.id });
      } catch (error) {
        setError(tratarErrosApi(error));
      } finally {
        setLoading(false);
      }
    }

    if (iClassificacao > HistoricoClassificacaoManager.lastI()) {
      obterMovimentacaoAleatoria();
    } else {
      const movimentacao = HistoricoClassificacaoManager.get(iClassificacao);
      obterMovimentacao(movimentacao);
    }
  }, [iClassificacao]);

  if (error?.__desconhecido) throw error.__desconhecido;

  async function handleProximo(event) {
    event.preventDefault();
    setIClassificacao(iClassificacao + 1);
    setTipoDecisao(valorInicialTipoDecisao);
  }

  async function handleAnterior(event) {
    event.preventDefault();
    setIClassificacao(iClassificacao - 1);
    setTipoDecisao(valorInicialTipoDecisao);
  }

  function EnviarDecisaoButton({ decisao, tipoDecisao }) {
    async function handleClick(event) {
      event.preventDefault();
      if (!movimentacao) return;
      const data = {
        decisao,
        tipo_decisao: tipoDecisao,
        id_movimentacao: movimentacao.id.toString(),
        integra: movimentacao.integra?.[0] || movimentacao.texto,
        indice: movimentacao.grau,
      };

      setLoading(true);
      setError(null);
      try {
        await fetcher(`/classificacao`, { method: "post", data });
        setIClassificacao((i) => i + 1);
        mutate();
      } catch (error) {
        setError(tratarErrosApi(error));
      } finally {
        setLoading(false);
        setTipoDecisao(valorInicialTipoDecisao);
      }
    }

    return (
      <button onClick={handleClick} className="p-2 bg-gray-100 semibold rounded hover:bg-gray-200">
        {descricaoDecisao(decisao, tipoDecisao)}
      </button>
    );
  }

  function handleEscolhaTipoDecisao(td) {
    return function (event) {
      event.preventDefault();
      setTipoDecisao(td);
    };
  }

  return (
    <BaseLayout>
      <AdminMenu />
      <div className="p-2 grid grid-cols-1 lg:grid-cols-12 mb-20">
        <div className="lg:col-span-2 border-r">
          <div className="p-2">
            Item {iClassificacao} / {HistoricoClassificacaoManager.lastI()}
          </div>
          <EstatisticasClassificacoes estatisticas={estatisticas} />
        </div>
        <div className="p-2 lg:col-span-10">
          <div className="grid grid-cols-2 gap-2">
            <button
              className="p-2 rounded font-semibold bg-gray-100 hover:bg-gray-200 disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={handleAnterior}
              disabled={iClassificacao < 1}
            >
              Anterior
            </button>
            <button className="p-2 rounded font-semibold bg-gray-100 hover:bg-gray-200" onClick={handleProximo}>
              Próximo
            </button>
          </div>
          {error?.error?._message && (
            <p className="text-red-500 text-center p-2 font-semibold">{error?.error?._message}</p>
          )}
          {loading ? (
            <MovimentacaoListItemSkeleton esconderAcoes />
          ) : (
            movimentacao && (
              <>
                <MovimentacaoListItem key={movimentacao._id} {...movimentacao} esconderAcoes esconderDecisao />
                <div className="mb-2" />
                <div className="grid grid-cols-3 gap-2 pb-2">
                  {[enums.TIPO_DECISAO_MERITO, enums.TIPO_DECISAO_TUTELA, enums.TIPO_DECISAO_OUTROS].map((td) => (
                    <button
                      key={td}
                      onClick={handleEscolhaTipoDecisao(td)}
                      className="p-2 bg-gray-100 semibold rounded hover:bg-gray-200"
                    >
                      {td}
                    </button>
                  ))}
                </div>
                <div className="grid grid-cols-2 lg:grid-cols-4 gap-2">
                  {decisoes.map((decisao) => (
                    <EnviarDecisaoButton {...decisao} key={decisao.decisao} />
                  ))}
                </div>
              </>
            )
          )}
        </div>
      </div>
    </BaseLayout>
  );
}
