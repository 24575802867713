import React from "react";
import useQuery from "../hooks/use-query";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/outline";
import formatarNumero from "../libs/formatar-numero";

function PaginationLink({ className, ...props }) {
  return (
    <button
      className={`px-3 py-2 flex items-center border rounded-lg text-sm text-gray-600 font-semibold disabled:opacity-50 hover:bg-gray-100 focus:bg-gray-100 focus:outline-none ${className}`}
      {...props}
    />
  );
}

export default function PaginacaoPesquisa({ pagina, size, count }) {
  const { setQuery, pushQuery } = useQuery();

  function setPagina(pagina) {
    window.scrollTo(0, 0);
    if (pagina !== 1) {
      setQuery("pagina", pagina);
    } else {
      setQuery("pagina", null);
    }
    pushQuery();
  }

  return (
    <>
      <div>
        Exibindo <strong>{formatarNumero((pagina - 1) * size + 1)}</strong> até{" "}
        <strong>{formatarNumero(pagina * size < count ? pagina * size : count)}</strong> de{" "}
        <strong>{formatarNumero(count)}</strong> resultados
      </div>
      <div className="flex">
        <PaginationLink onClick={() => setPagina(pagina - 1)} disabled={pagina === 1}>
          <ChevronLeftIcon className="w-4 h-4" /> Anterior
        </PaginationLink>
        <PaginationLink className="ml-2" onClick={() => setPagina(pagina + 1)} disabled={pagina * size > count}>
          Próximo <ChevronRightIcon className="w-4 h-4" />
        </PaginationLink>
      </div>
    </>
  );
}
