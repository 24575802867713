import React, { useState } from "react";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/outline";
import ModalHeader from "./modal-header";
import ModalContainer from "./modal-cointainer";
import Video from "./video";
import passo1 from "../videos/passo1.mp4";
import passo2 from "../videos/passo2.mp4";
import passo3 from "../videos/passo3.mp4";
import passo4 from "../videos/passo4.mp4";

const passos = [
  {
    title: "1. Busque um tema para listar as movimentações associadas a ele",
    video: passo1,
  },
  {
    title: "2. Analise o resultado e detalhe o processo associado a cada movimentação",
    video: passo2,
  },
  {
    title: "3. Aplique filtros para refinar sua pesquisa",
    video: passo3,
  },
  {
    title: "4. Gere relatórios estatísticos e entenda como o tema é julgado",
    video: passo4,
  },
];

function StepButton(props) {
  return (
    <button
      className="m-2 p-4 rounded-full text-sm md:text-base font-semibold bg-secondary-700 text-white flex items-center"
      {...props}
    />
  );
}

export default function Tutorial({ onClose }) {
  const [videoFullScreen, setVideoFullScreen] = useState(false);
  const [currentPasso, setCurrentPasso] = useState(0);
  const passo = passos[currentPasso];

  const handleClose = videoFullScreen ? () => setVideoFullScreen(false) : onClose;

  const botaoAnterior =
    currentPasso !== 0 ? (
      <StepButton onClick={() => setCurrentPasso((p) => p - 1)}>
        <ArrowLeftIcon className="h-5 w-5" />
      </StepButton>
    ) : (
      <StepButton onClick={onClose}>
        <ArrowLeftIcon className="h-5 w-5" />
      </StepButton>
    );
  const botaoProximo =
    currentPasso < passos.length - 1 ? (
      <StepButton onClick={() => setCurrentPasso((p) => p + 1)}>
        <ArrowRightIcon className="h-5 w-5" />
      </StepButton>
    ) : (
      <StepButton onClick={onClose}>
        <ArrowRightIcon className="h-5 w-5" />
      </StepButton>
    );

  return (
    <ModalContainer onClose={handleClose} fullscreen={videoFullScreen}>
      {videoFullScreen ? (
        <Video
          src={passo.video}
          autoPlay
          loop
          className="fixed top-0 left-0 right-0 bottom-0 cursor-pointer w-screen h-screen"
          onClick={() => setVideoFullScreen((v) => !v)}
        />
      ) : (
        <>
          <ModalHeader title={<span className="text-2xl">{passo.title}</span>} onClose={handleClose} />
          <main className="px-8 py-4 flex flex-col lg:flex-row items-center">
            <div className="flex justify-between w-auto lg:hidden">
              {botaoAnterior} {botaoProximo}
            </div>
            <div className="hidden lg:block">{botaoAnterior}</div>
            <Video
              src={passo.video}
              autoPlay
              loop
              className="rounded-lg cursor-pointer flex-grow w-full"
              onClick={() => setVideoFullScreen((v) => !v)}
            />
            <div className="hidden lg:block">{botaoProximo}</div>
          </main>
        </>
      )}
    </ModalContainer>
  );
}
