import * as enums from "../enums";

function arbitrarySort(arbitraryOrder, a, b) {
  return arbitraryOrder.indexOf(a.id) - arbitraryOrder.indexOf(b.id);
}

export function sortTipoDecisao(a, b) {
  return arbitrarySort([enums.TIPO_DECISAO_MERITO, enums.TIPO_DECISAO_TUTELA, enums.TIPO_DECISAO_OUTROS], a, b);
}

export function sortDecisao(a, b) {
  return arbitrarySort(
    [
      enums.DECISAO_PROVIDO,
      enums.DECISAO_PARCIALMENTE_PROVIDO,
      enums.DECISAO_IMPROVIDO,
      enums.DECISAO_PROCEDENTE,
      enums.DECISAO_PARCIALMENTE_PROCEDENTE,
      enums.DECISAO_IMPROCEDENTE,
      enums.DECISAO_ACORDO,
      enums.DECISAO_DEFERIDO,
      enums.DECISAO_PARCIALMENTE_DEFERIDO,
      enums.DECISAO_INDEFERIDO,
      enums.DECISAO_OUTROS,
    ],
    a,
    b
  );
}

export function sortDecisaoClassificacao(a, b) {
  return arbitrarySort(
    [
      enums.DECISAO_PROVIDO,
      enums.DECISAO_PARCIALMENTE_PROVIDO,
      enums.DECISAO_IMPROVIDO,
      enums.DECISAO_PROCEDENTE,
      enums.DECISAO_PARCIALMENTE_PROCEDENTE,
      enums.DECISAO_IMPROCEDENTE,
      enums.DECISAO_ACORDO,
      enums.DECISAO_DEFERIDO,
      enums.DECISAO_PARCIALMENTE_DEFERIDO,
      enums.DECISAO_INDEFERIDO,
      enums.DECISAO_OUTROS,
    ],
    { id: a?.classificacao },
    { id: b?.classificacao }
  );
}

export function sortById(a, b) {
  let fa = a.id.toLowerCase(),
    fb = b.id.toLowerCase();

  if (fa < fb) {
    return -1;
  }
  if (fa > fb) {
    return 1;
  }
  return 0;
}
