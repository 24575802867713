import React from "react";
import useSWR from "swr";
import BaseLayout from "../components/base-layout";
import Form from "../components/form";
import fetcher from "../libs/fetcher";
import { redirecionarCheckoutAssinatura } from "../libs/gerenciar-assinatura";

function ButtonPlano({ plano, preco, small, extra, ...props }) {
  return (
    <button className="border rounded p-4 hover:bg-gray-100 focus:border-black" {...props}>
      <div className="text-xl font-semibold">{plano}</div>
      <div className="py-2 text-4xl font-bold text-primary-500">
        {preco}
        <small className="text-lg text-gray-600 font-normal">{small}</small>
        <div className="text-sm text-gray-600 font-normal h-6">{extra}</div>
      </div>
    </button>
  );
}

function AssinaturaStatus() {
  const { data: status, error: requestError } = useSWR(`/usuario/assinatura/status`, fetcher);
  if (requestError) throw requestError;
  if (!status) return null;
  if (
    status.tipo !== "PAGAMENTO_INDEFINIDO" &&
    status.tipo !== "ASSINATURA_INDEFINIDA" &&
    status.tipo !== "ASSINATURA_EM_ABERTO"
  )
    return null;

  return <p className="text-gray-500 font-bold">{status.descricao}</p>;
}

export default function AssinaturaPlano() {
  return (
    <BaseLayout>
      <div className="mt-4 flex flex-col items-center w-full">
        <div className="flex flex-col p-4 max-w-screen-md w-full" id="planos-assinatura">
          <div className="flex flex-col">
            <h1 className="text-gray-600 text-xl font-bold">Escolha seu plano</h1>
            <AssinaturaStatus />
            <p className="text-gray-500 text-sm">
              Ao escolher um plano você será redirecionado para nossa plataforma de pagamentos.
            </p>
            <div className="my-2 grid grid-cols-1 sm:grid-cols-2 w-full gap-4">
              <ButtonPlano
                plano="Plano mensal"
                preco="R$ 22,90"
                small="/mês"
                onClick={(event) => redirecionarCheckoutAssinatura(event, "mensal")}
              />
              <ButtonPlano
                plano="Plano anual"
                preco="R$ 229,00"
                small="/ano"
                extra="(R$ 19,09/mês)"
                onClick={(event) => redirecionarCheckoutAssinatura(event, "anual")}
              />
            </div>
          </div>
          <div className="flex items-center justify-end mt-4">
            <Form.Link className="bg-gray-500 hover:bg-gray-600" to="/minha-conta">
              Voltar
            </Form.Link>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
}
