import React from "react";

export default function NumeroProcesso({ numero }) {
  if (!numero) return numero;
  const [principal, ano, ...rest] = numero.split(".");
  if (`${ano}`.length === 4) {
    return (
      <span className="text-secondary-700">
        {principal}.<span className="text-primary-500">{ano}</span>.{rest.join(".")}
      </span>
    );
  } else if (`${principal}`.length === 4) {
    return (
      <span className="text-secondary-700">
        <span className="text-primary-500">{principal}</span>.{ano}.{rest.join(".")}
      </span>
    );
  } else {
    return numero;
  }
}
