import React, { useState, useCallback } from "react";
import { nanoid } from "nanoid";

const AlertsContext = React.createContext({ alerts: [], addAlert: (message, type, id) => {}, closeAlert: (id) => {} });
export function AlertsProvider({ children }) {
  const [alerts, setAlerts] = useState([]);

  const delAlert = useCallback((id) => {
    setAlerts((allAlerts) => allAlerts.filter((a) => a.id !== id));
  }, []);

  const addAlert = useCallback((message, type, id) => {
    if (!id) id = nanoid();
    setAlerts((allAlerts) => [...allAlerts, { message, type, id }]);
  }, []);

  return <AlertsContext.Provider value={{ alerts, addAlert, delAlert }}>{children}</AlertsContext.Provider>;
}

export default AlertsContext;
