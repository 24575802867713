import React, { useEffect } from "react";
import { useLocation } from "react-router";
import IndexLayout from "../components/index-layout";
import IndexSection from "../components/index-section";

export default function QuemSomos() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <IndexLayout>
      <IndexSection>
        <h1 className="pt-8 text-4xl text-center text-primary-500 font-extrabold leading-tight">Quem somos?</h1>
        <div className="px-4 py-8 prose-lg">
          <p>
            <b>DOMINIA</b> é o fruto da união de operadores do direito e de cientistas de dados que detectaram pontos de
            melhoria na atuação de advogados, magistrados e empresários na predição de litígios e demandas.
          </p>
          <p>
            Quanto tempo durará, em média, o processo? Qual a posição do juiz sorteado para a causa sobre o assunto?
            Quais as chances de ganhar uma liminar ou o mérito no caso específico? Tais perguntas são, hoje, respondidas
            pelo operador de direito com base em experiências anteriores e em clara intuição, mas jamais com absoluta
            convicção, na tentativa de transparecer controle sobre a situação que lhe foi confiada.
          </p>
          <p>
            A <b>DOMINIA</b> nasceu, portanto, com o objetivo de prever, com base em dados públicos e oficiais, os rumos
            e tempo útil de um determinado processo, o que, repercute diretamente na capacidade de arbitrar honorários
            justos e precisos.
          </p>
          <p>
            Somos, sobretudo, entusiastas do direito e decidimos mudar o nebuloso e incerto cenário acima, para que
            pudéssemos ter domínio sobre toda e qualquer informação atinente às querelas cíveis. Começamos essa jornada
            com os processos da primeira instância Tribunal de Justiça do Distrito Federal e Territórios – TJDFT.
          </p>
          <p>
            Temos como finalidade precípua a de prover aos nossos clientes a segurança jurídica para que as melhores
            decisões sejam tomadas em prol destes, seja na sua atuação como operador do direito, de forma estratégica em
            decisões empresariais diversas ou até mesmo como um cidadão em busca de informações para ingressar em uma
            causa judicial.
          </p>
        </div>
      </IndexSection>

      <IndexSection>
        <h1 className="pt-8 text-4xl text-center text-primary-500 font-extrabold leading-tight">O que fazemos?</h1>
        <div className="px-4 py-8 prose-lg">
          <p>
            Coletamos e processamos informações oficiais disponibilizadas nos Diários de Justiça (DJe) pelo próprio
            Tribunal de Justiça do Distrito Federal e Territórios – TJDFT, em documentos públicos desde 05 de janeiro de
            2015, mantendo-as sempre atualizadas, com a finalidade de calcular e agregar com precisão novas informação
            úteis aos nossos clientes, para que estes consigam tomar decisões de forma mais segura, alcançando a melhor
            estratégia processual ou procedimental, prevendo riscos e mitigando as consequências dos seus atos.
          </p>
          <p>
            Para tanto, fazemos uso da <b>JURIMETRIA</b>, que é a aplicação de métodos quantitativos, especialmente a
            estatística, para compilar e analisar todos os dados e informações jurídicas captadas. Entre em jogo nossos
            dois robôs: O XPTO, uma poderosa máquina de processamento que monitora incansavelmente a fonte primária da
            informação (DJe), capturando, digerindo e organizando, diariamente, todos os dados para que se tornem mais
            fáceis de serem utilizados de forma massiva, e o OTPX, o cérebro da equipe, que aplica métodos de
            inteligência artificial para detectar, de forma precisa e uniforme, as decisões de qualquer tema, estimando
            as estatísticas e padrões relacionados a como as decisões estão sendo tomadas. Por meio da <b>JURIMETRIA</b>
            , o <b>DOMINIA</b> tem condições de realizar{" "}
            <a href="https://blog.sajadv.com.br/analise-preditiva/">análises descritivas, diagnósticas e preditivas</a>
             mais profundas e assertivas, antecipando cenários reais em busca de resultados viáveis para pretensão de
            nossos usuários, que conseguirão compreender com maior facilidade os processos e fatos jurídicos existentes
            e debatidos na 1ª instância do TJDFT.
          </p>
          <p>
            Os clientes do <b>DOMINIA</b> conseguem analisar, quantificar, prever e ter exata noção como os magistrados
            compreendem e julgam determinado tema ou matéria, sendo capazes de definir quais ações práticas são capazes
            de mitigar danos/riscos processuais, otimizando seus honorários.
          </p>
          <p>
            Como uma de nossas finalidades é descomplicar a atuação de nossos clientes, prestando informações e dados
            valiosos para suas atividades, implementamos sistema de pesquisa simples e intuitivo, permitindo utilização
            de palavras chave e/ou critérios de pesquisa para, de forma célere, proporcionar a todos irrestrito domínio,
            controle e segurança sobre qualquer cenário. Com filtros adicionais de pesquisa, é possível captar
            resultados/decisões de uma determinada Vara Cível do TJDFT, de um dado magistrado, entre outros, podendo
            cumular, ou não, diversas Varas e/ou Magistrados para qualquer tema.
          </p>
          <p>
            <b>DOMINIA</b> consolida os resultados em gráficos com o padrão do comportamento de decisões para um
            determinado tema, de forma que em poucos segundos o gráfico traduz as distribuições percentuais para cada
            tipo de decisão tomada, por exemplo: deferido, indeferido, parcialmente deferido, acordo ou outros.
          </p>
          <p>
            Cada processo exibido pelo <b>DOMINIA</b> pode ser detalhado, exibindo todo seu ciclo de vida nas
            movimentações disponibilizadas no DJe, informando o tempo e a quantidade de movimentações necessárias até
            aquele determinado estágio processual ou até a decisão.
          </p>
        </div>
      </IndexSection>
      <IndexSection className="pb-20">
        <h1 className="pt-8 text-4xl text-center text-primary-500 font-extrabold leading-tight">Qual é o nosso futuro?</h1>
        <div className="px-4 py-8 prose-lg">
          <p>
            O Poder Judiciário tem papel preponderante na retenção das informações que a sociedade precisa para ter
            maior segurança jurídica, uma vez que concentra a grande massa de informações acerca de processos em todo o
            país. Apesar de concentrar tais informações, estas não se encontram organizadas o suficiente, distribuídas
            em sistemas distintos, alguns de baixa qualidade, cadastradas de maneira diversa e com parâmetros variados
            em cada órgão/tribunal do Poder Judiciário deste país. São dezenas de tribunais, cada um utilizando um
            software e sistemas distintos. Um dos objetivos do <b>DOMINIA</b> é fazer com que nossos clientes também
            tenham acesso a todas as informações, mas de maneira simples, eficiente, precisa, menos burocrática e
            totalmente inteligível.
          </p>
        </div>
      </IndexSection>
    </IndexLayout>
  );
}
