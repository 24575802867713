import { set, get, remove } from "js-cookie";
import decode from "jwt-decode";

export default class TokenManager {
  static set(token) {
    const expires = new Date(0);
    expires.setUTCSeconds(decode(token).exp);
    set("AUTH_TOKEN", token, { path: "/", expires, sameSite: "strict", secure: true });
  }

  static info() {
    const token = TokenManager.get();
    if (!token) return null;
    return decode(token);
  }

  static get() {
    return get("AUTH_TOKEN");
  }

  static remove() {
    remove("AUTH_TOKEN");
  }
}
