import React from "react";
import useSWR from "swr";
import fetcher from "../libs/fetcher";
import ModalHeader from "./modal-header";
import ModalContainer from "./modal-cointainer";

export default function AdminPodLogModal({ name, status, onClose }) {
  const { data, error } = useSWR(`/pods/${name}/log`, fetcher);
  if (error) throw error;

  return (
    <ModalContainer onClose={onClose}>
      <ModalHeader title={name} onClose={onClose} />

      <main className="px-8 py-4">
        <textarea className="w-full h-128 bg-black text-white font-mono" disabled defaultValue={data} />
      </main>
    </ModalContainer>
  );
}
