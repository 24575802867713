import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import { ChevronDownIcon } from "@heroicons/react/outline";
import Logo from "./logo";
import SizeDebug from "./size-debug";
import TokenManager from "../libs/token-manager";
import Alerts from "./alerts";
import { temRole, Roles } from "../libs/roles";
import LogoTexto from "./logo-texto";
import classNames from "../libs/classnames";
import primeiraPagina from "../libs/primeira-pagina";
import encurtarNome from "../libs/encurtar-nome";
import formLink from "../libs/form-link";
import BotaoAssinatura from "./v2/botao-assinatura";

export default function BaseLayout({ children, clean }) {
  const info = TokenManager.info();
  const nome = info?.nome || "";
  const email = info?.email || "";

  return (
    <div className={classNames(clean ? "bg-white" : "bg-cool-gray-50", " flex flex-col")}>
      <div className="antialiased flex flex-col min-h-screen">
        <SizeDebug />

        <header className={classNames(clean ? "bg-white" : "bg-secondary-700 w-full print:hidden")}>
          {/* <Banner /> */}
          <div className={classNames("py-3 px-3 flex justify-between")}>
            <div className="flex justify-between items-center w-auto">
              <div className={classNames(clean ? "hidden" : "ml-0")}>
                <Link to={primeiraPagina()}>
                  <LogoTexto className="h-8 hidden sm:block" dark />
                  <Logo className="h-10 text-primary-500 sm:hidden" />
                </Link>
              </div>
            </div>

            <Menu as="div" className="ml-3 relative">
              {({ open }) => (
                <>
                  <div className="flex">
                    <div className="mr-2 flex">
                      <BotaoAssinatura />
                    </div>
                    <Menu.Button className="max-w-xs text-white bg-primary-500 flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-primary-500 p-2 rounded-md hover:bg-primary-600">
                      <span className="ml-3 text-white text-sm font-medium">
                        <span className="sr-only">Abrir menu</span>
                        {encurtarNome(nome)}
                      </span>
                      <ChevronDownIcon className="flex-shrink-0 ml-1 h-5 w-5 text-white" aria-hidden="true" />
                    </Menu.Button>
                  </div>
                  <Transition
                    show={open}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      static
                      className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                    >
                      {temRole(Roles.COFUNDADOR, Roles.ASSINANTE, Roles.USUARIO) && (
                        <Menu.Item>
                          {({ active }) => (
                            <NavLink to="/pesquisa" active={active}>
                              Pesquisar no Dominia
                            </NavLink>
                          )}
                        </Menu.Item>
                      )}
                       {temRole(Roles.COFUNDADOR, Roles.ASSINANTE, Roles.USUARIO) && (
                        <Menu.Item>
                          {({ active }) => (
                            <NavLink to="/pesquisa?favoritos=1" active={active}>
                              Favoritos
                            </NavLink>
                          )}
                        </Menu.Item>
                      )}
                      {temRole(Roles.COFUNDADOR, Roles.ASSINANTE, Roles.USUARIO) && (
                        <Menu.Item>
                          {({ active }) => (
                            <NavLink to="/historico" active={active}>
                              Histórico de busca
                            </NavLink>
                          )}
                        </Menu.Item>
                      )}
                      <div className="w-full border-b my-1 border-primary-50" />
                      <Menu.Item>
                        {({ active }) => (
                          <NavLink to="/pesquisa?tutorial=1" active={active}>
                            Ajuda
                          </NavLink>
                        )}
                      </Menu.Item>
                      <a
                        href={formLink(email)}
                        target="_blank"
                        rel="noreferrer"
                        className={classNames("block px-4 py-2 text-sm hover:bg-gray-100 text-primary-500")}
                      >
                        Avalie o Dominia
                      </a>
                      <div className="w-full border-b my-1 border-primary-50" />
                      {temRole(Roles.COFUNDADOR, Roles.ASSINANTE, Roles.USUARIO) && (
                        <Menu.Item>
                          {({ active }) => (
                            <NavLink active={active} to="/minha-conta">
                              Sua conta
                            </NavLink>
                          )}
                        </Menu.Item>
                      )}
                      <Menu.Item>
                        {({ active }) => (
                          <NavLink to="/" onClick={() => TokenManager.remove()} active={active}>
                            Sair
                          </NavLink>
                        )}
                      </Menu.Item>
                      {temRole(Roles.COFUNDADOR) && (
                        <>
                          <div className="w-full border-b my-1 border-primary-50" />
                          <Menu.Item>
                            {({ active }) => (
                              <NavLink to="/admin/usuarios" active={active}>
                                Admin
                              </NavLink>
                            )}
                          </Menu.Item>
                        </>
                      )}
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>
          </div>
        </header>

        <div className="flex flex-col items-center flex-grow w-full">
          <div
            className={classNames(
              clean ? "" : "max-w-screen-2xl border-r border-l print:border-0",
              "flex-grow flex flex-col overflow-x-auto bg-white w-full"
            )}
          >
            <Alerts className="mt-4" />
            {children}
          </div>
        </div>
        <footer>
          <div className="bg-gray-100 text-gray-600">
            <div className={classNames("flex justify-between items-center py-4 px-8 text-xs")}>
              <Logo className="h-8" />
              <div className="text-right">
                <p>© {new Date().getFullYear()} Dominia</p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}

function NavLink({ className, active, children, ...props }) {
  return (
    <Link
      className={classNames(active ? "bg-gray-100" : "", "block px-4 py-2 text-sm text-gray-700", className)}
      {...props}
    >
      {children}
    </Link>
  );
}
