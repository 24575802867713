import React from "react";

export default function LogoTexto({ dark = false, ...props }) {
  const logo = "#b03434";
  let texto = "#16203B";
  if (dark) texto = "#fff";

  const cls2 = { fillRuleA: "evenodd", fill: logo };
  const cls3 = { fill: texto };
  const cls4 = { fill: logo };
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="300 860 1400 280" {...props}>
      <path
        style={cls2}
        d="M390.69,948.32H494.05v103.36H390.69Zm128.6-25.24V892.74h30.34v30.34Zm-25.24,0H376.19a10.92,10.92,0,0,0-10.74,10.75v143.09H508.54a10.92,10.92,0,0,0,10.75-10.74V948.32h55.58V867.51H494.05ZM335.11,1107.26V933.83a41.26,41.26,0,0,1,41.08-41.09h92.62V867.5H376.19a66.51,66.51,0,0,0-66.32,66.33V1132.5H508.54a66.5,66.5,0,0,0,66.32-66.32V973.56H549.63v92.62a41.27,41.27,0,0,1-41.09,41.08Z"
      />
      <path
        style={cls3}
        d="M675,1082.32q-16.05,0-16.05-15.88V933.56q0-15.87,16.05-15.88h45.78q41.78,0,64.55,21.25t22.77,61q0,39.47-22.77,60.95t-64.55,21.49Zm14.16-24.76h29.74q57.57,0,57.58-57.68,0-57.43-57.58-57.44H689.14Z"
      />
      <path
        style={cls3}
        d="M913.34,1084.65q-24.08,0-42.13-10.51a71.67,71.67,0,0,1-28-29.54q-9.91-19-9.92-44.72,0-25.92,9.92-44.83a71.31,71.31,0,0,1,27.84-29.31q17.94-10.4,42.25-10.39t42.24,10.39a71.4,71.4,0,0,1,27.85,29.31q9.92,18.91,9.91,44.6,0,25.92-9.91,44.95a71.93,71.93,0,0,1-27.85,29.54Q937.64,1084.66,913.34,1084.65Zm0-25.69q22.89,0,35.63-15.64t12.75-43.44q0-28-12.63-43.43T913.34,941q-22.9,0-35.64,15.41T865,999.88q0,27.79,12.74,43.44T913.34,1059Z"
      />
      <path
        style={cls3}
        d="M1038.18,1084.18q-13.7,0-13.69-14V929.83q0-14,14.16-14,5.89,0,9.32,2.21c2.28,1.49,4.37,4,6.26,7.59l54.51,99.72,54.52-99.72c1.89-3.58,3.93-6.1,6.13-7.59s5.27-2.21,9.21-2.21q13.68,0,13.69,14v140.34q0,14-13.69,14t-13.69-14V971.39l-44.37,80.1a19,19,0,0,1-5,5.84,11.91,11.91,0,0,1-7.08,1.87,12.44,12.44,0,0,1-7.32-2,17.19,17.19,0,0,1-5-5.72l-44.36-79.63v98.31Q1051.87,1084.18,1038.18,1084.18Z"
      />
      <path
        style={cls3}
        d="M1244,1084.18q-15.1,0-15.1-15.41V931.23q0-15.42,15.1-15.41t15.1,15.41v137.54Q1259.07,1084.19,1244,1084.18Z"
      />
      <path
        style={cls3}
        d="M1309.58,1084.18q-14.16,0-14.16-14.47v-139c0-4.82,1.21-8.52,3.66-11.09a12.54,12.54,0,0,1,9.55-3.85q5.43,0,8.14,2a38.25,38.25,0,0,1,6.5,6.66l84.25,108.12V930.06q0-14.23,14.16-14.24,13.92,0,13.92,14.24v140.11q0,6.54-3.3,10.28a11.37,11.37,0,0,1-9,3.73q-5.43,0-8.61-2.1a32.47,32.47,0,0,1-7-6.77L1323.5,967v102.75Q1323.5,1084.19,1309.58,1084.18Z"
      />
      <path
        style={cls4}
        d="M1487.05,1084.18q-15.1,0-15.11-15.41V931.23q0-15.42,15.11-15.41t15.1,15.41v137.54Q1502.15,1084.19,1487.05,1084.18Z"
      />
      <path
        style={cls4}
        d="M1540.15,1084.18q-8.26,0-11.8-5.6t.23-13.54l62.31-136.61q3.06-6.78,7.43-9.7a17.65,17.65,0,0,1,10-2.91,17.25,17.25,0,0,1,9.79,2.91q4.36,2.92,7.44,9.7L1688.12,1065q3.77,8.18.47,13.66t-11.33,5.48q-6.62,0-10.26-3.15t-6.49-9.69l-11.8-27.09h-81.19L1556,1071.34q-3.07,6.78-6.37,9.81T1540.15,1084.18Zm67.73-134-30,70.29h60.41l-30-70.29Z"
      />
    </svg>
  );
}
