import { useMemo } from "react";
import { useLocation, useHistory } from "react-router-dom";

export default function useQuery() {
  const history = useHistory();
  const search = useLocation().search
  const query = useMemo(() => new URLSearchParams(search), [search]);

  function setQuery(key, value) {
    if (value?.map) {
      // is array
      query.delete(key);
      if (value.length > 0) {
        for (let item of value) {
          query.append(key, item);
        }
      }
    } else {
      // is not array
      if (value) {
        query.set(key, value);
      } else {
        query.delete(key);
      }
    }
  }

  function pushQuery() {
    history.push(`?${query.toString()}`);
  }

  return { query, setQuery, pushQuery };
}
