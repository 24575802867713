import React from "react";
import useSWR from "swr";
import BaseLayout from "../components/base-layout";
import fetcher from "../libs/fetcher";
import * as Table from "../components/table";
import AdminMenu from "../components/admin-menu";

export default function AdminPdfs() {
  const { data: pdfs, error } = useSWR(`/pdfs`, fetcher);
  if (error) throw error;

  return (
    <BaseLayout>
      <AdminMenu />
      {pdfs && (
        <Table.Container>
          <thead className="bg-gray-50">
            <tr>
              <Table.HeadRow>Nome</Table.HeadRow>
              <Table.HeadRow>Tamanho</Table.HeadRow>
              <Table.HeadRow>Última atualização</Table.HeadRow>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {pdfs.map((pdf) => (
              <tr className="text-sm text-gray-900" key={pdf.file}>
                <Table.Row>{pdf.file}</Table.Row>
                <Table.Row>{Math.ceil(pdf.stat.size / 1024 / 1024)} MB</Table.Row>
                <Table.Row>{pdf.stat.mtime}</Table.Row>
              </tr>
            ))}
          </tbody>
        </Table.Container>
      )}
      <div className="mb-10" />
    </BaseLayout>
  );
}
