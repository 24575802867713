import React, { useState } from "react";
import { useEffect } from "react";
import classNames from "../libs/classnames";
import Loading from "./loading";

export default function Video(props) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
  }, [props.src]);
  return (
    <>
      {loading && (
        <div className="flex w-full justify-center">
          <Loading text="Por favor, aguarde." />
        </div>
      )}
      <div className={classNames(loading && "hidden")}>
        <video {...props} onLoadedData={() => setLoading(false)} />
      </div>
    </>
  );
}
