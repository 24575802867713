import {
  AtSymbolIcon,
  CalendarIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  CurrencyDollarIcon,
  PhoneIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import React, { useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import AlertsContext from "../contexts/alerts-context";
import fetcher from "../libs/fetcher";
import formatarData from "../libs/formatar-data";

export default function AdminUsuarioItem({ index, usuario, mutate }) {
  const history = useHistory();
  const [mostrarEdit, setMostrarEdit] = useState(false);
  const { addAlert } = useContext(AlertsContext);

  async function handleCadastrarSendgrid() {
    try {
      await fetcher(`/usuarios/${usuario._id}/sendgrid`, { method: "post" });
      await mutate();
      addAlert(`Cadastro do send grid gerado! ${usuario._id}`, "success", `sendgrid-cadastrado-${usuario._id}`);
    } catch (error) {
      addAlert(`ERROR! ${usuario._id}: ${JSON.stringify(error)}`, "error", `sendgrid-error-${usuario._id}`);
    }
  }

  async function handleImpersonar() {
    if (window.confirm("Você vai logar como esse usuário! MUITO CUIDADO! TEM CERTEZA QUE DESEJA PROSSEGUIR?!!?")) {
      const json = await fetcher(`/usuarios/${usuario.email}/token`);
      history.push(`/token?token=${json}`);
    }
  }

  async function handleApagar(event) {
    event.preventDefault();
    if (window.confirm(`Realmente apagar ${usuario.nome}?`)) {
      try {
        await fetcher(`/usuario/${usuario._id}`, { method: "delete" });
        await mutate();
      } catch (error) {
        addAlert(`ERROR! ${usuario._id}: ${JSON.stringify(error)}`, "error", `apagar-${usuario._id}`);
      }
    }
  }

  function handleToggleMostrarEdit() {
    setMostrarEdit((mostrar) => !mostrar);
  }

  async function handleSubmitAtualizar(event) {
    event.preventDefault();
    const form = event.target;
    const dados = form.elements.namedItem("json").value;

    try {
      await fetcher(`/usuario/${usuario._id}`, { method: "post", data: JSON.parse(dados) });
      await mutate();
      addAlert(`Usuario atualizado ${usuario._id}!`, "success", `atualizar-${usuario._id}`);
    } catch (error) {
      addAlert(`ERROR! ${usuario._id}: ${JSON.stringify(error)}`, "error", `atualizar-${usuario._id}`);
    }
  }

  return (
    <div className="mt-2 w-full border rounded-lg shadow text-gray-700 overflow-hidden">
      <header className="flex flex-col border-b border-gray-200">
        <div className="py-3 px-5">
          <div className="flex flex-col md:flex-row justify-between items-bottom">
            <div className="text-base ml-1 text-gray-500 font-bold ">
              <span className="text-cool-gray-900">#{index + 1}</span> {usuario.nome}
            </div>
            <div className="flex items-center">
              <div className="md:mr-1 md:pl-2 md:pr-1 py-1 text-xs rounded-full font-bold leading-loose bg-white">
                <div className="hidden text-xs mr-1 text-gray-300 text-right md:block">{usuario._id}</div>
              </div>
              <div className="px-3 text-xs rounded-full font-bold leading-loose bg-cool-gray-200 text-cool-gray-700">
                {usuario.role === "ASSINANTE" ? "USUÁRIO" : usuario.role}
              </div>
            </div>
          </div>

          <div className="mt-1 flex flex-col lg:flex-row justify-between">
            <div className="flex flex-col lg:flex-row">
              <div className="flex lg:mr-4 text-gray-500 font-semibold text-sm items-begin">
                <AtSymbolIcon className="w-5 h-5 mr-1 text-primary-500" />{" "}
                <span className="text-secondary-700">{usuario.email}</span>
              </div>
              {usuario.celular && (
                <div className="flex lg:mr-4 text-gray-500 font-semibold text-sm items-begin">
                  <PhoneIcon className="w-5 h-5 mr-1 text-primary-500" />{" "}
                  <span className="text-secondary-700">{usuario.celular}</span>
                </div>
              )}
              <div className="flex text-gray-500 font-semibold text-sm items-begin">
                <CurrencyDollarIcon className="w-5 h-5 mr-1 text-primary-500" />{" "}
                <span className="text-secondary-700">
                  {usuario.plano_manual ? "Manual" : usuario.stripe_status ? usuario.stripe_status : "Não assinante"}
                </span>
              </div>
            </div>

            <div className="flex text-gray-500 font-semibold text-sm items-begin">
              <CalendarIcon className="w-5 h-5 mr-1 text-primary-500" />{" "}
              <span className="text-secondary-700">Atualizado {formatarData(usuario.atualizado_em)}</span>
            </div>
          </div>
        </div>
      </header>
      <main className="p-4">
        <div className="p-2 flex">
          <Link
            to={`/admin/historico/${usuario._id}`}
            className="m-1 flex items-center justify-center w-full p-2 text-sm font-semibold text-gray-700 bg-gray-100 hover:text-gray-900 hover:bg-gray-200"
          >
            Histórico de busca
          </Link>

          <button
            className="m-1 flex items-center justify-center w-full p-2 text-sm font-semibold text-gray-700 bg-gray-100 hover:text-gray-900 hover:bg-gray-200"
            onClick={handleCadastrarSendgrid}
          >
            Cadastrar no SendGrid
          </button>
          <button
            className="m-1 flex items-center justify-center w-full p-2 text-sm font-semibold text-gray-700 bg-gray-100 hover:text-gray-900 hover:bg-gray-200"
            onClick={handleImpersonar}
          >
            Impersonar
          </button>
        </div>
        <div className="border-b border-gray-200 bg-cool-gray-100">
          <button
            className="p-2 flex items-center justify-between w-full text-cool-gray-600 focus:outline-none"
            onClick={handleToggleMostrarEdit}
          >
            <span className="font-semibold text-sm uppercase">Editar</span>
            {mostrarEdit ? <ChevronUpIcon className="w-5 h-5" /> : <ChevronDownIcon className="w-5 h-5" />}
          </button>
          {mostrarEdit && (
            <form onSubmit={handleSubmitAtualizar} className="pb-2">
              <textarea
                name="json"
                className="w-full border mt-2 h-40"
                defaultValue={JSON.stringify(usuario, null, 2)}
              />
              <div className="pt-2 w-full flex justify-end">
                <button
                  type="submit"
                  className="mr-2 px-2 py-1 rounded-lg flex items-center bg-secondary-700 text-white font-semibold text-sm"
                >
                  Atualizar
                </button>
                <button
                  type="button"
                  className="px-2 py-1 rounded-lg flex items-center bg-red-500 text-white font-semibold text-sm"
                  onClick={handleApagar}
                >
                  <TrashIcon className="h-4 w-4 mr-2" />
                  Apagar
                </button>
              </div>
            </form>
          )}
        </div>
      </main>
    </div>
  );
}
