import React from "react";

export default function Logo({ fg = "#fff", ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3000 3000" fill="currentColor" stroke="currentColor" {...props}>
      <path
        d="M1087.83,1087.52h982v982h-982ZM2309.63,847.7V559.43h288.3V847.7Zm-239.8,0H950.05C894.93,847.7,848,894.58,848,949.76l0,1359.58H2207.56c55.14,0,102.07-46.91,102.07-102V1087.52h528.12V319.66H2069.83ZM559.72,2597.62V949.76c0-214.32,176-390.33,390.33-390.33h880V319.61h-880c-346.63,0-630.13,283.52-630.13,630.15V2837.4H2207.56c346.63,0,630.13-283.48,630.13-630.1l.06-880H2597.93l0,880c0,214.22-176.11,390.3-390.33,390.3Z"
      />
    </svg>
  );
}
