import React, { useContext, useState } from "react";
import BaseLayout from "../components/base-layout";
import Form from "../components/form";
import AlertsContext from "../contexts/alerts-context";
import fetcher from "../libs/fetcher";
import TokenManager from "../libs/token-manager";
import tratarErrosApi from "../libs/tratar-erros-api";
import BotaoAssinatura from "../components/v2/botao-assinatura";
import UserContext from "../contexts/user-context";

export default function MinhaConta() {
  const { addAlert } = useContext(AlertsContext);
  const { user: usuario, mutate } = useContext(UserContext);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  if (error?.__desconhecido) throw error.__desconhecido;

  async function handleSubmit(event) {
    event.preventDefault();
    setError(null);

    const form = event.target;
    const nome = form.elements.namedItem("nome").value;
    const email = form.elements.namedItem("email").value;
    const celular = form.elements.namedItem("celular").value;
    const cpf = form.elements.namedItem("cpf").value;
    const senha = form.elements.namedItem("senha").value;
    const confirmacaoSenha = form.elements.namedItem("confirmacao-senha").value;

    if (senha !== confirmacaoSenha) {
      return setError({
        senha: "Senha e confirmação de senha devem ser exatamente iguais",
      });
    }

    try {
      setLoading(true);
      const token = await fetcher("/usuario", { method: "post", data: { nome, email, celular, cpf, senha } });
      form.elements.namedItem("senha").value = "";
      form.elements.namedItem("confirmacao-senha").value = "";
      addAlert("Cadastro atualizado com sucesso!", "success", "cadastro-alterado");
      TokenManager.set(token);
      mutate();
    } catch (error) {
      setError(tratarErrosApi(error));
    } finally {
      setLoading(false);
    }
  }

  return (
    <BaseLayout>
      <div className="mt-4 flex flex-col items-center w-full">
        {usuario && (
          <>
            <div className="flex flex-col p-4 max-w-screen-md w-full" id="planos-assinatura">
              <div className="flex flex-col">
                <h1 className="text-gray-600 text-xl font-bold">Assinatura</h1>
                <p className="text-gray-500 text-sm">
                  Ao clicar no botão você será redirecionado para nossa plataforma de pagamentos.
                </p>
                <div className="my-2 flex">
                  <BotaoAssinatura theme="dark" size="big" />
                </div>
              </div>
              <hr className="block mt-4" />
            </div>
            <form className="flex flex-col p-4 max-w-screen-md w-full" onSubmit={handleSubmit}>
              <div className="flex flex-col">
                <h1 className="text-gray-600 text-xl font-bold">Informações pessoais</h1>
                <Form.Label htmlFor="nome" className="mt-8 font-semibold">
                  Nome completo
                </Form.Label>
                <Form.Input
                  className="mt-2"
                  type="text"
                  id="nome"
                  name="nome"
                  placeholder="Nome completo"
                  required
                  errored={error?.nome}
                  defaultValue={usuario.nome}
                />

                <Form.Label htmlFor="email" className="mt-4 font-semibold">
                  E-mail
                </Form.Label>
                <Form.Input
                  className="mt-2"
                  type="email"
                  id="email"
                  name="email"
                  placeholder="E-mail"
                  required
                  errored={error?.email}
                  defaultValue={usuario.email || ""}
                  autoComplete="username"
                />

                <Form.Label htmlFor="celular" className="mt-4 font-semibold">
                  Celular
                </Form.Label>
                <Form.Input
                  className="mt-2"
                  type="tel"
                  id="celular"
                  name="celular"
                  placeholder="(00) 00000-0000"
                  errored={error?.celular}
                  defaultValue={usuario.celular || ""}
                />

                <Form.Label htmlFor="cpf" className="mt-4 font-semibold">
                  CPF
                </Form.Label>

                <Form.Cpf
                  className="mt-2"
                  id="cpf"
                  name="cpf"
                  placeholder="000.000.000-00"
                  errored={error?.cpf}
                  defaultValue={usuario.cpf || ""}
                />

                <hr className="block mt-4" />
                <h1 className="text-gray-600 text-xl font-bold mt-4">Segurança</h1>
                <p className="text-gray-500 text-sm">
                  Preencha os campos abaixo somente em caso de alteração de senha.
                </p>

                <Form.Label className="mt-8" htmlFor="senha">
                  Senha
                </Form.Label>
                <Form.Input
                  className="mt-2"
                  type="password"
                  id="senha"
                  name="senha"
                  placeholder="********"
                  errored={error?.senha}
                  autoComplete="new-password"
                />

                <Form.Label className="mt-4" htmlFor="confirmacao-senha">
                  Confirmação de senha
                </Form.Label>
                <Form.Input
                  className="mt-2"
                  type="password"
                  id="confirmacao-senha"
                  name="confirmacao-senha"
                  placeholder="********"
                  errored={error?.senha}
                  autoComplete="new-password"
                />

                <hr className="block mt-4" />
                <p className="text-gray-700 italic text-sm text-center mt-4">
                  Ao se cadastrar você aceita os{" "}
                  <a
                    href="https://dominia.app/#cadastro-form"
                    rel="noreferrer"
                    target="_blank"
                    className="underline text-blue-500"
                  >
                    termos de uso
                  </a>{" "}
                  e{" "}
                  <a
                    href="https://dominia.app/#cadastro-form"
                    rel="noreferrer"
                    target="_blank"
                    className="underline text-blue-500"
                  >
                    termos de privacidade
                  </a>
                  .
                </p>

                <div className="flex items-center justify-end mt-4">
                  <Form.Button className="bg-primary-500 hover:bg-primary-600" type="submit" disabled={loading}>
                    Alterar cadastro
                  </Form.Button>
                </div>
              </div>
            </form>
          </>
        )}
      </div>
    </BaseLayout>
  );
}
