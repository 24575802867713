import * as enums from "../enums";

export default function descricaoDecisao(decisao, tipoDecisao) {
  switch (decisao) {
    case enums.DECISAO_PROCEDENTE:
      return "Procedente";
    case enums.DECISAO_DEFERIDO:
      return "Deferido";
    case enums.DECISAO_PARCIALMENTE_PROCEDENTE:
      return "Parcialmente procedente";
    case enums.DECISAO_PARCIALMENTE_DEFERIDO:
      return "Parcialmente deferido";
    case enums.DECISAO_IMPROCEDENTE:
      return "Improcedente";
    case enums.DECISAO_INDEFERIDO:
      return "Indeferido";
    case enums.DECISAO_ACORDO:
      return "Acordo";
    case enums.DECISAO_IMPROVIDO:
      return "Improvido";
    case enums.DECISAO_PROVIDO:
      return "Provido";
    case enums.DECISAO_PARCIALMENTE_PROVIDO:
      return "Parcialmente provido";
    default:
      if (tipoDecisao === enums.TIPO_DECISAO_MERITO) {
        return "Sentença";
      } else if (tipoDecisao === enums.TIPO_DECISAO_TUTELA) {
        return "Tutela";
      } else if (tipoDecisao === enums.TIPO_DECISAO_ACORDAO) {
        return "Acórdão";
      } else {
        return "Despacho";
      }
  }
}
