export default function mapFiltrosData(options) {
  if (!options) return [];
  return options.map((option) => {
    const [ano] = option.key_as_string.split("-");
    return {
      id: ano,
      description: ano,
      count: option.doc_count,
    };
  });
}
