import React, { useState } from "react";
import { Link } from "react-router-dom";
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";

function Button({ className, ...props }) {
  return (
    <button
      className={`py-2 px-4 text-white font-bold rounded focus:outline-none cursor-pointer focus:shadow-outline disabled:opacity-50 disabled:cursor-wait	${className}`}
      {...props}
    />
  );
}

function Input({ className, errored, ...props }) {
  const errorClass = errored ? "border-red-500" : "";
  return (
    <>
      <input className={`shadow form-input border ${errorClass} ${className}`} {...props} />
      <p className="text-red-500 text-xs italic mt-1 h-1">{errored || " "}</p>
    </>
  );
}

function Cpf({ className, errored, defaultValue,...props }) {
  const [cpf, setCpf] = useState(defaultValue);

  const errorClass = errored ? "border-red-500" : "";
  return (
    <>
      <CpfCnpj
        className={`shadow form-input border ${errorClass} ${className}`}
        {...props}
        type="tel"
        value={cpf}
        onChange={(ev) => {
          const newValue = ev.target.value;
          if (newValue.length <= 14) setCpf(newValue);
        }}
      />
      <p className="text-red-500 text-xs italic mt-1 h-1">{errored || " "}</p>
    </>
  );
}

function Label({ className, ...props }) {
  return <label className={`text-gray-700 font-semibold text-sm ${className}`} {...props} />;
}

function FormLink({ className, ...props }) {
  return (
    <Link
      className={`py-2 px-4 text-white font-bold rounded focus:outline-none cursor-pointer focus:shadow-outline disabled:opacity-50 disabled:cursor-wait	${className}`}
      {...props}
    />
  );
}

function A({ className, children, ...props }) {
  return (
    <a
      className={`py-2 px-4 text-white font-bold rounded focus:outline-none cursor-pointer focus:shadow-outline disabled:opacity-50 disabled:cursor-wait	${className}`}
      {...props}
    >
      {children}
    </a>
  );
}

const Form = { Input, Button, Label, Link: FormLink, A, Cpf };
export default Form;
