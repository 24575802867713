import React from "react";
import { StarIcon as StarIconSolid } from "@heroicons/react/solid";
import { StarIcon as StarIconOutline } from "@heroicons/react/outline";
import useQuery from "../../hooks/use-query";

export default function SideFilterFavoritos() {
  const { query, setQuery, pushQuery } = useQuery();
  const favoritos = query.get("favoritos");

  function toggleSelection(event) {
    event.preventDefault();
    if (!favoritos) setQuery("favoritos", 1);
    else setQuery("favoritos", null);

    setQuery("pagina", 1);
    pushQuery();
  }
  return (
    <div className="py-2 flex gap-1 px-2">
      <button className="flex gap-1 items-center" onClick={toggleSelection}>
        {favoritos ? <StarIconSolid className="w-5 h-5" /> : <StarIconOutline className="w-5 h-5" />}
        <div className="font-semibold text-cool-gray-600">Favoritos</div>
      </button>
    </div>
  );
}
