export default function mapFiltros(options, descriptionForIdFn) {
  if (!options) return [];
  return options
    .filter((option) => option?.key)
    .map((option) => ({
      id: option.key,
      count: option.doc_count,
      description: descriptionForIdFn(option.key),
    }));
}
