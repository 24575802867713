import React from "react";
import { useLocation } from "react-router-dom";
import classNames from "../libs/classnames";
import { DocumentReportIcon, FilterIcon, SearchIcon } from "@heroicons/react/outline";
import Tabs from "./v2/tabs2";

export default function Menu({ tema, count, onFiltrosClick }) {
  const location = useLocation();

  function activeProps(active, primary = true, className) {
    return {
      className: classNames(
        active ? "" : "hidden",
        primary
          ? "bg-primary-500 hover:bg-primary-600 text-white"
          : "bg-cool-gray-100 hover:bg-cool-gray-200 text-cool-gray-900",
        className,
        "rounded-lg my-1 mx-1 py-2 px-3 text-base font-bold text-center flex items-center focus:outline-none"
      ),
    };
  }

  return (
    <div className="flex flex-col w-full my-2 print:hidden">
      <Tabs
        tabs={[
          { name: "Resultado da pesquisa", href: `/pesquisa${location?.search}`, icon: SearchIcon },
          { name: "Relatório de Jurimetria", href: `/jurimetria${location?.search}`, icon: DocumentReportIcon },
        ]}
      />
      <nav className="flex flex-col lg:flex-row lg:items-center lg:justify-center w-full">
        <button {...activeProps(true, false, "block sm:hidden")} onClick={onFiltrosClick}>
          <FilterIcon className="h-5 w-5 mr-3" />
          <div>Filtros</div>
        </button>
      </nav>
    </div>
  );
}
