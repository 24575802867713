import React, { useCallback, useContext, useState } from "react";
import { StarIcon } from "@heroicons/react/solid";
import UserContext from "../../contexts/user-context";
import classNames from "../../libs/classnames";
import fetcher from "../../libs/fetcher";
import AlertsContext from "../../contexts/alerts-context";

export default function FavoritoButton({ idMovimentacao }) {
  const [disabled, setDisabled] = useState();

  const { user, mutate } = useContext(UserContext);
  const { addAlert } = useContext(AlertsContext);
  const favoritar = useCallback(async () => {
    try {
      setDisabled(true);
      await fetcher(`/usuario/movimentacao/favorito/${idMovimentacao}`, { method: "post" });
      await mutate();
      addAlert(`Favorito adicionado com sucesso!`, "success");
    } catch (error) {
      addAlert(`Falha! Não foi possível adicionar favorito.`, "error");
    } finally {
      setDisabled(false);
    }
  }, [idMovimentacao, addAlert, mutate, setDisabled]);

  const desfavoritar = useCallback(async () => {
    try {
      setDisabled(true);
      await fetcher(`/usuario/movimentacao/favorito/${idMovimentacao}`, { method: "delete" });
      await mutate();
      addAlert(`Favorito removido com sucesso!`, "success");
    } catch (error) {
      addAlert(`Falha! Não foi possível remover favorito.`, "error");
    } finally {
      setDisabled(false);
    }
  }, [idMovimentacao, addAlert, mutate]);

  const isFavorito = user?.favoritos?.includes(idMovimentacao);

  return (
    <button
      className={classNames(disabled ? "cursor-wait" : "")}
      onClick={isFavorito ? desfavoritar : favoritar}
      disabled={disabled}
    >
      <StarIcon className={classNames(isFavorito ? "text-yellow-300" : "text-cool-gray-300", "h-6 w-6")} />
    </button>
  );
}
