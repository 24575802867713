import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import TokenManager from "./token-manager";

export default function usePageTracking() {
  const location = useLocation();
  useEffect(() => {
    const info = TokenManager.info();
    if (window.env?.NODE_ENV === "production") {
      const options = info?._id ? { gaOptions: { userId: info?._id } } : undefined;
      ReactGA.initialize("UA-192959046-1", options);
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [location]);
}
