import React from "react";
import { Redirect } from "react-router-dom";
import LoginLayout from "../components/login-layout";
import useQuery from "../hooks/use-query";
import TokenManager from "../libs/token-manager";

export default function Token() {
  const { query } = useQuery();
  const token = query.get("token");

  if (token) {
    TokenManager.set(token);
    return <Redirect to="/pesquisa" />;
  } else {
    return (
      <LoginLayout>
        <h1 className="p-4 mt-4 text-white text-lg text-center">
          Token não informado! <br />
          Por favor, tente copiar e colar o endereço informado via e-mail.
        </h1>
      </LoginLayout>
    );
  }
}
