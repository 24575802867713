import React from "react";
import BaseLayout from "../components/base-layout";
import Form from "../components/form";

export default function PaginaNaoEncontrada() {
  return (
    <BaseLayout>
      <div className="p-4 pb-20 flex flex-col items-center">
        <img
          className="px-8 h-84 w-full max-w-xl lg:block"
          src="/img/undraw_page_not_found_su7k.svg"
          alt="Desenho de mulher em cima de número 404"
        />
        <h1 className="text-3xl font-bold text-gray-700 text-center">Ops... Página não encontrada!</h1>
        <p className="mt-6 text-gray-500 text-center max-w-screen-md">
          Não conseguimos localizar a página desejada. Clique no botão abaixo para recomeçar a navegação.
        </p>
        <Form.Button className="mt-6 bg-primary-500" onClick={() => (window.location.href = "/")}>
          Página inicial
        </Form.Button>
      </div>
    </BaseLayout>
  );
}
