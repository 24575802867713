import React from "react";
import * as Sentry from "@sentry/browser";
import Form from "./form";
import TokenManager from "../libs/token-manager";
import CoreLayout from "./core-layout";
import BotaoAssinatura from "./v2/botao-assinatura";
import formatarPreco from "../libs/formatar-preco";
import { precoPadraoEmCentavos } from "./v2/preco";
import { withRouter } from "react-router-dom";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthError: false,
      isPaymentError: false,
      errorMessage: "",
      errorTitle: "",
      hasError: false,
      error: null,
      eventId: null,
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    if (error?.code === 401 || error?.response?.status === 401) {
      TokenManager.remove();
      this.setState({
        isAuthError: true,
        errorTitle: "Acesso expirado",
        errorMessage: "Seu acesso expirou. Será necessário fazer o login no sistema novamente.",
      });
    } else if (error?.code === 402 || error?.response?.status === 402) {
      this.setState({ isPaymentError: true });
    } else if (error?.code === 403 || error?.response?.status === 403) {
      this.setState({
        isAuthError: true,
        errorTitle: "Acesso proibido",
        errorMessage: "Você não tem acesso a essa ação. Tente logar novamente para renovar as permissões.",
      });
    } else if (error?.code === 412 || error?.response?.status === 412) {
      window.location.href = "/assinatura/plano";
      this.setState({ redirected: true });
    } else {
      Sentry.withScope((scope) => {
        scope.setExtras(errorInfo);
        const info = TokenManager.info();
        if (info) scope.setUser(info);
        const eventId = Sentry.captureException(error);
        this.setState({ eventId });
      });
    }
  }

  render() {
    if (this.state.redirected) return null;

    if (this.state.hasError) {
      if (this.state.isAuthError) {
        return (
          <CoreLayout>
            <div className="mt-6 p-4 flex flex-col items-center">
              <h1 className="text-3xl font-bold text-gray-700 text-center">{this.state.errorTitle}</h1>
              <p className="mt-6 text-gray-500 text-center max-w-screen-md">{this.state.errorMessage}</p>
              <Form.Button className="mt-6 bg-primary-500" onClick={() => (window.location.href = "/login")}>
                Acessar
              </Form.Button>
            </div>
          </CoreLayout>
        );
      } else if (this.state.isPaymentError) {
        return (
          <CoreLayout>
            <div className="mt-6 p-4 flex flex-col items-center">
              <h1 className="text-3xl font-bold text-gray-700 text-center">Assinatura suspensa!</h1>
              <p className="mt-6 text-gray-500 text-center max-w-screen-md">
                Seu plano de assinatura Dominia venceu. Tenha acesso novamente por apenas{" "}
                <strong>{formatarPreco(precoPadraoEmCentavos)} / mês</strong>!
              </p>
              <div className="mt-6 w-full flex justify-center">
                <BotaoAssinatura theme="dark" size="big" />
              </div>
            </div>
          </CoreLayout>
        );
      } else {
        return (
          <CoreLayout>
            <div className="p-4 flex flex-col items-center justify-center h-full">
              <img
                className="px-8 h-84 w-full max-w-xl lg:block"
                src="/img/undraw_server_down_s4lk.svg"
                alt="Desenho de monstrinho cortado cabo entre um computador doméstico e um servidor"
              />
              <h1 className="text-3xl font-bold text-gray-700 text-center">Ops... Encontramos um erro!</h1>
              <p className="mt-6 text-gray-500 text-center max-w-screen-md">
                Não se preocupe. Já fomos informados do erro e estamos trabalhando para resolvê-lo. Atualize o seu
                navegador para tentar novamente ou clique no botão abaixo.
              </p>
              <p className="text-sm font-semibold mt-3">
                {this.state.eventId && <>Código do erro: {this.state.eventId}</>}
              </p>
              <Form.Button className="mt-6 bg-primary-500" onClick={() => (window.location.href = "/")}>
                Página inicial
              </Form.Button>
            </div>
          </CoreLayout>
        );
      }
    } else {
      return this.props.children;
    }
  }
}

export default withRouter(ErrorBoundary);
