import createAutorString from "./create-autor-string";
import createReuString from "./create-reu-string";

export default function generateTema(tema, reus, autores) {
  let busca = [];
  if (reus.length > 0) {
    busca.push(`(${reus.map((reu) => `${createReuString(reu)}`).join(" | ")})`);
  }

  if (autores.length > 0) {
    busca.push(`(${autores.map((autor) => `${createAutorString(autor)}`).join(" | ")})`);
  }

  if (tema) {
    busca.push(`(${tema})`);
  }

  const temaFinal = busca.join(" & ");
  console.log(temaFinal);
  return temaFinal;
}
