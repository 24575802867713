import React from "react";
import useSWR from "swr";
import classNames from "../../libs/classnames";
import fetcher from "../../libs/fetcher";
import { redirecionarGerenciarAssinatura, exibirAlertaAssinaturaManual } from "../../libs/gerenciar-assinatura";

export default function BotaoAssinatura({ theme = "light", size = "normal" }) {
  const { data: status, error: requestError } = useSWR(`/usuario/assinatura/status`, fetcher);
  if (requestError) throw requestError;

  if (status === undefined) return null;
  const className = classNames(
    size === "normal" ? "text-sm" : "text-lg",
    theme === "light" ? "text-primary-600 bg-white hover:bg-indigo-50" : "text-white bg-indigo-600 hover:bg-indigo-700",
    "font-semibold flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm"
  );

  if (status?.tipo === "PAGAMENTO_INDEFINIDO") {
    return (
      <a  className={className} href="/assinatura/plano">
        Assinar o Dominia
      </a>
    );
  } else if (status?.tipo === "ASSINATURA_INDEFINIDA") {
    return (
      <a className={className} href="/assinatura/plano">
        Assinar o Dominia
      </a>
    );
  } else if (status?.tipo === "ASSINATURA_MANUAL") {
    return (
      <button className={className} onClick={exibirAlertaAssinaturaManual}>
        Gerenciar Assinatura
      </button>
    );
  } else {
    return (
      <button className={className} onClick={redirecionarGerenciarAssinatura}>
        Gerenciar Assinatura
      </button>
    );
  }
}
