import React, { useState } from "react";
import BaseLayout from "../components/base-layout";
import AdminMenu from "../components/admin-menu";
import fetcher from "../libs/fetcher";
import tratarErrosApi from "../libs/tratar-erros-api";

export default function AdminTests() {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  async function atualizarCadastrosSendGrid() {
    try {
      setLoading(true);
      await fetcher(`/usuarios/sendgrid`, { method: "post" });
    } catch (error) {
      setError(tratarErrosApi(error));
    } finally {
      setLoading(false);
    }
  }
  if (error) throw error;

  return (
    <BaseLayout>
      <AdminMenu />
      <div className="p-4">
        <button className="disabled:opacity-50" onClick={atualizarCadastrosSendGrid} disabled={loading}>
          {loading ? "Enviando..." : "Enviar cadastros"}
        </button>
        <p className="text-sm text-cool-gray-500">Atualizar cadastros SendGrid</p>
      </div>
    </BaseLayout>
  );
}
