import React, { useState } from "react";
import useSWR from "swr";
import BaseLayout from "../components/base-layout";
import fetcher from "../libs/fetcher";
import * as Table from "../components/table";
import AdminMenu from "../components/admin-menu";
import AdminPodLogModal from "../components/admin-pod-log-modal";
import useQuery from "../hooks/use-query";
import { Roles, temRole } from "../libs/roles";
import tratarErrosApi from "../libs/tratar-erros-api";

export default function AdminPods() {
  const [error, setError] = useState(null);
  const { query, setQuery, pushQuery } = useQuery();
  const { data, error: podRequestError, mutate } = useSWR(`/pods`, fetcher);
  const selectedPod = query.get("pod");

  if (podRequestError) throw error;
  if (error) throw error;

  function setSelectedPod(pod) {
    setQuery("pod", pod);
    pushQuery();
  }

  function deletePod(pod) {
    return async (event) => {
      event.preventDefault();
      event.stopPropagation();
      if (window.confirm(`Realmente apagar ${pod}?`)) {
        try {
          await fetcher(`/pods/${pod}`, { method: "delete" });
          await mutate();
        } catch (error) {
          setError(tratarErrosApi(error));
        }
      }
    };
  }

  return (
    <>
      {selectedPod && <AdminPodLogModal name={selectedPod} onClose={() => setSelectedPod(null)} />}
      <BaseLayout>
        <AdminMenu />

        {data && (
          <Table.Container>
            <thead className="bg-gray-50">
              <tr>
                <Table.HeadRow>Name</Table.HeadRow>
                <Table.HeadRow>Status</Table.HeadRow>
                <Table.HeadRow>Date</Table.HeadRow>
                <Table.HeadRow className="text-center">Ações</Table.HeadRow>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {data.map((item) => (
                <tr
                  className="text-sm text-gray-900 cursor-pointer hover:bg-gray-100"
                  key={item.name}
                  onClick={() => setSelectedPod(item.name)}
                >
                  <Table.Row>{item.name}</Table.Row>
                  <Table.Row>{item.status}</Table.Row>
                  <Table.Row>{item.date}</Table.Row>
                  <Table.Row className="px-6 py-4">
                    <div className="flex justify-center w-full">
                      <Table.Button
                        className="bg-gray-100 text-gray-800 focus:ring-gray-500"
                        onClick={deletePod(item.name)}
                        disabled={!temRole(Roles.ROOT)}
                      >
                        Apagar
                      </Table.Button>
                    </div>
                  </Table.Row>
                </tr>
              ))}
            </tbody>
          </Table.Container>
        )}
        <div className="mb-10" />
      </BaseLayout>
    </>
  );
}
