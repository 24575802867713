import React from "react";
import IndexLayout from "../components/index-layout";
import IndexSection from "../components/index-section";
import PerguntaResposta from "../components/pergunta-resposta";
import formatarTimestamp from "../libs/formatar-timestamp";
import formatarNumero from "../libs/formatar-numero";

export default function PerguntasFrequentes() {
  const statistics = {};
  return (
    <IndexLayout>
        <IndexSection className="px-4 pb-20">
          <h1 className="pt-8 text-4xl text-center text-primary-500 font-extrabold leading-tight">Perguntas frequentes</h1>
          <div className="mt-6 pt-10">
            <dl className="space-y-10">
              <PerguntaResposta pergunta="Como DOMINIA obtêm os dados constantes na plataforma?">
                Todos os dados informados pela nossa plataforma foram extraídos após meticulosa e profunda análise do
                conteúdo disponibilizado no Diário de Justiça Eletrônico (DJe) do TJDFT de 05 janeiro de 2015 até a
                presente data. Desta forma, o parâmetro utilizado para estimar o tempo de duração dos processos de 1ª
                instância, o período médio necessário entre a distribuição da inicial até o julgamento dos pleitos de
                tutela antecipada e/ou liminar, bem como da sentença foram os andamentos e decisões devidamente
                disponibilizadas no DJe do TJDFT.
              </PerguntaResposta>

              <PerguntaResposta pergunta="Quantos processos existem na base de dados?">
                Atualmente existem <strong>{formatarNumero(statistics.quantidadeProcessos)}</strong> processos na nossa
                base de dados.
              </PerguntaResposta>

              <PerguntaResposta pergunta="De quando é o primeiro processo cadastrado no sistema?">
                O processo mais antigo no sistema tem a primeira movimentação em{" "}
                <strong>{formatarTimestamp(statistics.dataPrimeiroProcesso)}</strong>.
              </PerguntaResposta>

              <PerguntaResposta pergunta="De quando é o processo mais recente cadastrado no sistema?">
                O processo mais recente no sistema tem a última movimentação em{" "}
                <strong>{formatarTimestamp(statistics.dataUltimoProcesso)}</strong>.
              </PerguntaResposta>

              <PerguntaResposta pergunta="Como é feito o cálculo de duração do processo, desde sua distribuição até a disponibilização da sentença?">
                O dado é apurado após verificar-se a data da primeira movimentação disponibilizada no DJe do TJDFT após
                a distribuição da inicial e a data da disponibilização da sentença. Vale lembrar que o primeiro dado é
                datado de {formatarTimestamp(statistics.dataPrimeiroProcesso)}.
              </PerguntaResposta>

              <PerguntaResposta pergunta="Como é feito o cálculo de movimentações até julgamento da sentença?">
                O dado é apurado após verificar-se todas as disponibilizações referentes a um determinado processo
                constante no DJe do TJDFT até a disponibilização da sentença. Vale lembrar que o primeiro dado é datado
                de {formatarTimestamp(statistics.dataPrimeiroProcesso)}.
              </PerguntaResposta>

              <PerguntaResposta pergunta="Como é feito o cálculo de tempo até a tutela e/ou liminar?">
                O dado é apurado após verificar-se a data da primeira movimentação disponibilizada no DJe do TJDFT após
                a distribuição da inicial e a data da disponibilização da decisão que apreciou o pleito de
                tutela/liminar. Vale lembrar que o primeiro dado é datado de{" "}
                {formatarTimestamp(statistics.dataPrimeiroProcesso)}.
              </PerguntaResposta>

              <PerguntaResposta pergunta="Como é feito o cálculo de movimentações até a tutela e/ou liminar?">
                O dado é apurado após verificar-se todas as disponibilizações referentes a um determinado processo
                constante no DJe do TJDFT, desde a distribuição da medida judicial até a apreciação da tutela ou
                liminar. Vale lembrar que o primeiro dado é datado de{" "}
                {formatarTimestamp(statistics.dataPrimeiroProcesso)}.
              </PerguntaResposta>
            </dl>

            <div className="mt-16 text-lg font-semibold text-gray-900 leading-6">
              Mais perguntas? Envie um email para <strong>contato@dominia.app</strong>.
            </div>
          </div>
        </IndexSection>
    </IndexLayout>
  );
}
