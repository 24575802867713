import React from "react";

export default function MovimentacaoListItemSkeleton({ esconderAcoes = false }) {
  return (
    <div className="mt-2 w-full border rounded-lg shadow text-gray-700 overflow-hidden animate-pulse">
      <header className="flex flex-col border-b border-gray-200">
        <div className="py-3 px-5">
          <div className="flex justify-between items-bottom">
            <div className="flex flex-col sm:flex-row sm:gap-2 text-base text-gray-500 font-bold ">
              <div className="flex gap-1">
                <div className="h-5 w-5 rounded-full bg-gray-300" />
                <div className="h-5 w-64 bg-gray-300 rounded-lg" />
              </div>
            </div>
            <div className="flex items-center gap-1">
              <div className="mr-1 pl-2 pr-1 py-1 text-xs rounded-full font-bold leading-loose bg-white">
                <div className="text-xs mr-1 text-gray-300 text-right" />
              </div>
              <div className="h-5 w-5 lg:w-24 bg-gray-300 rounded-lg" />
            </div>
          </div>

          <div className="mt-2 flex flex-col lg:flex-row justify-between">
            <div className="flex flex-col lg:flex-row">
              <div className="flex lg:mr-4 text-gray-500 font-semibold text-sm items-begin my-1 lg:mx-1 ">
                <div className="w-4 h-4 mr-1 bg-primary-500 rounded-full" />{" "}
                <span className="p-1 h-4 w-72 bg-gray-300 rounded-lg" />
              </div>
            </div>
            <div className="flex text-gray-500 font-semibold text-sm items-begin my-1 lg:mx-1">
              <div className="w-4 h-4 mr-1 bg-primary-500 rounded-full" />{" "}
              <span className="h-4 w-44 bg-gray-300 rounded-lg"></span>
            </div>
          </div>
        </div>
      </header>
      <main>
        <div className="highlight-content py-4 px-6 text-gray-700 text-sm h-56" />

        {!esconderAcoes && (
          <div className="flex justify-end">
            <div className="block rounded-tl-lg px-4 py-2 border-r border-gray-300 bg-gray-100 text-sm text-center font-semibold hover:bg-gray-200 focus:outline-none focus:bg-gray-200 h-9 w-32" />
          </div>
        )}
      </main>
    </div>
  );
}
