import React from "react";
import { Link, useLocation } from "react-router-dom";
import classNames from "../../libs/classnames";

export default function Tabs({ tabs }) {
  const location = useLocation();
  return (
    <div>
      <div className="block mb-1">
        <nav className="flex space-x-4" aria-label="Tabs">
          {tabs.map((tab) => {
            const active = tab.href.startsWith(location?.pathname);
            return (
              <Link
                key={tab.name}
                to={tab.href}
                className={classNames(
                  active
                    ? "bg-secondary-50 text-secondary-500"
                    : "text-gray-500 hover:text-gray-700 hover:bg-gray-100 border-gray-100 border",
                  "flex px-3 py-2 font-medium rounded-md items-center text-center"
                )}
                aria-current={active ? "page" : undefined}
              >
                <tab.icon
                  className={classNames(
                    active ? "text-secondary-500" : "text-gray-400 group-hover:text-gray-500",
                    "-ml-0.5 mr-2 h-5 w-5 sm:block hidden"
                  )}
                  aria-hidden="true"
                />
                <span>{tab.name}</span>
              </Link>
            );
          })}
        </nav>
      </div>
    </div>
  );
}
