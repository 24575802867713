import React from "react";
import { Route } from "react-router-dom";
import { temRole } from "../libs/roles";

export default function PrivateRoute({ children, roles, ...props }) {
  return (
    <Route
      {...props}
      render={() => {
        if (temRole(...roles)) {
          return children;
        } else {
          window.location.href = "/login";
          return null;
        }
      }}
    />
  );
}
