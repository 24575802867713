import { CheckIcon, SearchIcon } from "@heroicons/react/outline";
import { format, formatRelative } from "date-fns";
import { ptBR } from "date-fns/locale";
import React from "react";
import { Link, useParams } from "react-router-dom";
import useSWR from "swr";
import { sortBy } from "underscore";
import BaseLayout from "../components/base-layout";
import PaginacaoPesquisa from "../components/paginacao-pesquisa";
import useQuery from "../hooks/use-query";
import fetcher from "../libs/fetcher";
import jurisdicao from "../libs/jurisdicao";

function CheckList({ list, title, transformer = (i) => i }) {
  if (list && list.length > 0) {
    return (
      <div className="flex flex-col px-2">
        <h2 className="font-bold text-lg">{title}</h2>
        {list.map((i) => (
          <div className="flex" key={i}>
            <CheckIcon className="w-5 h-5" />
            <h2 className="font-semibold text-base">{transformer(i)}</h2>
          </div>
        ))}
      </div>
    );
  } else {
    return null;
  }
}

function HistoricoItem({ historico }) {
  return (
    <div className="border rounded-lg text-gray-700 flex flex-col gap-2 p-2 items-start">
      <header className="w-full gap-1 flex items-center border-gray-200">
        {historico._source.metadata.tema && (
          <>
            <SearchIcon className="w-5 h-5 text-primary-500" />
            <h1 className="font-bold text-lg">{historico._source.metadata.tema}</h1>
          </>
        )}
        <div title={format(historico.data, "yyyy-MM-dd HH:mm:ss")} className="font-semibold flex-grow text-right">
          {formatRelative(historico.data, new Date(), { locale: ptBR })}
        </div>
      </header>
      <div className="grid sm:grid-cols-2 lg:grid-cols-4 grid-cols-1">
        <CheckList list={historico._source.metadata.favoritos ? ["Favoritos"] : []} title="" />
        <CheckList
          list={historico._source.metadata.grauJurisdicao}
          title="Jurisdição"
          transformer={(i) => jurisdicao[i].description}
        />
        <CheckList list={historico._source.metadata.data} title="Data" />
        <CheckList list={historico._source.metadata.tipoDecisao} title="Tipo de Decisão" />
        <CheckList list={historico._source.metadata.decisao} title="Decisão" />
        <CheckList list={historico._source.metadata.magistrado} title="Magistrados" />
        <CheckList list={historico._source.metadata.vara} title="Vara" />
        <CheckList list={historico._source.metadata.orgaoJulgador} title="Órgão Julgador" />
      </div>
      <Link
        className="p-2 border bg-gray-50 hover:bg-gray-100 rounded-lg"
        to={`pesquisa${historico._source.metadata.search}`}
      >
        Refazer pesquisa
      </Link>
    </div>
  );
}

export default function AdminHistorico() {
  const { query } = useQuery();
  const { usuario } = useParams();
  const size = 20;
  const pagina = query.get("pagina") !== null ? parseInt(query.get("pagina")) : 1;
  const params = new URLSearchParams();
  params.set("size", size);
  params.set("from", (pagina - 1) * size);
  params.set("usuario", usuario);
  const { data, error } = useSWR(`/usuario/historico?${params.toString()}`, fetcher);
  if (error) throw error;

  const historico = sortBy(
    data?.data?.map((d) => ({
      ...d,
      data: new Date(d._source.date),
    })),
    "data"
  ).reverse();

  return (
    <BaseLayout>
      <main className="my-6 flex flex-col flex-grow w-full items-center">
        <div className="flex flex-col gap-2 px-2 w-full">
          {historico?.map((h) => (
            <HistoricoItem key={h._id} historico={h} />
          ))}
        </div>
        {data?.count > 0 && (
          <div className="mt-2 py-2 px-5 flex items-center justify-between border-t border-gray-200 text-sm text-gray-700 m-auto w-full">
            <PaginacaoPesquisa count={data.count} pagina={pagina} size={size} />
          </div>
        )}

        {data && data.count === 0 && <p>Nenhum registro encontrado.</p>}
      </main>
    </BaseLayout>
  );
}
