import "intersection-observer";
import "regenerator-runtime/runtime.js";
import "./styles/global.css";

import * as Sentry from "@sentry/browser";
import React from "react";
import ReactDom from "react-dom";
import App from "./app";

if (window.env?.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://af6f260b6d2d478f9ad3430c0f67102f@sentry.io/5172534",
  });
}

const root = document.getElementById("root");
if (window.env?.BACKEND_URL) {
  ReactDom.render(<App />, root);
} else {
  root.innerHTML = `<h1>Ops... Erro catastrófico!</h1><p>Não foi possível localizar <code>window.env.BACKEND_URL</code></p>`;
}
