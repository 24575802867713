import { ChevronDownIcon, ChevronUpIcon, SearchIcon } from "@heroicons/react/outline";
import React, { useState } from "react";
import useQuery from "../hooks/use-query";
import normalizarString from "../libs/normalizar-string";
import classNames from "../libs/classnames";

export default function SideFilter({ label, options, id, selected, showSearch = false }) {
  const { setQuery, pushQuery } = useQuery();
  const [opened, setOpened] = useState(!showSearch || selected.length > 0);
  const [filter, setFilter] = useState("");

  function toggleOpened() {
    setOpened((state) => !state);
  }

  let filteredOptions = options.filter(
    (option) => normalizarString(option.description).indexOf(normalizarString(filter)) !== -1
  );

  if (showSearch) {
    filteredOptions = filteredOptions.filter((option) => !selected.includes(option.id));
  }

  const selectedOptions = options.filter((option) => selected.includes(option.id));

  function toggleSelection(item) {
    return function (event) {
      if (event.target.checked) {
        setQuery(id, [...selected, item]);
      } else {
        setQuery(
          id,
          selected.filter((i) => item !== i)
        );
      }
      setQuery("pagina", 1);
      pushQuery();
    };
  }

  function OptionItem({ option }) {
    return (
      <li key={option.id} className="py-1 px-2 flex items-center w-full">
        <input
          className="mr-2 form-checkbox h-5 w-5 border-2 border-cool-gray-700 text-secondary-700 focus:ring-blue-700 rounded disabled:border-cool-gray-400"
          type="checkbox"
          id={`tipos-decisao-${option.id}`}
          onChange={toggleSelection(option.id)}
          checked={selected.includes(option.id)}
          disabled={option.disabled}
        />
        <div className="flex font-semibold justify-between items-center text-secondary-700">
          <label htmlFor={`tipos-decisao-${option.id}`}>
            {option.description}{" "}
            {/* <span className="text-secondary-400 font-normal">({formatarNumero(option.count)})</span> */}
          </label>
        </div>
      </li>
    );
  }

  return (
    <div className="border-b border-gray-200">
      <button
        className="p-2 flex items-center justify-between w-full text-cool-gray-600 focus:outline-none"
        onClick={toggleOpened}
      >
        <span className="font-semibold text-sm uppercase">{label}</span>
        {opened ? <ChevronUpIcon className="w-5 h-5" /> : <ChevronDownIcon className="w-5 h-5" />}
      </button>
      {opened && (
        <div className="pb-2">
          {showSearch && (
            <div className="px-2 pb-2">
              <div className="p-1 flex border-b items-center w-full">
                <SearchIcon className="h-4 w-4" />
                <input
                  className="ml-1 text-xs focus:outline-none w-full"
                  type="text"
                  value={filter}
                  onChange={(event) => setFilter(event.target.value)}
                  autoFocus={opened}
                />
              </div>
            </div>
          )}
          {showSearch && selectedOptions && (
            <ol className="flex flex-col w-full text-left text-sm">
              {selectedOptions.map((option) => (
                <OptionItem key={option.id} option={option} />
              ))}
            </ol>
          )}
          <ol
            className={classNames(showSearch && "max-h-36", "flex flex-col w-full text-left text-sm overflow-y-auto")}
          >
            {filteredOptions.map((option) => (
              <OptionItem key={option.id} option={option} />
            ))}
          </ol>
        </div>
      )}
    </div>
  );
}
