import React from "react";
import * as enums from "../enums";

export default function DecisaoDot({ decisao, className }) {
  let css;
  switch (decisao) {
    case enums.DECISAO_PROCEDENTE:
    case enums.DECISAO_DEFERIDO:
    case enums.DECISAO_PROVIDO:
      css = "bg-green-200";
      break;
    case enums.DECISAO_PARCIALMENTE_PROCEDENTE:
    case enums.DECISAO_PARCIALMENTE_DEFERIDO:
    case enums.DECISAO_PARCIALMENTE_PROVIDO:
      css = "bg-yellow-100";
      break;
    case enums.DECISAO_IMPROCEDENTE:
    case enums.DECISAO_INDEFERIDO:
    case enums.DECISAO_IMPROVIDO:
      css = "bg-red-200";
      break;
    case enums.DECISAO_ACORDO:
      css = "bg-blue-200";
      break;
    default:
      css = "border-2";
  }

  return <div className={`rounded-full h-4 w-4 ${css} ${className}`} />;
}
