const jurisdicao = {
  "1grau": {
    id: "1grau",
    description: "TJDFT 1º grau",
    orgao: "TJDFT",
  },
  "2grau": {
    id: "2grau",
    description: "TJDFT 2º grau",
    orgao: "TJDFT",
  },
  trf1_acordaos: {
    id: "trf1_acordaos",
    description: "TRF 1ª Região 2º grau",
  },
  trf2_2grau_acordaos: {
    id: "trf2_2grau_acordaos",
    description: "TRF 2ª Região 2º grau",
  },
  trf3_2grau_acordaos: {
    id: "trf3_2grau_acordaos",
    description: "TRF 3ª Região 2º grau",
  },
  stj: {
    id: "stj",
    description: "STJ",
    orgao: "STJ",
  },
  stf: {
    id: "stf",
    description: "STF",
  },
  trt10_1grau: {
    id: "trt10_1grau",
    description: "TRT 10ª Região 1º grau",
  },
  trt10_2grau_acordaos: {
    id: "trt10_2grau_acordaos",
    description: "TRT 10ª Região 2º grau",
    disabled: false,
    invisible: false,
  },
  classificacoes_trt10_1grau_tutela: {
    id: "classificacoes_trt10_1grau_tutela",
    description: "Classificações TRT 10ª Região 1º grau - TUTELA",
    disabled: true,
    invisible: true,
  },
  classificacoes_trt10_1grau_merito: {
    id: "classificacoes_trt10_1grau_merito",
    description: "Classificações TRT 10ª Região 1º grau - MERITO",
    disabled: true,
    invisible: true,
  },
  classificacoes_trt10_1grau_procedente: {
    id: "classificacoes_trt10_1grau_procedente",
    description: "Classificações TRT 10ª Região 1º grau - PROCEDENTE",
    disabled: true,
    invisible: true,
  },
  tst_acordaos: {
    id: "tst_acordaos",
    description: "TST",
    disabled: false,
    invisible: false,
  },
  tcu_acordaos: {
    id: "tcu_acordaos",
    description: "TCU",
    disabled: false,
    invisible: false,
  },
};

export default jurisdicao;
