import React from "react";
import { Link } from "react-router-dom";
import Logo from "./logo";
import SizeDebug from "./size-debug";

export default function LoginLayout({ children }) {
  return (
    <div className="antialiased flex justify-center min-h-screen bg-secondary-700 dominia-background">
      <SizeDebug />
      <div className="m-auto w-full pt-6 pb-16 flex flex-col items-center">
        <Link className="h-16 w-16 text-primary-500" to="/">
          <Logo />
        </Link>
        {children}
        <p className="text-center text-gray-200 text-xs mt-4">© {new Date().getFullYear()} Dominia</p>
      </div>
    </div>
  );
}
