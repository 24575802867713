import React, { useEffect } from "react";
import { XIcon } from "@heroicons/react/outline";
import classNames from "../libs/classnames";

export default function ModalContainer({ children, onClose, fullscreen = false }) {
  useEffect(() => {
    function handleKeyEvent(event) {
      if (event.key === "Esc" || event.key === "Escape") {
        onClose();
      }
    }

    document.addEventListener("keydown", handleKeyEvent);
    return () => {
      document.removeEventListener("keydown", handleKeyEvent);
    };
  });

  useEffect(() => {
    const defaultOverflowY = document.body.style.overflowY;
    document.body.style.overflowY = "hidden";
    return () => (document.body.style.overflowY = defaultOverflowY);
  });

  return (
    <div
      className={classNames(
        fullscreen ? "" : "bg-opacity-90",
        "fixed px-2 w-full h-screen antialiased bg-gray-800 z-30 overflow-y-scroll"
      )}
      onClick={(event) => {
        if (event.currentTarget === event.target) onClose();
      }}
    >
      {fullscreen ? (
        children
      ) : (
        <div className="my-4 max-w-screen-xl m-auto border rounded-lg bg-white shadow-lg">
          <button
            className="text-white hover:text-gray-200 focus:text-gray-200 focus:outline-none hidden 2xl:block fixed right-10 top-5"
            onClick={onClose}
          >
            <XIcon className="h-6 w-6 fill-current" />
          </button>
          {children}
        </div>
      )}
    </div>
  );
}
