import axios from "axios";
import TokenManager from "./token-manager";

export const baseURL = window.env.BACKEND_URL || "http://localhost:8080";

export default async function fetcher(url, config) {
  let finalConfig;
  if (TokenManager.get()) {
    finalConfig = {
      ...config,
      headers: {
        ...config?.headers,
        Authorization: `Bearer ${TokenManager.get()}`,
      },
    };
  } else {
    finalConfig = config;
  }

  try {
    const res = await axios
      .create({ baseURL })
      .request({ url, ...finalConfig });
    return res.data;
  } catch (error) {
    if (!error.message.includes("Request aborted"))
      throw error;
  }
}
