import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import formatarNumero from "../../libs/formatar-numero";
import pluralizar from "../../libs/pluralizar";

export default function TempoMedioRanking({ titulo, estatisticas, generateTo, grauJurisdicao }) {
  return (
    <div className="p-4 border-2 border-cool-gray-300 rounded-lg flex flex-col">
      <h1 className="font-bold text-cool-gray-700 text-sm">{titulo}</h1>
      <h2 className="font-bold text-cool-gray-700 text-lg inline">
        {formatarNumero(estatisticas.dias)}{" "}
        <small className="text-cool-gray-500">{pluralizar(estatisticas.dias, "dia", "dias")}</small>
      </h2>

      <hr className="mt-2" />
      <h3 className="font-bold text-cool-gray-900 text-sm uppercase mt-2">Varas mais rápidas</h3>
      <div className="grid grid-cols-4 text-sm">
        {estatisticas.bot_varas_dias
          ?.sort((a, b) => a.qtd_dias - b.qtd_dias)
          .map((i) => (
            <Fragment key={i.vara}>
              <Link
                className="truncate col-span-3 text-cool-gray-700 font-semibold"
                to={generateTo({ grauJurisdicao, vara: i.vara })}
              >
                <abbr style={{ borderBottom: "none" }} title={i.vara}>
                  {i.vara}
                </abbr>
              </Link>
              <div className="text-right text-cool-gray-500 font-semibold">
                {formatarNumero(Math.ceil(i.qtd_dias))} {pluralizar(i.qtd_dias, "dia", "dias")}
              </div>
            </Fragment>
          ))}
      </div>

      <h3 className="font-bold text-cool-gray-900 text-sm uppercase mt-2">Varas mais lentas</h3>
      <div className="grid grid-cols-3 text-sm">
        {estatisticas.top_varas_dias
          .sort((a, b) => b.qtd_dias - a.qtd_dias)
          .map((i) => (
            <Fragment key={i.vara}>
              <Link
                className="truncate col-span-2 text-cool-gray-700 font-semibold"
                to={generateTo({ grauJurisdicao, vara: i.vara })}
              >
                <abbr style={{ borderBottom: "none" }} title={i.vara}>
                  {i.vara}
                </abbr>
              </Link>
              <div className="text-right text-cool-gray-500 font-semibold">
                {formatarNumero(Math.ceil(i.qtd_dias))} {pluralizar(i.qtd_dias, "dia", "dias")}
              </div>
            </Fragment>
          ))}
      </div>
    </div>
  );
}
