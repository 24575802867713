import React, { useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import Form from "../components/form";
import LoginLayout from "../components/login-layout";
import useQuery from "../hooks/use-query";
import fetcher from "../libs/fetcher";
import primeiraPagina from "../libs/primeira-pagina";
import TokenManager from "../libs/token-manager";
import tratarErrosApi from "../libs/tratar-erros-api";

export default function Login() {
  const history = useHistory();
  const { query } = useQuery();
  const token = query.get("token");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  if (token) {
    TokenManager.set(token);
    return <Redirect to="/pesquisa" />;
  }

  if (TokenManager.get()) {
    return <Redirect to="/pesquisa" />;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setError(null);

    const form = event.target;
    const email = form.elements.namedItem("email").value;
    const senha = form.elements.namedItem("senha").value;

    try {
      setLoading(true);
      const token = await fetcher("/usuario/login", { method: "post", data: { email, senha } });
      setLoading(false);
      TokenManager.set(token);
      history.push(primeiraPagina());
    } catch (error) {
      setError(tratarErrosApi(error));
      setLoading(false);
    }
  }

  if (error?.__desconhecido) {
    throw error.__desconhecido;
  }

  return (
    <LoginLayout>
      <form
        className="flex flex-col mt-8 px-8 py-8 bg-white shadow-md sm:rounded-lg sm:max-w-sm w-full"
        onSubmit={handleSubmit}
      >
        <Form.Label htmlFor="email">E-mail</Form.Label>
        <Form.Input
          className="mt-2"
          type="email"
          id="email"
          name="email"
          placeholder="E-mail"
          required
          autoComplete="username"
          errored={error?.email}
        />

        <Form.Label className="mt-4" htmlFor="password">
          Senha
        </Form.Label>
        <Form.Input
          className="mt-2"
          name="senha"
          type="password"
          id="password"
          placeholder="********"
          required
          autoComplete="current-password"
          errored={error?.senha}
        />

        <div className="flex items-center justify-between mt-4">
          <Form.Button className="bg-primary-500 hover:bg-primary-600" type="submit" disabled={loading}>
            Entrar
          </Form.Button>
          <Link
            className="inline-block align-baseline font-bold text-sm text-gray-500 hover:text-black"
            to="/esqueci-minha-senha"
          >
            Esqueceu sua senha?
          </Link>
        </div>
        <a
          className="mt-8 p-2 rounded-lg inline-block text-center align-baseline text-lg font-bold bg-secondary-400 text-white hover:bg-secondary-700"
          href="https://dominia.app/jurimetria/#cadastro-form"
        >
          Cadastre-se para testar gratuitamente
        </a>
      </form>
    </LoginLayout>
  );
}
