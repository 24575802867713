import React from "react";
import { Link } from "react-router-dom";
import classNames from "../../libs/classnames";

function Div(props) {
  return <div {...props} />;
}

export default function Card({ label, children, className, ...props }) {
  const Component = props.to ? Link : Div;

  return (
    <Component
      className={classNames("p-4 border-2 border-cool-gray-300 rounded-lg flex flex-col", className)}
      to="/"
      {...props}
    >
      <h1 className="font-bold text-cool-gray-900 text-xl">{children}</h1>
      <h2 className="font-semibold text-cool-gray-600 text-xs">{label}</h2>
    </Component>
  );
}
