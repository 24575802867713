import React, { useContext, useEffect } from "react";
import { InformationCircleIcon, CheckCircleIcon, XCircleIcon, XIcon } from "@heroicons/react/outline";
import AlertsContext from "../contexts/alerts-context";
import classNames from "../libs/classnames";

export default function Alerts({ className }) {
  const { alerts, delAlert } = useContext(AlertsContext);
  return (
    <div className="relative z-50 -mt-3">
      <div className="absolute w-full flex justify-center md:justify-end">
        <div className={classNames(className, "flex flex-col items-end fixed")}>
          {alerts.map((alert) => (
            <Alert key={alert.id} message={alert.message} type={alert.type} onClose={() => delAlert(alert.id)} />
          ))}
        </div>
      </div>
    </div>
  );
}

function Alert({ message, type, onClose }) {
  useEffect(() => {
    const timer = setTimeout(onClose, 4000);
    return () => clearInterval(timer);
  }, [onClose]);

  let bg50;
  let border500;
  let text500;
  let text800;
  let buttonClass;
  let Icon;

  switch (type) {
    case "success":
      bg50 = "bg-green-50";
      border500 = "border-green-500";
      text500 = "text-green-500";
      text800 = "text-green-800";
      buttonClass = "bg-green-50 text-green-500 hover:bg-green-100 focus:ring-offset-green-50 focus:ring-green-600";
      Icon = CheckCircleIcon;
      break;
    case "error":
      bg50 = "bg-red-50";
      border500 = "border-red-500";
      text500 = "text-red-500";
      text800 = "text-red-800";
      buttonClass = "bg-red-50 text-red-500 hover:bg-red-100 focus:ring-offset-red-50 focus:ring-red-600";
      Icon = XCircleIcon;
      break;
    default:
      bg50 = "bg-blue-50";
      border500 = "border-blue-500";
      text500 = "text-blue-500";
      text800 = "text-blue-800";
      buttonClass = "bg-blue-50 text-blue-500 hover:bg-blue-100 focus:ring-offset-blue-50 focus:ring-blue-600";
      Icon = InformationCircleIcon;
      break;
  }

  return (
    <div className={classNames("rounded-md p-4 mb-4 border-2", border500, bg50)}>
      <div className="flex items-center">
        <div className="flex-shrink-0">
          <Icon className={classNames("w-5 h-5", text500)} />
        </div>
        <div className="ml-3">
          <p className={classNames("font-semibold", text800)}>{message}</p>
        </div>
        <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <button
              className={classNames(
                "inline-flex rounded-md p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2",
                buttonClass
              )}
              onClick={onClose}
            >
              <span className="sr-only">Dismiss</span>
              <XIcon className="w-5 h-5" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
