import React, { useState } from "react";
import useSWR from "swr";
import BaseLayout from "../components/base-layout";
import fetcher from "../libs/fetcher";
import AdminMenu from "../components/admin-menu";
import AdminUsuarioItem from "../components/admin-usuario-item";
import normalizarString from "../libs/normalizar-string";
import classNames from "../libs/classnames";

export default function AdminUsuarios() {
  const { data: usuarios, error: requestError, mutate } = useSWR(`/usuarios`, fetcher);
  const [error] = useState();
  const [filtro, setFiltro] = useState("");
  const [filtroStatus, setFiltroStatus] = useState(null);

  function filterBy(status) {
    return function handler() {
      setFiltroStatus((fs) => (fs === status ? null : status));
    };
  }

  if (requestError) throw requestError;
  if (error) throw error;

  function definirFiltro(event) {
    setFiltro(event.target.value);
  }

  let filtered = usuarios
    ? usuarios
        .filter(
          (u) =>
            normalizarString(u.nome).indexOf(normalizarString(filtro)) !== -1 ||
            normalizarString(u.email).indexOf(normalizarString(filtro)) !== -1 ||
            normalizarString(u.role).indexOf(normalizarString(filtro)) !== -1 ||
            normalizarString(u.celular).indexOf(normalizarString(filtro)) !== -1
        )
        .filter(
          (u) =>
            !filtroStatus ||
            (u.plano_manual && filtroStatus === "manual") ||
            (!u.stripe_status && filtroStatus === "nao_assinante") ||
            (u.stripe_status && u.stripe_status === filtroStatus)
        )
    : [];

  return (
    <BaseLayout>
      <AdminMenu />
      <div className="px-3">
        <input
          className="mt-2 p-2 border rounded-lg w-full"
          type="text"
          placeholder="Filtrar usuários"
          onChange={definirFiltro}
        />
        <div className="flex p-3 flex-wrap">
          {[
            { id: "incomplete", nome: "Assinatura incompleta" },
            { id: "incomplete_expired", nome: "Assinatura expirada" },
            { id: "trialing", nome: "Período de teste" },
            { id: "active", nome: "Ativo" },
            { id: "past_due", nome: "Assinatura vencida" },
            { id: "canceled", nome: "Assinatura cancelada" },
            { id: "unpaid", nome: "Devendo" },
            { id: "nao_assinante", nome: "Não assinante" },
            { id: "manual", nome: "Manual" },
          ].map((s) => (
            <button
              key={s.id}
              className={classNames(
                filtroStatus === s.id && "underline",
                "px-2 py-1 text-gray-600 bg-gray-50 rounded border hover:bg-gray-100 uppercase font-bold focus:outline-none ml-2 my-1"
              )}
              onClick={filterBy(s.id)}
            >
              {s.nome}
            </button>
          ))}
        </div>
        {usuarios &&
          filtered.map((usuario, index) => (
            <AdminUsuarioItem key={usuario._id} usuario={usuario} index={index} mutate={mutate} />
          ))}
      </div>
    </BaseLayout>
  );
}
