import * as enums from "../enums";
import jurisdicao from "./jurisdicao";

export default function filtroTipoDecisao(grauJurisdicao) {
  let allowed = [
    enums.TIPO_DECISAO_ACORDAO,
    enums.TIPO_DECISAO_MERITO,
    enums.TIPO_DECISAO_TUTELA,
    enums.TIPO_DECISAO_OUTROS,
  ];

  if (grauJurisdicao && grauJurisdicao.length > 0) {
    if (!grauJurisdicao.includes(jurisdicao["1grau"].id) && !grauJurisdicao.includes(jurisdicao.trt10_1grau.id)) {
      allowed = allowed.filter(
        (i) => i !== enums.TIPO_DECISAO_MERITO && i !== enums.TIPO_DECISAO_TUTELA && i !== enums.TIPO_DECISAO_OUTROS
      );
    }

    if (
      !grauJurisdicao.includes(jurisdicao["2grau"].id) &&
      !grauJurisdicao.includes(jurisdicao.stj.id) &&
      !grauJurisdicao.includes(jurisdicao.stf.id) &&
      !grauJurisdicao.includes(jurisdicao.trf1_acordaos.id) &&
      !grauJurisdicao.includes(jurisdicao.trf2_2grau_acordaos.id) &&
      !grauJurisdicao.includes(jurisdicao.trf3_2grau_acordaos.id) &&
      !grauJurisdicao.includes(jurisdicao.tst_acordaos.id) &&
      !grauJurisdicao.includes(jurisdicao.tcu_acordaos.id) &&
      !grauJurisdicao.includes(jurisdicao.trt10_2grau_acordaos.id)
    ) {
      allowed = allowed.filter((i) => i !== enums.TIPO_DECISAO_ACORDAO);
    }
  }

  return function (tipoDecisao) {
    return allowed.includes(tipoDecisao.id);
  };
}
