import capitalize from "./capitalize";

export default function encurtarNome(nomeCompleto) {
  if (!nomeCompleto) return "";
  const [primeiroNome, ...nomes] = nomeCompleto.split(" ");
  if (nomes.length > 0) {
    return `${capitalize(primeiroNome)} ${capitalize(nomes[nomes.length - 1])}`;
  } else {
    return capitalize(primeiroNome);
  }
}
