export default function joinComVirgula(strs, conector) {
  return strs.reduce((prev, curr, i, arr) => {
    if (i === 0) {
      return curr;
    } else if (i === arr.length - 1) {
      return `${prev} ${conector} ${curr}`;
    } else {
      return `${prev}, ${curr}`;
    }
  });
}
