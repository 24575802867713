import { format } from "date-fns";

export default function formatarTimestamp(timestamp, dateFormat = "dd/MM/yyyy") {
  if (timestamp) {
    const offset = new Date(timestamp).getTimezoneOffset() * 60 * 1000;
    const data = new Date(timestamp + offset);
    return format(data, dateFormat);
  } else {
    return timestamp;
  }
}
