import fetcher from "./fetcher";

export async function redirecionarGerenciarAssinatura(event) {
  event.preventDefault();
  const session = await fetcher(`/usuario/portal-gestao-assinatura`);
  window.location.href = session.url;
}

export async function redirecionarCheckoutAssinatura(event, tipo_plano) {
  event.preventDefault();
  const session = await fetcher(`/usuario/sessao-checkout-assinatura/${tipo_plano}`);
  window.location.href = session.url;
}

export function exibirAlertaAssinaturaManual(event) {
  event.preventDefault();
  window.alert("Assinatura controlada manualmente! Gerencie via contato em contato@dominia.app");
}
