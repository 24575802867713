import React, { useState } from "react";
import useSWR from "swr";
import fetcher from "../libs/fetcher";
import useQuery from "../hooks/use-query";
import BaseLayout from "../components/base-layout";
import SearchInput from "../components/search-input";
import PaginacaoPesquisa from "../components/paginacao-pesquisa";
import useStickyResult from "../hooks/use-sticky-result";
import MovimentacaoListItem from "../components/movimentacao-list-item";
import MovimentacoesProcesso from "../components/movimentacoes-processo";
import Menu from "../components/menu";
import classNames from "../libs/classnames";
import SideFilters from "../components/side-filters";
import SideMobileMenu from "../components/side-mobile-menu";
import MovimentacaoListItemSkeleton from "../components/movimentacao-list-item-skeleton";
import Tutorial from "../components/tutorial";
import Relatorio from "../components/estatisticas/relatorio";
import pluralizar from "../libs/pluralizar";
import formatarNumero from "../libs/formatar-numero";
import TutorialOperadores from "../components/tutorial-operadores";
import swrNoRevalidateOptions from "../libs/swr-no-revalidate-options";
import generateTema from "../libs/generate-tema";

// TODO: https://www.elastic.co/guide/en/elasticsearch/reference/master/mixing-exact-search-with-stemming.html
// TODO: melhorar impressão de relatório
// TODO: onBlur de pesquisa
// TODO: adicionar sample de relatórios
// TODO: atualizar valores do FAQ
// TODO: deve atualizar filtros conforme cada outro filtro selecionado
// TODO: tratar caso de filtro selecionado não existir nos filtros de opções
// TODO: melhorar exibição de processo em dispositivo mobile
// TODO: melhorar impressão de detalhamento do processo
// TODO: varas e juizes devem ficar ordenados por quantidade de vezes que já foram filtrados
// TODO: agrupamento de varas. Exemplo (Fazenda pública)
export default function Pesquisa() {
  const size = 20;
  const { query, setQuery, pushQuery } = useQuery();
  const [filterOpened, setFilterOpened] = useState(false);
  const pagina = query.get("pagina") !== null ? parseInt(query.get("pagina")) : 1;
  const tema = query.get("tema");
  const reus = query.getAll("reu");
  const autores = query.getAll("autor");
  const selectedSimilares = query.getAll("similar");
  const selectedTiposDecisao = query.getAll("tipoDecisao");
  const selectedDecisoes = query.getAll("decisao");
  const selectedMagistrados = query.getAll("magistrado");
  const selectedVaras = query.getAll("vara");
  const selectedDatas = query.getAll("data");
  const selectedGrauJurisdicao = query.getAll("grauJurisdicao");
  const selectedOrgaosJulgadores = query.getAll("orgaoJulgador");
  const favoritos = query.get("favoritos");

  const params = new URLSearchParams();

  if (tema || reus.length > 0 || autores.length > 0) {
    params.set("tema", generateTema(tema, reus, autores));
  }

  if (favoritos) {
    params.append("favoritos", true);
  }

  for (let tipoDecisao of selectedTiposDecisao) {
    params.append("tipoDecisao", tipoDecisao);
  }
  for (let decisao of selectedDecisoes) {
    params.append("decisao", decisao);
  }
  for (let magistrado of selectedMagistrados) {
    params.append("magistrado", magistrado);
  }
  for (let vara of selectedVaras) {
    params.append("vara", vara);
  }
  for (let data of selectedDatas) {
    params.append("data", data);
  }
  for (let grauJurisdicao of selectedGrauJurisdicao) {
    params.append("grauJurisdicao", grauJurisdicao);
  }
  for (let orgaoJulgador of selectedOrgaosJulgadores) {
    params.append("orgaoJulgador", orgaoJulgador);
  }
  for (let similar of selectedSimilares) {
    params.append("similar", similar);
  }

  const algumFiltroPreenchido = Array.from(params).length > 0;

  const { data: filtros, error: errorFiltros } = useSWR(
    `/movimentacoes/filtros?${params.toString()}`,
    fetcher,
    swrNoRevalidateOptions
  );
  if (errorFiltros) throw errorFiltros;
  const stickyFiltros = useStickyResult(filtros);

  let { data: count, error: errorCount } = useSWR(
    algumFiltroPreenchido ? `/movimentacoes/count?${params.toString()}` : null,
    fetcher,
    swrNoRevalidateOptions
  );
  if (errorCount) throw errorCount;

  params.set("size", size);
  params.set("from", (pagina - 1) * size);
  let { data: movimentacoes, error: errorMovimentacoes } = useSWR(
    algumFiltroPreenchido ? `/movimentacoes?${params.toString()}` : null,
    fetcher,
    swrNoRevalidateOptions
  );
  if (errorMovimentacoes) throw errorMovimentacoes;

  const id = query.get("id");
  function setId(id) {
    setQuery("id", id);
    if (!id) setQuery("id", null);
    pushQuery();
  }

  const tutorial = query.get("tutorial");
  function setTutorial(tutorial) {
    setQuery("tutorial", tutorial);
    if (!tutorial) setQuery("tutorial", null);
    pushQuery();
  }

  const tutorialOperadores = query.get("tutorialOperadores");
  function setTutorialOperadores(tutorialOperadores) {
    setQuery("tutorialOperadores", tutorialOperadores);
    if (!tutorialOperadores) setQuery("tutorialOperadores", null);
    pushQuery();
  }

  return (
    <>
      {tutorial && <Tutorial onClose={() => setTutorial(null)} />}
      {tutorialOperadores && <TutorialOperadores onClose={() => setTutorialOperadores(null)} />}
      {id && <MovimentacoesProcesso id={id} tema={tema} onClose={() => setId(null)} />}
      <BaseLayout>
        <div className="flex flex-grow">
          {algumFiltroPreenchido && <SideFilters filtros={stickyFiltros} className={classNames("hidden sm:block")} />}
          {algumFiltroPreenchido && (
            <SideMobileMenu open={filterOpened} onClose={() => setFilterOpened(false)}>
              <SideFilters filtros={stickyFiltros} className={classNames("block sm:hidden")} />
            </SideMobileMenu>
          )}
          <main className="mt-3 flex flex-col flex-grow w-full items-center">
            <div className="px-2 mt-3 flex flex-col flex-grow w-full items-center">
              <div className="flex flex-col mt-2 pb-2 bg-white max-w-screen-md w-full">
                <SearchInput defaultValue={tema} searchButton={!tema} showHint={!tema} />
              </div>
              {!algumFiltroPreenchido && <Relatorio />}
              <div className="flex flex-col">
                {count?.count === 0 && (
                  <h1 className="p-4 text-gray-700 font-semibold text-sm">
                    Nenhum dado encontrado para os filtros informados.
                  </h1>
                )}
              </div>
              {algumFiltroPreenchido && (
                <>
                  <Menu tema={tema} count={count} onFiltrosClick={() => setFilterOpened(true)} />
                  <div className="w-full px-2 text-left text-cool-gray-700 text-sm">
                    {count?.count ? (
                      <div>
                        Exibindo <strong>{formatarNumero((pagina - 1) * size + 1)}</strong> até{" "}
                        <strong>{formatarNumero(pagina * size < count.count ? pagina * size : count.count)}</strong> de{" "}
                        <strong>{formatarNumero(count.count)}</strong> {pluralizar(count, "resultado", "resultados")}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  {movimentacoes &&
                    movimentacoes?.length > 0 &&
                    movimentacoes.map((movimentacao) => (
                      <MovimentacaoListItem key={movimentacao._id} tema={tema} {...movimentacao} />
                    ))}
                  {!movimentacoes && [...Array(10).keys()].map((m) => <MovimentacaoListItemSkeleton key={m} />)}
                  {count?.count > 0 && (
                    <div className="mt-2 py-2 px-5 flex items-center justify-between border-t border-gray-200 text-sm text-gray-700 m-auto max-w-screen-xl w-full ">
                      <PaginacaoPesquisa count={count.count} pagina={pagina} size={size} />
                    </div>
                  )}
                </>
              )}
            </div>
          </main>
        </div>
      </BaseLayout>
    </>
  );
}
