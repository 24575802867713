import * as enums from "../enums";
import jurisdicao from "./jurisdicao";

export default function filtroDecisao(grauJurisdicao, tiposDecisao) {
  let allowed = [
    enums.DECISAO_PROVIDO,
    enums.DECISAO_PARCIALMENTE_PROVIDO,
    enums.DECISAO_IMPROVIDO,
    enums.DECISAO_PROCEDENTE,
    enums.DECISAO_PARCIALMENTE_PROCEDENTE,
    enums.DECISAO_IMPROCEDENTE,
    enums.DECISAO_ACORDO,
    enums.DECISAO_DEFERIDO,
    enums.DECISAO_PARCIALMENTE_DEFERIDO,
    enums.DECISAO_INDEFERIDO,
    enums.DECISAO_OUTROS,
  ];

  if (tiposDecisao && tiposDecisao.length > 0) {
    if (!tiposDecisao.includes(enums.TIPO_DECISAO_ACORDAO)) {
      allowed = allowed.filter(
        (i) =>
          i !== enums.DECISAO_PROVIDO &&
          i !== enums.DECISAO_PARCIALMENTE_PROVIDO &&
          i !== enums.DECISAO_IMPROVIDO &&
          i !== enums.DECISAO_OUTROS
      );
    }

    if (!tiposDecisao.includes(enums.TIPO_DECISAO_MERITO)) {
      allowed = allowed.filter(
        (i) =>
          i !== enums.DECISAO_PROCEDENTE &&
          i !== enums.DECISAO_PARCIALMENTE_PROCEDENTE &&
          i !== enums.DECISAO_IMPROCEDENTE &&
          i !== enums.DECISAO_ACORDO
      );
    }

    if (!tiposDecisao.includes(enums.TIPO_DECISAO_TUTELA)) {
      allowed = allowed.filter(
        (i) =>
          i !== enums.DECISAO_DEFERIDO && i !== enums.DECISAO_PARCIALMENTE_DEFERIDO && i !== enums.DECISAO_INDEFERIDO
      );
    }
  }

  if (grauJurisdicao && grauJurisdicao.length > 0) {
    if (!grauJurisdicao.includes("1grau") && !grauJurisdicao.includes("trt10_1grau")) {
      allowed = allowed.filter(
        (i) =>
          i !== enums.DECISAO_PROCEDENTE &&
          i !== enums.DECISAO_PARCIALMENTE_PROCEDENTE &&
          i !== enums.DECISAO_IMPROCEDENTE &&
          i !== enums.DECISAO_ACORDO &&
          i !== enums.DECISAO_DEFERIDO &&
          i !== enums.DECISAO_PARCIALMENTE_DEFERIDO &&
          i !== enums.DECISAO_INDEFERIDO
      );
    }

    if (
      !grauJurisdicao.includes("2grau") &&
      !grauJurisdicao.includes("stj") &&
      !grauJurisdicao.includes("stf") &&
      !grauJurisdicao.includes("trf1_acordaos") &&
      !grauJurisdicao.includes(jurisdicao.trf2_2grau_acordaos.id) &&
      !grauJurisdicao.includes(jurisdicao.trf3_2grau_acordaos.id) &&
      !grauJurisdicao.includes("tst_acordaos") &&
      !grauJurisdicao.includes(jurisdicao.trt10_2grau_acordaos.id)
    ) {
      allowed = allowed.filter(
        (i) =>
          i !== enums.DECISAO_PROVIDO &&
          i !== enums.DECISAO_PARCIALMENTE_PROVIDO &&
          i !== enums.DECISAO_IMPROVIDO &&
          i !== enums.DECISAO_OUTROS
      );
    }
  }

  return function (tipoDecisao) {
    return allowed.includes(tipoDecisao.id);
  };
}
