import React, { useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Form from "../components/form";
import LoginLayout from "../components/login-layout";
import AlertsContext from "../contexts/alerts-context";
import fetcher from "../libs/fetcher";
import primeiraPagina from "../libs/primeira-pagina";
import TokenManager from "../libs/token-manager";
import tratarErrosApi from "../libs/tratar-erros-api";

export default function Cadastro() {
  const history = useHistory();
  const { addAlert } = useContext(AlertsContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  async function handleSubmit(event) {
    event.preventDefault();
    setError(null);

    const form = event.target;
    const nome = form.elements.namedItem("nome").value;
    const email = form.elements.namedItem("email").value;
    const celular = form.elements.namedItem("celular").value;
    const senha = form.elements.namedItem("senha").value;
    const confirmacaoSenha = form.elements.namedItem("confirmacao-senha").value;

    if (senha !== confirmacaoSenha) {
      return setError({
        senha: "Senha e confirmação de senha devem ser exatamente iguais",
      });
    }

    try {
      setLoading(true);
      const token = await fetcher("/usuario/novo", { method: "post", data: { nome, email, senha, celular } });
      TokenManager.set(token);
      setLoading(false);
      addAlert("Cadastro realizado com sucesso. Bem vindo ao Dominia!", "success", "cadastro_usuario");
      history.push(primeiraPagina());
    } catch (error) {
      setError(tratarErrosApi(error));
      setLoading(false);
    }
  }

  if (error?.__desconhecido) {
    throw error.__desconhecido;
  }

  return (
    <LoginLayout>
      <form
        className="flex flex-col mt-8 px-8 py-8 bg-white shadow-md sm:rounded-lg sm:max-w-sm w-full"
        onSubmit={handleSubmit}
      >
        <p className="text-secondary-400 italic text-sm text-center">
          Faça o cadastro e solicite seu acesso <strong>gratuito durante a fase de testes</strong> do sistema.
        </p>
        <Form.Label htmlFor="nome" className="mt-8">
          Nome completo
        </Form.Label>
        <Form.Input
          className="mt-2"
          type="text"
          id="nome"
          name="nome"
          placeholder="Nome completo"
          required
          errored={error?.nome}
        />

        <Form.Label htmlFor="email" className="mt-4">
          E-mail
        </Form.Label>
        <Form.Input
          className="mt-2"
          type="email"
          id="email"
          name="email"
          placeholder="E-mail"
          required
          errored={error?.email}
        />

        <Form.Label htmlFor="celular" className="mt-4">
          Celular
        </Form.Label>
        <Form.Input
          className="mt-2"
          type="tel"
          id="tel"
          name="celular"
          placeholder="(99) 99999-9999"
          required
          errored={error?.celular}
        />

        <Form.Label className="mt-4" htmlFor="senha">
          Senha
        </Form.Label>
        <Form.Input
          className="mt-2"
          type="password"
          id="senha"
          name="senha"
          placeholder="********"
          required
          errored={error?.senha}
        />

        <Form.Label className="mt-4" htmlFor="confirmacao-senha">
          Confirmação de senha
        </Form.Label>
        <Form.Input
          className="mt-2"
          type="password"
          id="confirmacao-senha"
          name="confirmacao-senha"
          placeholder="********"
          required
          errored={error?.senha}
        />

        <p className="text-gray-700 font-semibold text-sm mt-4">
          <input
            type="checkbox"
            id="comprometimento-avaliacao"
            className="form-checkbox border rounded-md mr-1"
            required
          />{" "}
          <label htmlFor="comprometimento-avaliacao">
            Entendo que o <b>DOMINIA</b> está em fase testes e compreendo a importância de responder o formulário de
            feedback após avaliação do sistema.
          </label>
        </p>

        <p className="text-gray-700 italic text-sm text-center mt-4">
          Ao fazer o cadastro você aceita os{" "}
          <a href="https://dominia.app/#termos" rel="noreferrer" target="_blank" className="underline text-blue-500">
            termos de uso
          </a>{" "}
          e{" "}
          <a href="https://dominia.app/#politica" rel="noreferrer" target="_blank" className="underline text-blue-500">
            termos de privacidade
          </a>
          .
        </p>

        <div className="flex items-center mt-4">
          <Form.Button className="mt-4 bg-primary-500 hover:bg-primary-600" type="submit" disabled={loading}>
            Solicitar convite
          </Form.Button>
          <Link
            to="/login"
            className="block py-2 px-4 mt-4 ml-4 font-semibold rounded bg-white text-gray-600 hover:bg-gray-100 focus:bg-gray-100 focus:outline-none"
          >
            Cancelar
          </Link>
        </div>
      </form>
    </LoginLayout>
  );
}
