import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import formatarNumero from "../../libs/formatar-numero";

export default function ContagemRanking({ titulo, estatisticas, generateTo, grauJurisdicao }) {
  return (
    <div className="p-4 border-2 border-cool-gray-300 rounded-lg flex flex-col">
      <h1 className="font-bold text-cool-gray-700 text-sm">{titulo}</h1>
      <div className="grid grid-cols-4 text-sm mt-2">
        {estatisticas.top_varas_count.map((i) => (
          <Fragment key={i.vara}>
            <Link
              className="truncate col-span-3 text-cool-gray-700 font-semibold"
              to={generateTo({ grauJurisdicao, vara: i.vara })}
            >
              <abbr style={{ borderBottom: "none" }} title={i.vara}>
                {i.vara}
              </abbr>
            </Link>
            <div className="text-right text-cool-gray-500 font-semibold">{formatarNumero(Math.ceil(i.count))}</div>
          </Fragment>
        ))}
      </div>
    </div>
  );
}
