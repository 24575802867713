import React from "react";
import { XIcon } from "@heroicons/react/outline";

export default function ModalHeader({ title, children, onClose }) {
  return (
    <header className="py-4 pl-8 pr-4 w-full border-b border-gray-200">
      <div className="flex justify-between items-center">
        <div className="text-gray-700 font-bold">{title}</div>
        <button
          className="text-gray-500 hover:text-gray-800 focus:text-gray-800 focus:outline-none block 2xl:hidden"
          onClick={onClose}
        >
          <XIcon className="h-6 w-6 fill-current" />
        </button>
      </div>
      <div className="mt-1 flex flex-col lg:flex-row justify-between">{children}</div>
    </header>
  );
}
