import React from "react";
import TabContainer from "./tab-cointainer";
import TabLink from "./tab-link";

export default function AdminMenu() {
  return (
    <TabContainer className="mt-4">
      <TabLink to="/admin/usuarios">Usuários</TabLink>
      <TabLink to="/admin/classificacoes">Classificações</TabLink>
      <TabLink to="/admin/eventos">Eventos</TabLink>
      <TabLink to="/admin/pdfs">PDFs</TabLink>
      <TabLink to="/admin/log/pdf">Log - PDF</TabLink>
      <TabLink to="/admin/indices">Indices</TabLink>
      <TabLink to="/admin/pods">Pods</TabLink>
      <TabLink to="/admin/tests">Tests</TabLink>
    </TabContainer>
  );
}
