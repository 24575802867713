export const TIPO_DECISAO_MERITO = "Mérito";
export const TIPO_DECISAO_TUTELA = "Tutela";
export const TIPO_DECISAO_ACORDAO = "Acórdão";
export const TIPO_DECISAO_OUTROS = "Outros";

export const DECISAO_PROCEDENTE = "Procedente";
export const DECISAO_PARCIALMENTE_PROCEDENTE = "Parcialmente Procedente";
export const DECISAO_IMPROCEDENTE = "Improcedente";
export const DECISAO_ACORDO = "Acordo";

export const DECISAO_DEFERIDO = "Deferido";
export const DECISAO_PARCIALMENTE_DEFERIDO = "Parcialmente Deferido";
export const DECISAO_INDEFERIDO = "Indeferido";

export const DECISAO_PROVIDO = "Provido";
export const DECISAO_PARCIALMENTE_PROVIDO = "Parcialmente Provido";
export const DECISAO_IMPROVIDO = "Improvido";

export const DECISAO_OUTROS = "Outros";

export const decisoes = [
  {
    decisao: DECISAO_PROVIDO,
    tipoDecisao: TIPO_DECISAO_ACORDAO,
    bg: "bg-green-100",
    text: "text-green-700",
    border: "border-green-500",
  },
  {
    decisao: DECISAO_PARCIALMENTE_PROVIDO,
    tipoDecisao: TIPO_DECISAO_ACORDAO,
    bg: "bg-yellow-100",
    text: "text-yellow-700",
    border: "border-yellow-400",
  },
  {
    decisao: DECISAO_IMPROVIDO,
    tipoDecisao: TIPO_DECISAO_ACORDAO,
    bg: "bg-red-100",
    text: "text-red-700",
    border: "border-red-500",
  },
  {
    decisao: DECISAO_OUTROS,
    tipoDecisao: TIPO_DECISAO_ACORDAO,
    bg: "bg-cool-gray-100",
    text: "text-cool-gray-700",
    border: "border-cool-gray-500",
  },

  {
    decisao: DECISAO_PROCEDENTE,
    tipoDecisao: TIPO_DECISAO_MERITO,
    bg: "bg-green-100",
    text: "text-green-700",
    border: "border-green-500",
  },
  {
    decisao: DECISAO_PARCIALMENTE_PROCEDENTE,
    tipoDecisao: TIPO_DECISAO_MERITO,
    bg: "bg-yellow-100",
    text: "text-yellow-700",
    border: "border-yellow-400",
  },
  {
    decisao: DECISAO_IMPROCEDENTE,
    tipoDecisao: TIPO_DECISAO_MERITO,
    bg: "bg-red-100",
    text: "text-red-700",
    border: "border-red-500",
  },
  {
    decisao: DECISAO_ACORDO,
    tipoDecisao: TIPO_DECISAO_MERITO,
    bg: "bg-blue-100",
    text: "text-blue-700",
    border: "border-blue-500",
  },

  {
    decisao: DECISAO_DEFERIDO,
    tipoDecisao: TIPO_DECISAO_TUTELA,
    bg: "bg-green-100",
    text: "text-green-700",
    border: "border-green-500",
  },
  {
    decisao: DECISAO_PARCIALMENTE_DEFERIDO,
    tipoDecisao: TIPO_DECISAO_TUTELA,
    bg: "bg-yellow-100",
    text: "text-yellow-700",
    border: "border-yellow-400",
  },
  {
    decisao: DECISAO_INDEFERIDO,
    tipoDecisao: TIPO_DECISAO_TUTELA,
    bg: "bg-red-100",
    text: "text-red-700",
    border: "border-red-500",
  },
  {
    decisao: DECISAO_OUTROS,
    tipoDecisao: TIPO_DECISAO_OUTROS,
    bg: "bg-cool-gray-100",
    text: "text-cool-gray-700",
    border: "border-cool-gray-300",
  },
];

export function getDecisao(decisao, tipoDecisao) {
  return decisoes.find((d) => d.decisao === decisao && d.tipoDecisao === tipoDecisao);
}

export function descriptionForEnum(isDecisao = false) {
  return function (en) {
    if (isDecisao) {
      switch (en) {
        case DECISAO_PROCEDENTE:
          return "Procedente";
        case DECISAO_PARCIALMENTE_PROCEDENTE:
          return "Parc. Procedente";
        case DECISAO_IMPROCEDENTE:
          return "Improcedente";
        case DECISAO_ACORDO:
          return "Acordo";
        case DECISAO_DEFERIDO:
          return "Deferido";
        case DECISAO_PARCIALMENTE_DEFERIDO:
          return "Parc. Deferido";
        case DECISAO_INDEFERIDO:
          return "Indeferido";
        case DECISAO_PROVIDO:
          return "Provido";
        case DECISAO_PARCIALMENTE_PROVIDO:
          return "Parc. Provido";
        case DECISAO_IMPROVIDO:
          return "Improvido";
        case DECISAO_OUTROS:
          return "Outros";
        default:
          return "???UNDEFINED " + en;
      }
    } else {
      switch (en) {
        case TIPO_DECISAO_MERITO:
          return "Sentença";
        case TIPO_DECISAO_TUTELA:
          return "Tutela";
        case TIPO_DECISAO_OUTROS:
          return "Despacho";
        case TIPO_DECISAO_ACORDAO:
          return "Acórdão";
        default:
          return "???UNDEFINED???";
      }
    }
  };
}
