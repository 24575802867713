import React, { useState } from "react";
import { Link } from "react-router-dom";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import SizeDebug from "../components/size-debug";
import Logo from "../components/logo";
import IndexSection from "./index-section";
import primeiraPagina from "../libs/primeira-pagina";
import LogoTexto from "./logo-texto";

export default function IndexLayout({ children }) {
  const [isOpen, setOpen] = useState(false);
  const linkClasses =
    "mt-1 px-4 py-2 block text-gray-600 font-semibold rounded hover:bg-gray-100 focus:outline-none focus:bg-gray-100 lg:ml-4 lg:mt-0 ";
  const footerLinkClasses =
    "block pt-1 text-xs font-semibold text-gray-100 hover:text-white hover:underline focus:outline-none focus:text-white focus:underline";

  return (
    <div className="antialiased flex flex-col h-screen">
      <SizeDebug />
      <IndexSection>
        <header className="py-2 pt-6 px-4 lg:px-8 lg:flex lg:justify-between">
          <div className="flex justify-between items-center w-full lg:w-auto">
            <div className="lg:ml-0">
              <Link to="/">
                <LogoTexto className="h-10" />
              </Link>
            </div>
            <button
              className="mr-2 text-gray-500 hover:text-gray-900 focus:text-gray-900 focus:outline-none lg:hidden"
              onClick={() => setOpen((open) => !open)}
            >
              {isOpen ? <XIcon className="h-6 w-6 fill-current" /> : <MenuIcon className="h-6 w-6 fill-current" />}
            </button>
          </div>
          <nav className={`${isOpen ? "block" : "hidden"} mt-4 lg:mt-0 lg:flex`}>
            <a className={linkClasses} href="/#funcionalidades">
              Funcionalidades
            </a>
            <a className={linkClasses} href="/#clientes">
              Clientes
            </a>
            <a className={linkClasses} href="/#planos">
              Planos
            </a>
            <Link className={linkClasses} to="/quem-somos">
              Quem somos
            </Link>
            <Link className={linkClasses} to="/perguntas-frequentes">
              Perguntas frequentes
            </Link>
            <Link
              to={primeiraPagina()}
              className={`${linkClasses} font-extrabold text-secondary-500 hover:bg-secondary-50 hover:text-secondary-700 focus:bg-secondary-50 focus:text-secondary-700 justify-self-end`}
            >
              Acessar
            </Link>
          </nav>
        </header>
      </IndexSection>

      {children}

      <footer>
        <div className="bg-secondary-900">
          <IndexSection className="py-4 px-8 flex flex-col items-start">
            <h2 className="font-bold text-white">Dominia</h2>
            <a href="/" className={`${footerLinkClasses} mt-4`}>
              Início
            </a>
            <a href="#funcionalidades" className={footerLinkClasses}>
              Funcionalidades
            </a>
            <a href="#clientes" className={footerLinkClasses}>
              Clientes
            </a>
            <a href="#planos" className={footerLinkClasses}>
              Planos
            </a>
            <Link to="/quem-somos" className={footerLinkClasses}>
              Quem somos
            </Link>
            <Link to="/perguntas-frequentes" className={footerLinkClasses}>
              Perguntas frequentes
            </Link>
            <a href="/login" className={footerLinkClasses}>
              Acessar
            </a>
            <p className="text-xs text-white mt-3 font-semibold">contato@dominia.app</p>
          </IndexSection>
        </div>
        <div className="bg-black text-gray-100">
          <IndexSection className="flex justify-between items-center py-4 px-8 text-xs">
            <Logo className="h-8" fg="#000" />
            <div className="text-right">
              <p>© {new Date().getFullYear()} Dominia</p>
            </div>
          </IndexSection>
        </div>
      </footer>
    </div>
  );
}
