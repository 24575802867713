import React from "react";
import Logo from "./logo";
import SizeDebug from "./size-debug";

export default function CoreLayout({ children }) {
  return (
    <div className="antialiased flex flex-col h-screen">
      <SizeDebug />
      <div className="m-auto max-w-screen-xl flex-grow w-full">{children}</div>
      <footer>
        <div className="bg-gray-100 text-gray-600">
          <div className="max-w-screen-xl m-auto flex justify-between items-center py-4 px-8 text-xs">
            <Logo className="h-8" />
            <div className="text-right">
              <p>© {new Date().getFullYear()} Dominia</p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
