import React, { Suspense } from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import PrivateRoute from "./components/private-route.js";
import ErrorBoundary from "./components/error-boundary.js";
import Loading from "./components/loading.js";
import EsqueciMinhaSenha from "./pages/esqueci-minha-senha.js";
import Login from "./pages/login.js";
import MinhaConta from "./pages/minha-conta.js";
import Cadastro from "./pages/cadastro.js";
import PerguntasFrequentes from "./pages/perguntas-frequentes.js";
import TermosDeUso from "./pages/termos-de-uso.js";
import AdminUsuarios from "./pages/admin-usuarios.js";
import AdminPdfs from "./pages/admin-pdfs.js";
import AdminIndices from "./pages/admin-indices.js";
import PaginaNaoEncontrada from "./pages/pagina-nao-encontrada.js";
import { AlertsProvider } from "./contexts/alerts-context.js";
import { Roles } from "./libs/roles.js";
import AdminLogPdf from "./pages/admin-log-pdfs.js";
import AdminPods from "./pages/admin-pods.js";
import QuemSomos from "./pages/quem-somos.js";
import usePageTracking from "./libs/use-page-tracking.js";
import Pesquisa from "./pages/pesquisa.js";
import Jurimetria from "./pages/jurimetria.js";
import Token from "./pages/token.js";
import AdminTests from "./pages/admin-tests.js";
import AdminEventos from "./pages/admin-eventos.js";
import AdminClassificacoes from "./pages/admin-classificacoes.js";
import AssinaturaPlano from "./pages/assinatura-plano.js";
import PerfilVara from "./pages/perfil-vara.js";
import { UserProvider } from "./contexts/user-context.js";
import Historico from "./pages/historico.js";
import AdminHistorico from "./pages/admin-historico.js";

function Routes() {
  usePageTracking();

  return (
    <AlertsProvider>
      <ErrorBoundary>
        <UserProvider>
          <Suspense fallback={<Loading />}>
            <Switch>
              <Route exact path="/">
                <Redirect to="/pesquisa" />;
              </Route>
              <Route path="/perguntas-frequentes">
                <PerguntasFrequentes />
              </Route>
              <Route path="/quem-somos">
                <QuemSomos />
              </Route>
              <Route path="/termos-de-uso">
                <TermosDeUso />
              </Route>
              <Route path="/cadastro">
                <Cadastro />
              </Route>
              <Route path="/login">
                <Login />
              </Route>
              <Route path="/token">
                <Token />
              </Route>
              <Route path="/esqueci-minha-senha">
                <EsqueciMinhaSenha />
              </Route>
              <PrivateRoute path="/pagamento/cancelado" roles={[Roles.USUARIO, Roles.ASSINANTE, Roles.COFUNDADOR]}>
                <Redirect to="/minha-conta" />;
              </PrivateRoute>
              <PrivateRoute path="/pagamento/sucesso" roles={[Roles.USUARIO, Roles.ASSINANTE, Roles.COFUNDADOR]}>
                <Redirect to="/minha-conta" />;
              </PrivateRoute>
              <PrivateRoute path="/pagamento/portal" roles={[Roles.USUARIO, Roles.ASSINANTE, Roles.COFUNDADOR]}>
                <Redirect to="/minha-conta" />;
              </PrivateRoute>
              <PrivateRoute path="/minha-conta" roles={[Roles.USUARIO, Roles.ASSINANTE, Roles.COFUNDADOR]}>
                <MinhaConta />
              </PrivateRoute>
              <PrivateRoute path="/historico" roles={[Roles.USUARIO, Roles.ASSINANTE, Roles.COFUNDADOR]}>
                <Historico />
              </PrivateRoute>
              <PrivateRoute path="/assinatura/plano" roles={[Roles.USUARIO, Roles.ASSINANTE, Roles.COFUNDADOR]}>
                <AssinaturaPlano />
              </PrivateRoute>
              <PrivateRoute path="/pesquisa" roles={[Roles.USUARIO, Roles.ASSINANTE, Roles.COFUNDADOR]}>
                <Pesquisa />
              </PrivateRoute>
              <PrivateRoute path="/jurimetria" roles={[Roles.USUARIO, Roles.ASSINANTE, Roles.COFUNDADOR]}>
                <Jurimetria />
              </PrivateRoute>
              <PrivateRoute path="/perfil/vara" roles={[Roles.USUARIO, Roles.ASSINANTE, Roles.COFUNDADOR]}>
                <PerfilVara />
              </PrivateRoute>
              <PrivateRoute path="/admin/usuarios" roles={[Roles.COFUNDADOR]}>
                <AdminUsuarios />
              </PrivateRoute>
              <PrivateRoute path="/admin/historico/:usuario" roles={[Roles.COFUNDADOR]}>
                <AdminHistorico />
              </PrivateRoute>
              <PrivateRoute path="/admin/pdfs" roles={[Roles.COFUNDADOR]}>
                <AdminPdfs />
              </PrivateRoute>
              <PrivateRoute path="/admin/log/pdf" roles={[Roles.COFUNDADOR]}>
                <AdminLogPdf />
              </PrivateRoute>
              <PrivateRoute path="/admin/indices" roles={[Roles.COFUNDADOR]}>
                <AdminIndices />
              </PrivateRoute>
              <PrivateRoute path="/admin/pods" roles={[Roles.COFUNDADOR]}>
                <AdminPods />
              </PrivateRoute>
              <PrivateRoute path="/admin/tests" roles={[Roles.COFUNDADOR]}>
                <AdminTests />
              </PrivateRoute>
              <PrivateRoute path="/admin/eventos" roles={[Roles.COFUNDADOR]}>
                <AdminEventos />
              </PrivateRoute>
              <PrivateRoute path="/admin/classificacoes" roles={[Roles.COFUNDADOR]}>
                <AdminClassificacoes />
              </PrivateRoute>
              <Route path="*">
                <PaginaNaoEncontrada />
              </Route>
            </Switch>
          </Suspense>
        </UserProvider>
      </ErrorBoundary>
    </AlertsProvider>
  );
}

export default function App() {
  return (
    <Router>
      <Routes />
    </Router>
  );
}
