import React from "react";

export default function SizeDebug() {
  if (process.env.NODE_ENV === "production") {
    return null;
  } else {
    return (
      <div className="px-4 py-2 rounded text-gray-300 fixed bottom-0 left-0 text-xs font-bold">
        <div className="sm:hidden md:hidden lg:hidden xl:hidden">xs</div>
        <div className="hidden sm:block md:hidden lg:hidden xl:hidden">sm</div>
        <div className="hidden md:block lg:hidden xl:hidden">md</div>
        <div className="hidden lg:block xl:hidden">lg</div>
        <div className="hidden xl:block">xl</div>
      </div>
    );
  }
}
