export default function createReuString(reu) {
  const termos = [
    "Réu",
    "R:",
    "R",
    "Requerido",
    "Reclamado",
    "Demandado",
    "Impetrado",
    "Recorrido",
    "Apelado",
    "Suscitado",
    "Acusado",
    "Embargado",
    "Executado",
    "Impugnado",
    "Excepto",
  ];
  return termos.map((termo) => `"${termo} ${reu}"`).join(" | ");
}