import React from "react";
import ModalHeader from "./modal-header";
import ModalContainer from "./modal-cointainer";

export default function TutorialOperadores({ onClose }) {
  return (
    <ModalContainer onClose={onClose}>
      <ModalHeader title={<span className="text-2xl">Como usar os operadores de busca?</span>} onClose={onClose} />
      <main className="px-8 py-4 flex flex-col lg:flex-row items-center">
        <ul className="flex flex-col gap-4">
          <li>
            <h1 className="text-lg font-semibold">
              Operador <span className="font-mono text-primary-500 font-extrabold">+</span>
            </h1>
            <p>
              Equivalente a operação <b>E</b>. Por exemplo: supermercado + furto + veículo. O sistema buscará documentos que
              contenham obrigatoriamente as três palavras.
            </p>
          </li>
          <li>
            <h1 className="text-lg font-semibold">
              Operador <span className="font-mono text-primary-500 font-extrabold">|</span>
            </h1>
            <p>
              Equivalente a operação <b>OU</b>. Por exemplo: carro ou automóvel ou veículo. O sistema buscará documentos que
              contenham qualquer uma das três palavras.
            </p>
          </li>
          <li>
            <h1 className="text-lg font-bold">
              Operador <span className="font-mono text-primary-500 font-extrabold">-</span>
            </h1>
            <p>
              Equivalente a operação <b>NÃO</b>. Por exemplo: seguro -automóvel. O sistema buscará documentos que contenham a
              palavra seguro excluindo os resultados que contenham a palavra automóvel.
            </p>
          </li>
          <li>
            <h1 className="text-lg font-bold">
              Aspas duplas <span className="font-mono text-primary-500 font-extrabold">"</span>
            </h1>
            <p>
              Realiza busca de termos consecutivos. Por exemplo: "home care". O sistema buscará documentos que contenham
              a palavra home e logo em seguida a palavra care.
            </p>
          </li>

          <li>
            <h1 className="text-lg font-bold">
              Termos entre parenteses <span className="font-mono text-primary-500 font-extrabold">( )</span>
            </h1>
            <p>
              Muito parecido com o uso de parêntese em operações matemáticas. Ou seja, muda a precedência do operador.
              Por exemplo, (seguro + veículo) | (seguro + automóvel). O sistema buscará documentos que contenham o termo
              seguro e o termo veículo, ou documentos que contenham o termo seguro e o termo automóvel.
            </p>
          </li>
        </ul>
      </main>
    </ModalContainer>
  );
}
