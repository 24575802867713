import React, { Fragment } from "react";
import classNames from "../libs/classnames";
import descriptionForIdFn from "../libs/descricao-decisao";
import joinComVirgula from "../libs/join-com-virgula";

export default function TextoRelatorio({
  className,
  tema,
  selectedTiposDecisao,
  selectedDecisoes,
  selectedMagistrados,
  selectedVaras,
  selectedDatas,
  selectedOrgaosJulgadores
}) {
  const filtroClassName = "text-primary-500 italic font-semibold";
  let textos = ["Relatório de jurimetria"];
  if (selectedTiposDecisao?.length) {
    textos.push(
      <>
        de{" "}
        <span className={filtroClassName}>
          {joinComVirgula(
            selectedTiposDecisao.map((tipoDecisao) => descriptionForIdFn(null, tipoDecisao).toLowerCase()),
            "ou"
          )}
        </span>
      </>
    );
  }

  if (tema) {
    textos.push(
      <>
        para o tema <span className={filtroClassName}>{tema}</span>
      </>
    );
  }

  if (selectedDecisoes?.length) {
    if (selectedDecisoes.length < 2) {
      textos.push(<>com a decisão</>);
    } else {
      textos.push(<>com as decisões</>);
    }

    textos.push(
      <span className={filtroClassName}>
        {joinComVirgula(
          selectedDecisoes.map((decisao, i) => descriptionForIdFn(decisao).toLowerCase()),
          "ou"
        )}
      </span>
    );
  }

  if (selectedMagistrados?.length) {
    textos.push(<>decidido por</>);

    textos.push(<span className={filtroClassName}>{joinComVirgula(selectedMagistrados, "ou")}</span>);
  }

  if (selectedVaras?.length || selectedOrgaosJulgadores?.length) {
    if (selectedMagistrados?.length) {
      textos.push(<>ou na </>);
    } else {
      textos.push(<>decididos na </>);
    }

    if (selectedVaras.length) {
      textos.push(<span className={filtroClassName}>{joinComVirgula(selectedVaras, "ou")}</span>);
    }

    if (selectedOrgaosJulgadores.length) {
      if (selectedVaras.length) {
        textos.push(<> ou </>)
      }
      textos.push(<span className={filtroClassName}>{joinComVirgula(selectedOrgaosJulgadores, "ou")}</span>);
    }

  }

  if (selectedDatas?.length) {
    textos.push(<>em </>);
    textos.push(<span className={filtroClassName}>{joinComVirgula(selectedDatas.sort(), "ou")}</span>);
  }

  return (
    <div className={classNames(className, "text-secondary-700 font-normal")}>
      {textos.map((trecho, key) => <Fragment key={key}>{trecho}</Fragment>).reduce((prev, curr) => [prev, " ", curr])}
    </div>
  );
}
