import React, { useState } from "react";
import { Link } from "react-router-dom";
import Form from "../components/form";
import LoginLayout from "../components/login-layout";
import useQuery from "../hooks/use-query";
import fetcher from "../libs/fetcher";
import tratarErrosApi from "../libs/tratar-erros-api";

export default function EsqueciMinhaSenha() {
  const { query } = useQuery();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const email = query.get("email") || "";

  async function handleSubmit(event) {
    event.preventDefault();
    setError(null);

    const form = event.target;
    const email = form.elements.namedItem("email").value;

    try {
      setLoading(true);
      await fetcher("/usuario/recuperacao", { method: "post", data: { email } });
      form.elements.namedItem("email").value = "";
      setMessage("E-mail de recuperação enviado. Verifique seu email.");
    } catch (error) {
      setError(tratarErrosApi(error));
    } finally {
      setLoading(false);
    }
  }

  if (error?.__desconhecido) {
    throw error.__desconhecido;
  }

  return (
    <LoginLayout>
      <form
        className="flex flex-col mt-8 px-8 py-8 bg-white shadow-md sm:rounded-lg sm:max-w-sm w-full"
        onSubmit={handleSubmit}
      >
        <p className="text-gray-600 text-sm">
          Informe seu email para receber um link para login sem a necessidade de senha.
        </p>
        <Form.Label htmlFor="email" className="mt-4">
          E-mail
        </Form.Label>
        <Form.Input
          className="mt-2"
          type="email"
          id="email"
          placeholder="E-mail"
          required
          defaultValue={email}
          errored={error?.email}
        />
        {message && <p className="text-green-400 text-xs italic mt-1 h-1">{message}</p>}

        <div className="flex items-center mt-4">
          <Form.Button className="mt-4 bg-primary-500 hover:bg-primary-600" type="submit" disabled={loading}>
            Enviar link
          </Form.Button>
          <Link
            className="block py-2 px-4 mt-4 ml-4 font-semibold rounded bg-white text-gray-600 hover:bg-gray-100 focus:bg-gray-100 focus:outline-none"
            to="/login"
          >
            Cancelar
          </Link>
        </div>
      </form>
    </LoginLayout>
  );
}
